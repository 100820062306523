import localforage from "localforage";

var store = localforage.createInstance({
  name: "af",
  storeName: "a",
  driver: localforage.INDEXEDDB,
});

export async function get_value(key) {
  try {
    const value = await store.getItem(key);
    return value;
  } catch (err) {
    console.log(err);
  }
}

export async function set_value(key, value) {
  try {
    await store.setItem(key, value);
  } catch (err) {
    console.log(err);
  }
}

export async function clear_values() {
  store.clear();
}
