import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";

export class Grid {
  constructor() {
    this.thinW = 0.2;
    this.thickW = 0.5;
    this.zoom = 10;
    this.gridObject = new THREE.Object3D();
    this.thinLines = new THREE.Object3D();
    this.thickLines = new THREE.Object3D();
    this.scalerObject = new THREE.Object3D();
    this.intervals = 5;
    this.rows = 36;
    this.columns = 72;
    this.width = 360;
    this.height = 180;
    this.unit = 1;
    this.rectangle = new THREE.Object3D();

    this.scaleState = 0;
    this.thinmaterial = new LineMaterial({
      // side: THREE.DoubleSide,
      color: 0xaaaaaa,
      linewidth: 0.0003,
      // depthTest: false,
    });
    this.thickmaterial = new LineMaterial({
      // side: THREE.DoubleSide,
      color: 0x444444,
      linewidth: 0.0004,
      // depthTest: false,
    });
    this.scalermaterial = new LineMaterial({
      // side: THREE.DoubleSide,
      color: 0xff0000,
      linewidth: 0.001,
      // depthTest: false,
    });
    this.createGrid();
    this.createRectangle();
  }

  createGrid() {
    for (var i = -this.columns / 2; i < this.columns / 2; i++) {
      for (var j = 0; j < this.intervals; j++) {
        var point01 = new THREE.Vector3(
          i * this.unit * this.intervals + j * this.unit,
          this.height / 2,
          0
        );
        var point02 = new THREE.Vector3(
          i * this.unit * this.intervals + j * this.unit,
          -this.height / 2,
          0
        );
        var geo0 = this.createGeometry(point01, point02);
        var mesh0 = new Line2(geo0, this.thinmaterial);
        this.thinLines.add(mesh0);
      }
      var point11 = new THREE.Vector3(
        i * this.unit * this.intervals,
        this.height / 2,
        0
      );
      var point12 = new THREE.Vector3(
        i * this.unit * this.intervals,
        -this.height / 2,
        0
      );
      var geo1 = this.createGeometry(point11, point12);
      var mesh1 = new Line2(geo1, this.thickmaterial);
      this.thickLines.add(mesh1);
    }

    for (var k = -this.rows / 2; k < this.rows / 2; k++) {
      for (var l = 0; l < this.intervals; l++) {
        var point21 = new THREE.Vector3(
          this.width / 2,
          k * this.unit * this.intervals + l * this.unit,
          0
        );
        var point22 = new THREE.Vector3(
          -this.width / 2,
          k * this.unit * this.intervals + l * this.unit,
          0
        );
        var geo2 = this.createGeometry(point21, point22);
        var mesh2 = new Line2(geo2, this.thinmaterial);
        this.thinLines.add(mesh2);
      }
      var point31 = new THREE.Vector3(
        this.width / 2,
        k * this.unit * this.intervals,
        0
      );
      var point32 = new THREE.Vector3(
        -this.width / 2,
        k * this.unit * this.intervals,
        0
      );
      var geo3 = this.createGeometry(point31, point32);
      var mesh3 = new Line2(geo3, this.thickmaterial);
      this.thickLines.add(mesh3);
    }
  }

  createRectangle() {
    var p1 = new THREE.Vector3(0, 0, 0);
    var p2 = new THREE.Vector3(this.unit, 0, 0);
    var p3 = new THREE.Vector3(this.unit, this.unit, 0);
    var p4 = new THREE.Vector3(0, this.unit, 0);

    var lines = [
      [p1, p2],
      [p2, p3],
      [p3, p4],
      [p4, p1],
    ];
    lines.forEach((v) => {
      var geo = this.createGeometry(v[0], v[1]);
      var mesh = new Line2(geo, this.scalermaterial);
      this.rectangle.add(mesh);
    });
  }

  createGeometry(point1, point2) {
    var geometry = new LineGeometry();
    geometry.setPositions([
      point1.x,
      point1.y,
      point1.z,
      point2.x,
      point2.y,
      point2.z,
    ]);
    return geometry;
  }

  getGrid() {
    return this.gridObject;
  }

  getThinLines() {
    return this.thinLines;
  }
  getThickLines() {
    return this.thickLines;
  }

  getRectangle() {
    return this.rectangle;
  }
}
