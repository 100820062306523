import { API } from "aws-amplify";

const _API_NAME = "WebAppAPI";

export function getAPIData(resource, params = {}) {
  return new Promise((resolve, reject) => {
    API.get(_API_NAME, resource, params)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export function updateAPIData(resource, body) {
  return new Promise((resolve, reject) => {
    API.post(_API_NAME, resource, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export function deleteAPIData(resource, params) {
  return new Promise((resolve, reject) => {
    API.del(_API_NAME, resource, params)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}
