import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon, Avatar } from "antd";
import { Link, withRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { getUser } from "./User";
import LogoutIcon from "./media/log-out.svg";
import QuestionMarkIcon from "./media/question-mark.svg";
import SettingsIcon from "./media/settings.svg";
import FileTextIcon from "./media/file-text.svg";
import ShoppingCartIcon from "./media/shopping-cart.svg";
import Logo from "./media/ArchForm-logo.svg";
import InboxIcon from "./media/inbox.svg";
import GiftIcon from "./media/gift.svg";
import DownloadIcon from "./media/download.svg";
import Scrollbar from "./Scrollbar";
import { clear_values } from "./db";
// import { getSubscriptions, getFreeTrialActive } from './User';

import "./Sidebar.css";

// import {getUser} from './User';
// const { Header } = Layout;
export const status_color_map = {
  Draft: "var(--turquoise-1)",
  "All Patients": "var(--gray-3)",
  "On Hold": "var(--alert-100)",
  Unprocessed: "var(--teal-100)",
  Planning: "var(--orange-100)",
  "Needs Review": "var(--primary)",
  Accepted: "var(--pink-100)",
  "In Production": "var(--success-100)",
  "In Treatment": "var(--purple-100)",
  Archived: "var(--gray-3)",
};

export function SidebarPatientMenuItem(props) {
  return (
    <div
      onClick={props.onClick}
      style={{ background: props.active ? "var(--gray-5)" : "initial" }}
      className="sidebar-menu-item"
    >
      <div
        className="sidebar-patient-menu-item-icon"
        style={{ color: props.color }}
      />
      <span className="sidebar-menu-item-text">{props.title}</span>
      {props.count ? (
        <div className="sidebar-menu-item-count">{props.count}</div>
      ) : null}
    </div>
  );
}

export function MenuItem(props) {
  return (
    <div
      onClick={props.onClick}
      style={{ background: props.active ? "var(--gray-5)" : "initial" }}
      className="sidebar-menu-item"
    >
      <img
        className="sidebar-menu-item-icon"
        src={props.icon}
        alt={props.altText}
      />
      <span className="sidebar-menu-item-text">{props.title}</span>
      {props.count ? (
        <div className="sidebar-menu-item-count">{props.count}</div>
      ) : null}
    </div>
  );
}

function ArchFormSidebar(props) {
  // const [subscription, set_subscription] = useState(true);
  // const [free_trial_active, set_free_trial_active] = useState(true);

  // useEffect(() => {
  //     (async function() {
  //         set_subscription(await getSubscriptions())
  //         set_free_trial_active(await getFreeTrialActive())
  //     })()
  // }, [])

  const dashboard = props.dashboard;
  const active_status =
    dashboard && props.location.pathname === "/" ? dashboard.state.status : "";
  const handleRouting = (status) => (e) => {
    if (dashboard) dashboard.handleMenuChange(status)(e);
    else props.history.push(`/#${encodeURIComponent(status)}_1`);
  };

  let patients;
  if (dashboard) patients = dashboard.state.patients || [];
  else patients = Amplify.Cache.getItem("patientdata") || [];
  const statusCount = React.useMemo(() => {
    const count = {};
    for (var patient of patients) {
      if (!count[patient.status]) count[patient.status] = 0;
      if (!patient.status) {
        if (!count.Unprocessed) count.Unprocessed = 1;
        else count.Unprocessed += 1;
      }
      count[patient.status] += 1;
    }
    return count;
  }, [patients]);

  return (
    <>
      {/* {!subscription && !free_trial_active && props.location.pathname === '/create-patient' ?

                <div className='subscription-prompt-container'>
                    <div className='subscription-prompt-overlay' />
                    <div className='subscription-prompt-card'>
                        <div>
                            <p>You currently have no billing information on file</p>
                            <p>Please update your account to continue using ArchForm services</p>
                        </div>
                        <div
                            className='subscription-prompt-button'
                            onClick={e => props.history.push('/settings')}
                        >
                            <p>Go to Billing</p>
                        </div>
                    </div>
                </div>
                : null} */}
      <div id="sidebar-container" className="sidebar">
        <div className="sidebar-top-nav">
          <img src={Logo} alt="logo" onClick={handleRouting("Needs Review")} />
        </div>

        {props.children}

        <div className="sidebar-menu">
          <MenuItem
            onClick={handleRouting("Inbox")}
            icon={InboxIcon}
            altText="Inbox"
            title="Inbox"
            active={active_status === "Inbox"}
            count={
              dashboard && dashboard.state.notifications
                ? dashboard.state.notifications.length
                : null
            }
          />
          <h4 className="sidebar-menu-title">Patients</h4>

          {[
            "Draft",
            "All Patients",
            "On Hold",
            "Unprocessed",
            "Planning",
            "Needs Review",
            "Accepted",
            "In Production",
            "In Treatment",
            "Archived",
          ].map((status) => (
            <SidebarPatientMenuItem
              onClick={handleRouting(status)}
              color={status_color_map[status]}
              title={status}
              active={active_status === status}
              count={statusCount[status] || 0}
              key={status}
            />
          ))}
        </div>

        <div className="sidebar-bottom-items">
          <MenuItem
            onClick={(e) =>
              window.open("https://support.archform.com/", "_blank")
            }
            icon={QuestionMarkIcon}
            altText="Support"
            title="Support"
          />

          <MenuItem
            onClick={(e) =>
              window.open("https://supplies.archform.com/", "_blank")
            }
            icon={ShoppingCartIcon}
            altText="Supplies"
            title="Supplies"
          />

          <MenuItem
            onClick={(e) => window.open("https://grow.surf/rjf24o", "_blank")}
            icon={GiftIcon}
            altText="Referrals"
            title="Referrals"
          />

          <MenuItem
            onClick={(e) =>
              window.open(
                "https://www.archform.co/downloadarchform-e5r6t7yiughvb",
                "_blank"
              )
            }
            icon={DownloadIcon}
            altText="Desktop App"
            title="Desktop App"
          />

          <MenuItem
            onClick={(e) => props.history.push("/settings")}
            icon={SettingsIcon}
            altText="Settings"
            title="Settings"
            active={props.location.pathname === "/settings"}
          />
          <MenuItem
            onClick={(e) => props.history.push("/billing")}
            icon={FileTextIcon}
            altText="Billing"
            title="Billing"
            active={props.location.pathname === "/billing"}
          />
          <MenuItem
            onClick={(e) => {
              clear_values();
              Amplify.Cache.clear();
              Amplify.Auth.signOut();
              props.history.push("/login");
            }}
            icon={LogoutIcon}
            altText="Logout"
            title="Logout"
          />
        </div>
      </div>
    </>
  );
}

export default withRouter(ArchFormSidebar);
