import React, { useEffect, useState, useRef } from "react";
import { useAppViewCtrl } from "../container/app-view-state";
import FileReader from "../file-reader";
import Scene from "../lib/scene";
import usePrevious from "../lib/hooks/usePrevious";
import "./main.css";

const Viewport = (props) => {
  const {
    setToothClicked,
    setIsFirstStepLoaded,
    isFullLoaded,
    setIsFullLoaded,
    currentStep,
    setCurrentStep,
    totalStep,
    setTotalStep,
    setReadyState,
    playAnimation,
    setPlayAnimation,
    overlayStep,
    setOverlayStep,
    sliderValue,
    setSliderValue,
    setMovements,
    editMode,
    views,
    gridVisible,
    iprVisible,
    collisionVisible,
    overlayVisible,
    isMobileView,
  } = useAppViewCtrl();

  const previousPlayAnimation = usePrevious(playAnimation);

  const mount = useRef(null);
  const [fileReadCompleted, setFileReadCompleted] = useState(false);
  const [scene] = useState(
    () =>
      new Scene({
        path: props.path,
        onSetTotalStep: setTotalStep,
        onSetReadyState: setReadyState,
        onSetIsFirstStepLoaded: setIsFirstStepLoaded,
        onSetIsFullLoaded: setIsFullLoaded,
        onSetSliderValue: setSliderValue,
        onSetCurrentStep: setCurrentStep,
        onSetPlayAnimation: setPlayAnimation,
        onSetToothClicked: setToothClicked,
        onSetMovements: setMovements,
        onSetOverlayStep: setOverlayStep,
      })
  );

  const [fileReader] = useState(
    () =>
      new FileReader({
        userid: props.userid,
        patientid: props.patientid,
        path: props.path,
        finish: () => setFileReadCompleted(true),
        onSetTotalStep: setTotalStep,
        loadPatientFiles: true,
      })
  );

  const renderViewport = () => {
    // This setTimeout really shouldn't be necessary, but
    // without it the useEffect hook fires before the
    // mounted element has a height and the viewport doesn't
    // work. We're having the same issue with the canvas in
    // the spaces app, which may or may not be related
    setTimeout(() => scene.renderViewport(mount), 1000);
  };
  useEffect(renderViewport, [scene, mount]);

  useEffect(() => {
    if (fileReadCompleted) {
      const json = fileReader.inputJSON;
      const element = json.find((obj) => obj.step === totalStep);
      const elementFirst = json.find((obj) => obj.step === 1);
      console.log("in viewport index", element);
      scene.loadTeethMeshes(
        fileReader.lowFiles,
        fileReader.upperFiles,
        element,
        elementFirst
      );
      scene.animate(totalStep, fileReader.inputJSON, true);
    }
  }, [
    fileReadCompleted,
    fileReader.inputJSON,
    fileReader.lowFiles,
    fileReader.upperFiles,
    scene,
    totalStep,
  ]);

  useEffect(() => {
    scene.selectFrame(currentStep, fileReader.inputJSON);
  }, [currentStep, fileReader.inputJSON, scene]);

  useEffect(() => {
    scene.updateTotalStep(totalStep);
  }, [scene, totalStep]);

  useEffect(() => {
    scene.playAnimation(
      currentStep,
      totalStep,
      playAnimation,
      previousPlayAnimation
    );
  }, [currentStep, previousPlayAnimation, playAnimation, scene, totalStep]);

  // useEffect(() => {
  //   scene.setOverlayStep(overlayStep);
  // }, [overlayStep, scene]);

  useEffect(() => {
    if (isFullLoaded) scene.selectView(views);
  }, [views, scene, isFullLoaded, setIsFullLoaded]);

  useEffect(() => {
    scene.showGrid(gridVisible);
  }, [gridVisible, scene]);

  useEffect(() => {
    scene.IPRView(iprVisible);
  }, [iprVisible, scene]);

  useEffect(() => {
    scene.collisionView(collisionVisible);
  }, [collisionVisible, scene]);

  useEffect(() => {
    scene.overlayView(overlayVisible);
  }, [overlayVisible, scene]);

  useEffect(() => {
    scene.smoothView(sliderValue);
  }, [sliderValue, scene]);

  useEffect(() => {
    scene.setIsMobileView(isMobileView);
  }, [isMobileView, scene]);

  useEffect(() => {
    scene.onEditMode(editMode);
  }, [editMode, scene]);

  return (
    <div
      ref={mount}
      style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
    />
  );
};

export default Viewport;
