import * as THREE from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import CameraControls from "./camera-controls";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { Grid } from "./grid";
import {
  createOrthoCamera,
  createPerspCamera,
  createRenderer,
  createCameraControl,
  createHemiLight,
  createDirLight,
  createPointLight,
  createSpotLight,
} from "./three-factory";
import { diffXZ, Materials } from "./constants";
// import Controller from "./controller/Controller";
import { TransformControls } from "./transformControls";

const draco = new DRACOLoader();
draco.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.4.1/");

export class Scene {
  constructor(settings) {
    this.settings = settings;
    this.isMobile = true;

    this.scene = undefined;
    this.camera = undefined;
    this.perspcamera = undefined;
    this.orthocamera = undefined;
    this.grid = null;
    this.renderer = undefined;
    this.animationTimer = undefined;
    this.INTERSECTED = null;
    this.mousedown = false;
    this.drag = 0;
    this.editMode = false;

    this.currentStep = 1;
    this.lowerTeethBody = new THREE.Object3D();
    this.upperTeethBody = new THREE.Object3D();
    this.overlayBody = new THREE.Object3D();
    this.lowerGumBody = new THREE.Object3D();
    this.upperGumBody = new THREE.Object3D();
    this.lowerhidden = false;
    this.upperhidden = false;
    this.jsonData = undefined;
    this.wholeBody = new THREE.Object3D();
    this.wholeBody.add(this.lowerTeethBody);
    this.wholeBody.add(this.upperTeethBody);
    this.wholeBody.add(this.overlayBody);
    this.bodySize = 100;

    this.cameraControls = null;
    this.clock = new THREE.Clock();
    CameraControls.install({ THREE: THREE });
    // this.controller = null;

    this.initScene();
    this.initControls();
    this.initLight();
    this.render();
    this.addEventListeners();
    this.addGrid();
    // this.initMorphController();
  }

  updateAttrs = (args, mode) => {
    if (mode === "CurrentStep") {
      this.selectFrame(args.CurrentStep, this.FileReader.inputJSON);
    } else if (mode === "TotalSteps") {
    } else if (mode === "PlayAnimation") {
      this.playAnimation(args);
    } else if (mode === "OverlayStep") {
      this.setOverlayStep(args.OverlayStep);
    }
  };

  setIsMobileView = (mobileView) => {
    this.isMobile = mobileView;
  };

  updateTotalStep = (totalStep) => {
    this.totalStep = totalStep;
  };

  initScene = () => {
    this.scene = new THREE.Scene();
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.perspcamera = createPerspCamera(width, height);
    this.perspcamera.position.set(0, 0, 20);
    this.scene.add(this.perspcamera);
    this.camera = this.perspcamera;

    this.orthocamera = createOrthoCamera(width, height);
    this.orthocamera.zoom = 10;
    this.scene.add(this.orthocamera);

    this.renderer = createRenderer(width, height);
    // this.mount.appendChild(this.renderer.domElement);
    const dom = this.renderer.domElement;
    dom.style.width = "100%";
    dom.style.height = "100%";
    this.overlayBody.visible = false;
  };

  renderViewport = (mount) => {
    if (mount.current && !this.mount) {
      this.mount = mount.current;
      this.mount.appendChild(this.renderer.domElement);
    }
  };

  initControls = () => {
    this.cameraControls1 = createCameraControl(
      this.perspcamera,
      this.renderer.domElement,
      0.15,
      0.3,
      800,
      40
    );
    this.cameraControls2 = createCameraControl(
      this.orthocamera,
      this.renderer.domElement,
      0.15,
      0.3,
      60,
      2
    );

    this.cameraControls1.addEventListener("control", () => {
      this.updateAnnotationVisibility();
    });

    this.cameraControls2.addEventListener("control", () => {
      this.updateAnnotationVisibility();
    });

    this.transformControl = new TransformControls(
      this.camera,
      this.renderer.domElement
    );
    this.transformControl.setSpace("local");
    this.transformControl.addEventListener("dragging-changed", (event) => {
      this.cameraControls1.enabled = !event.value;
      this.cameraControls2.enabled = !event.value;
    });
    this.scene.add(this.transformControl);
  };

  initLight = () => {
    this.scene.add(createHemiLight());
    this.perspcamera.add(createDirLight());

    const pointLight = createPointLight();
    pointLight.position.copy(this.perspcamera.position);
    this.perspcamera.add(pointLight);
    this.perspcamera.add(createSpotLight());

    const dirLight2 = createDirLight();
    dirLight2.visible = false;
    this.orthocamera.add(dirLight2);

    const spotlight2 = createSpotLight();
    spotlight2.visible = false;
    this.orthocamera.add(spotlight2);
  };

  // initMorphController = () => {
  //   this.controller = new Controller(this);

  // };

  render = () => {
    window.requestAnimationFrame(this.render);
    const delta = this.clock.getDelta();
    this.cameraControls2.update(delta);
    this.cameraControls1.update(delta);
    this.renderer.render(this.scene, this.camera);
  };

  addEventListeners = () => {
    window.addEventListener(
      "resize",
      () => {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
      },
      false
    );

    var drag = 0;
    var mousedown = false;
    this.renderer.domElement.addEventListener(
      "mousedown",
      (e) => {
        if (this.isMobile) return;
        mousedown = true;
        drag = 0;
      },
      false
    );

    this.renderer.domElement.addEventListener(
      "mousemove",
      (e) => {
        if (this.isMobile) return;
        if (mousedown) {
          drag++;
        }
      },
      false
    );

    this.renderer.domElement.addEventListener(
      "mouseup",
      (e) => {
        if (this.isMobile) return;
        if (drag > 3) return;

        var intersects = this.getIntersections(e);
        if (intersects.length === 0) {
          if (this.INTERSECTED) {
            this.transformControl.detach(this.INTERSECTED);
            this.INTERSECTED.material.color.setHex(this.INTERSECTED.currentHex);
          }
          this.INTERSECTED = null;
          this.settings.onSetToothClicked(false);
          return;
        }
        var intersect = intersects[0].object;
        if (intersect === this.INTERSECTED) return;
        if (this.INTERSECTED)
          this.INTERSECTED.material.color.setHex(this.INTERSECTED.currentHex);
        this.INTERSECTED = intersects[0].object;
        this.INTERSECTED.currentHex = this.INTERSECTED.material.color.getHex();
        this.INTERSECTED.material.color.setHex(0xff8533);
        if (this.editMode) this.transformControl.attach(this.INTERSECTED);
        this.settings.onSetToothClicked(true);
        this.setMovements();

        mousedown = false;
        drag = 0;
      },
      false
    );

    this.renderer.domElement.addEventListener("wheel", (e) => {
      this.recreateGrid(this.cameraControls2._zoom);
    });
  };

  addGrid = () => {
    this.grid = new Grid();
    var lineMesh1 = this.grid.getThinLines();
    lineMesh1.position.set(0, 0, -1);
    lineMesh1.visible = false;
    this.orthocamera.add(lineMesh1);

    var lineMesh2 = this.grid.getThickLines();
    lineMesh2.position.set(0, 0, -1);
    lineMesh2.visible = false;
    this.orthocamera.add(lineMesh2);

    var rectangle = this.grid.getRectangle();
    rectangle.position.set(0, 0, -1);
    rectangle.name = "rectangle";
    rectangle.visible = false;
    this.orthocamera.add(rectangle);
  };

  updateAnnotationVisibility = () => {
    const meshDistance = this.camera.position.distanceTo(
      this.wholeBody.position
    );
    var lowgum = this.lowerGumBody.children.find(
      (obj) => obj.step === this.currentStep
    );
    var uppergum = this.upperGumBody.children.find(
      (obj) => obj.step === this.currentStep
    );
    if (lowgum !== undefined && lowgum.children.length > 0) {
      lowgum.children.forEach((item) => {
        if (item.name === "ipr" || item.name === "collision") {
          const spriteDistance = this.camera.position.distanceTo(item.pos);
          if (meshDistance > spriteDistance && item.show) {
            item.visible = true;
          } else {
            item.visible = false;
          }
        }
      });
    }
    if (uppergum !== undefined && uppergum.children.length > 0) {
      uppergum.children.forEach((item) => {
        if (item.name === "ipr" || item.name === "collision") {
          const spriteDistance = this.camera.position.distanceTo(item.pos);
          if (meshDistance > spriteDistance && item.show) {
            item.visible = true;
          } else {
            item.visible = false;
          }
        }
      });
    }
  };

  getIntersections = (event) => {
    // get intersection point when mouse click on the surface of model
    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    var vector = new THREE.Vector2();
    vector.set(
      (event.clientX / width) * 2 - 1,
      -(event.clientY / height) * 2 + 1
    );
    var raycaster = new THREE.Raycaster();
    raycaster.layers.set(1);
    raycaster.setFromCamera(vector, this.camera);

    return raycaster.intersectObjects(this.scene.children, true);
  };

  setcontent = (object) => {
    // put a camera at a proper position and look at a object
    this.box = new THREE.Box3().setFromObject(object);
    const size = this.box.getSize(new THREE.Vector3()).length();
    object.size = size;
    this.bodySize = size;

    const center = this.box.getCenter(new THREE.Vector3());

    var obj_pos = object.position;
    object.center = obj_pos;
    object.position.x += object.position.x - center.x;
    object.position.y += object.position.y - center.y;
    object.position.z += object.position.z - center.z;
    const distance = this.isMobile ? size * 4 : (size * 5) / 3;
    this.cameraControls1.setLookAt(0, 0, distance, 0, 0, 0, false);
    this.cameraControls2.setLookAt(0, 0, distance, 0, 0, 0, false);
  };

  loadTeethMeshes = (low, upper, showInitialStepJson, firstStepJson) => {
    var promises = [];
    var index = 0;

    low.forEach((value) => {
      promises[index++] = this.loadStl(value).then((geo) => {
        var mesh = new THREE.Mesh(geo, Materials.tooth.clone());
        var overlayMesh = new THREE.Mesh(geo, Materials.compare);
        var id = value.split("?")[0].match(/\d+/g);
        if (id.length > 0) {
          mesh.toothId = parseInt(id.slice(-1)[0]);
          mesh.lowerTooth = true;
          mesh.layers.enable(1);
          overlayMesh.toothId = parseInt(id.slice(-1)[0]);
          overlayMesh.lowerTooth = true;
          this.lowerTeethBody.add(mesh);
          this.overlayBody.add(overlayMesh);
        }
      });
    });

    upper.forEach((value) => {
      promises[index++] = this.loadStl(value).then((geo) => {
        var mesh = new THREE.Mesh(geo, Materials.tooth.clone());
        var overlayMesh = new THREE.Mesh(geo, Materials.compare);
        var id = value.split("?")[0].match(/\d+/g);
        if (id.length > 0) {
          mesh.toothId = parseInt(id.slice(-1)[0]);
          mesh.lowerTooth = false;
          mesh.layers.enable(1);
          overlayMesh.toothId = parseInt(id.slice(-1)[0]);
          overlayMesh.lowerTooth = false;
          this.upperTeethBody.add(mesh);
          this.overlayBody.add(overlayMesh);
        }
      });
    });
    Promise.all(promises).then(() => {
      this.wholeBody.add(this.lowerTeethBody);
      this.wholeBody.add(this.upperTeethBody);
      this.wholeBody.add(this.overlayBody);

      this.transformTeeth(showInitialStepJson);
      console.log("in scene", showInitialStepJson);
      this.transformOverlayTeeth(firstStepJson);
      this.settings.onSetIsFirstStepLoaded(true);
    });
  };

  loadStl = (url) => {
    return new Promise((resolve) => {
      function onLoad(geo) {
        geo.computeVertexNormals();
        resolve(geo);
      }
      draco.load(url, onLoad, onProgressCallback, onError);
    });

    function onProgressCallback() {}
    function onError() {
      console.log("cannot load stl files");
    }
  };

  animate = (frame, jsonData, gumOnly = false) => {
    this.jsonData = jsonData;
    var element = jsonData.find((obj) => obj.step === frame);
    var firstElement = jsonData.find((obj) => obj.step === 1);
    this.loadGumPontic(frame, jsonData);
    if (element === undefined) {
      console.log("Cannot find Json data of step ", frame);
      return;
    }
    if (!gumOnly) {
      this.transformTeeth(element);
      // this.transformOverlayTeeth(firstElement);
    }
    this.currentStep = frame;
  };

  loadGumPontic = (frame, jsonData) => {
    var element = jsonData.find((obj) => obj.step === frame);
    var promises = [];
    var index = 0;
    if (element === undefined) return;

    promises[index++] = this.loadStl(element.LowerGumName).then((geo) => {
      var mesh = new THREE.Mesh(geo, Materials.tissue);
      mesh.step = frame;
      mesh.IPRattached = false;
      this.applyMatrix(mesh, element.LowerGumMatrix);
      this.lowerGumBody.add(mesh);
    });
    promises[index++] = this.loadStl(element.UpperGumName).then((geo) => {
      var mesh = new THREE.Mesh(geo, Materials.tissue);
      mesh.step = frame;
      mesh.IPRattached = false;
      this.applyMatrix(mesh, element.UpperGumMatrix);
      this.upperGumBody.add(mesh);
      // this.controller.reloadModel(mesh);
    });

    // load pontics if they exist
    if (element.LowerPonticName) {
      promises[index++] = this.loadStl(element.LowerPonticName).then((geo) => {
        var mesh = new THREE.Mesh(geo, Materials.pontic);
        mesh.step = frame;
        this.lowerGumBody.add(mesh);
      });
    }
    if (element.UpperPonticName) {
      promises[index++] = this.loadStl(element.UpperPonticName).then((geo) => {
        var mesh = new THREE.Mesh(geo, Materials.pontic);
        mesh.step = frame;
        this.upperGumBody.add(mesh);
      });
    }

    Promise.all(promises).then(() => {
      this.wholeBody.add(this.lowerGumBody);
      this.wholeBody.add(this.upperGumBody);
      this.scene.add(this.wholeBody);
      this.setcontent(this.wholeBody);

      this.settings.onSetReadyState(frame);
      this.settings.onSetCurrentStep(frame);
      // this.settings.onSetOverlayStep(frame);

      this.loadGumsPontics(1, jsonData);
      this.settings.onSetIsFullLoaded(true);
    });
  };

  loadGumsPontics = (frame, jsonData) => {
    var element = jsonData.find((obj) => obj.step === frame);
    var promises = [];
    var index = 0;
    if (element === undefined || frame === this.totalStep) {
      return;
    }

    promises[index++] = this.loadStl(element.LowerGumName).then((geo) => {
      var mesh = new THREE.Mesh(geo, Materials.tissue);
      mesh.step = frame;
      mesh.IPRattached = false;

      mesh.visible = false;
      this.applyMatrix(mesh, element.LowerGumMatrix);
      this.lowerGumBody.add(mesh);
    });
    promises[index++] = this.loadStl(element.UpperGumName).then((geo) => {
      var mesh = new THREE.Mesh(geo, Materials.tissue);
      mesh.step = frame;
      mesh.IPRattached = false;

      mesh.visible = false;
      this.applyMatrix(mesh, element.UpperGumMatrix);
      this.upperGumBody.add(mesh);
    });

    // load pontics if they exist
    if (element.LowerPonticName) {
      promises[index++] = this.loadStl(element.LowerPonticName).then((geo) => {
        var mesh = new THREE.Mesh(geo, Materials.pontic);
        mesh.step = frame;
        mesh.visible = false;
        this.lowerGumBody.add(mesh);
      });
    }
    if (element.UpperPonticName) {
      promises[index++] = this.loadStl(element.UpperPonticName).then((geo) => {
        var mesh = new THREE.Mesh(geo, Materials.pontic);
        mesh.step = frame;
        mesh.visible = false;
        this.upperGumBody.add(mesh);
      });
    }

    Promise.all(promises).then(() => {
      this.settings.onSetReadyState(frame);
      this.loadGumsPontics(frame + 1, jsonData);
      this.settings.onSetIsFullLoaded(true);
    });
  };

  selectFrame = (currentStep, jsonData) => {
    var element = jsonData.find((obj) => obj.step === currentStep);
    if (element === undefined) return;
    this.displayIPR(currentStep, element);
    this.transformTeeth(element);
    this.lowerGumBody.children.forEach((v) => {
      if (v.step === currentStep && !this.lowerhidden) v.visible = true;
      else v.visible = false;
    });
    this.upperGumBody.children.forEach((v) => {
      if (v.step === currentStep && !this.upperhidden) v.visible = true;
      else v.visible = false;
    });
    this.currentStep = currentStep;
    this.setMovements();
  };

  displayIPR = (frame, element) => {
    if (element.LowerIPR === undefined || element.UpperIPR === undefined) {
      console.log("It still didnt read IPR data from csv file.");
    }
    if (element.LowerIPR !== undefined && element.LowerIPR.length > 0) {
      let targetGum = this.lowerGumBody.children.find(
        (item) => item.step === frame
      );
      if (targetGum !== undefined && !targetGum.IPRattached) {
        element.LowerIPR.forEach((v) => {
          if (v.vertices && v.centerPosition) {
            let dots = v.vertices;
            let middlePoint = v.centerPosition;

            var geo = new THREE.Geometry();
            var mat = new THREE.MeshBasicMaterial({
              color: 0xff6666,
              depthTest: false,
              side: THREE.DoubleSide,
            });
            for (var i = 0; i < dots.length / 3; i++) {
              var vertice = new THREE.Vector3(
                parseFloat(dots[i * 3]),
                parseFloat(dots[i * 3 + 1]),
                parseFloat(dots[i * 3 + 2])
              );
              geo.vertices.push(vertice);
            }
            geo.vertices.push(middlePoint);

            for (var j = 1; j < dots.length / 3; j++) {
              geo.faces.push(
                new THREE.Face3(geo.vertices.length - 1, j, j - 1)
              );
            }
            let buffergeo = new THREE.BufferGeometry().fromGeometry(geo);
            var mesh = new THREE.Mesh(buffergeo, mat);
            mesh.name = "collision";
            mesh.pos = v.centerPosition;
            mesh.visible = true;
            mesh.show = true;
            mesh.renderOrder = 1;
            targetGum.add(mesh);
            this.createAnnotationLabel(targetGum, v, -18, false);
          }
        });
        targetGum.IPRattached = true;
      }
    }
    if (element.UpperIPR !== undefined && element.UpperIPR.length > 0) {
      let targetGum = this.upperGumBody.children.find(
        (item) => item.step === frame
      );

      if (targetGum !== undefined && !targetGum.IPRattached) {
        element.UpperIPR.forEach((v) => {
          if (v.vertices && v.centerPosition) {
            let dots = v.vertices;
            let middlePoint = v.centerPosition;

            var geo = new THREE.Geometry();
            var mat = new THREE.MeshBasicMaterial({
              color: 0xff6666,
              depthTest: false,
              side: THREE.DoubleSide,
            });
            for (var i = 0; i < dots.length / 3; i++) {
              var vertice = new THREE.Vector3(
                parseFloat(dots[i * 3]),
                parseFloat(dots[i * 3 + 1]),
                parseFloat(dots[i * 3 + 2])
              );
              geo.vertices.push(vertice);
            }
            geo.vertices.push(middlePoint);

            for (var k = 1; k < dots.length / 3; k++) {
              geo.faces.push(
                new THREE.Face3(geo.vertices.length - 1, k, k - 1)
              );
            }
            let buffergeo = new THREE.BufferGeometry().fromGeometry(geo);
            var mesh = new THREE.Mesh(buffergeo, mat);
            mesh.name = "collision";
            mesh.pos = v.centerPosition;
            mesh.visible = true;
            mesh.show = true;
            mesh.renderOrder = 1;
            targetGum.add(mesh);
            this.createAnnotationLabel(targetGum, v, 18, false);
          }
        });
        targetGum.IPRattached = true;
      }
    }
  };

  shadeCollisionArea = (dots, middlePoint) => {
    var geo = new THREE.Geometry();
    var mat = new THREE.MeshBasicMaterial({
      color: 0xff6666,
      depthTest: false,
      side: THREE.DoubleSide,
    });
    for (var i = 0; i < dots.length / 3; i++) {
      var vertice = new THREE.Vector3(
        parseFloat(dots[i * 3]),
        parseFloat(dots[i * 3 + 1]),
        parseFloat(dots[i * 3 + 2])
      );
      geo.vertices.push(vertice);
    }
    geo.vertices.push(middlePoint);

    for (var l = 1; l < dots.length / 3; l++) {
      geo.faces.push(new THREE.Face3(geo.vertices.length - 1, l, l - 1));
    }
    var mesh = new THREE.Mesh(geo, mat);
    mesh.visible = true;
    return mesh;
  };

  createAnnotationLabel = (meshToAttach, IPR, AnnoY) => {
    let diffX = diffXZ[parseInt(IPR.tooth1) - 1];
    if (!diffX) return;
    diffX = diffX[0];
    const diffZ = diffXZ[parseInt(IPR.tooth1) - 1][1];

    var centerPos = IPR.centerPosition;
    const canvas = document.createElement("canvas");
    canvas.style.position = "absolute";
    canvas.style.zIndex = -1;
    canvas.width = 64 * 16;
    canvas.height = 64 * 16;

    const ctx = canvas.getContext("2d");
    if (ctx) {
      const x = 32 * 16;
      const y = 32 * 16;
      const radius = 30 * 16;

      ctx.fillStyle = "rgb(255, 255, 255)";
      ctx.beginPath();
      ctx.rect(0, 0, radius * 2, radius * 2);

      ctx.fill();

      ctx.strokeStyle = "rgb(100, 100, 100)";
      ctx.lineWidth = 2 * 32;
      ctx.beginPath();
      ctx.rect(32, 32, radius * 2, radius * 2);
      ctx.stroke();

      ctx.fillStyle = "rgb(0, 0, 0)";
      ctx.font = "512px sans-serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(parseFloat(IPR.IPRValue).toFixed(1), x, y);
    }

    const numberTexture = new THREE.CanvasTexture(canvas);

    const spriteMaterial = new THREE.SpriteMaterial({
      map: numberTexture,
      alphaTest: 1.0,
      depthTest: false,
    });

    let sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(3, 3, 1);
    sprite.position.set(
      parseFloat(centerPos.x) + diffX,
      AnnoY,
      parseFloat(centerPos.z) + diffZ
    );
    sprite.name = "ipr";
    sprite.show = true;
    sprite.visible = true;
    sprite.pos = centerPos;
    sprite.renderOrder = 1;
    meshToAttach.add(sprite);

    const lineGeo = new LineGeometry();
    if (IPR.tooth1 === undefined) return;
    lineGeo.setPositions([
      centerPos.x,
      centerPos.y,
      centerPos.z,
      parseFloat(centerPos.x) + diffX,
      AnnoY - 1.2,
      parseFloat(centerPos.z) + diffZ,
    ]);
    const lineMat = new LineMaterial({
      color: 0x000000,
      linewidth: 0.0015,
      depthTest: false,
      polygonOffset: true,
      polygonOffsetFactor: 1,
      precision: "highp",
    });

    const line = new Line2(lineGeo, lineMat);
    line.name = "ipr";
    line.visible = true;
    line.show = true;

    line.pos = centerPos;
    line.renderOrder = 1;
    meshToAttach.add(line);
  };

  transformTeeth = (element) => {
    this.lowerTeethBody.children.forEach((v) => {
      this.applyMatrix(v, element.LowerTeethMatrices[v.toothId - 1]);
      if (
        element.LowerVisibility &&
        element.LowerVisibility[v.toothId - 1] !== undefined
      ) {
        v.visible = Boolean(element.LowerVisibility[v.toothId - 1]);
      } else {
        v.visible = true;
      }
    });
    this.upperTeethBody.children.forEach((v) => {
      this.applyMatrix(v, element.UpperTeethMatrices[v.toothId - 1]);
      v.visible = true;
      if (
        element.UpperVisibility &&
        element.UpperVisibility[v.toothId - 1] !== undefined
      ) {
        v.visible = Boolean(element.UpperVisibility[v.toothId - 1]) ?? true;
      } else {
        v.visible = true;
      }
    });
  };

  transformOverlayTeeth = (element) => {
    this.overlayBody.children.forEach((v) => {
      if (v.lowerTooth)
        this.applyMatrix(v, element.LowerTeethMatrices[v.toothId - 1]);
      else this.applyMatrix(v, element.UpperTeethMatrices[v.toothId - 1]);
    });
  };

  applyMatrix = (obj, matrix) => {
    if (matrix === undefined || matrix === null) {
      console.log("Empty transformation matrix!");
      return;
    }
    var pos = new THREE.Vector3();
    var quaternion = new THREE.Quaternion();
    var scale = new THREE.Vector3();
    matrix.decompose(pos, quaternion, scale);
    obj.position.copy(pos);
    obj.quaternion.copy(quaternion);
  };

  playFrame = (frame, jsonData) => {
    var element = jsonData.find((obj) => obj.step === frame);
    this.transformTeeth(element);
    this.lowerGumBody.children.forEach((v) => {
      v.visible = false;
      if (v.step === frame && this.lowerhidden === false && v.show)
        v.visible = true;
    });
    this.upperGumBody.children.forEach((v) => {
      v.visible = false;
      if (v.step === frame && this.upperhidden === false && v.show)
        v.visible = true;
    });
    this.currentStep = frame;
  };

  playAnimation = (
    currentStep,
    totalStep,
    playAnimation,
    prevPlayAnimation
  ) => {
    var start = currentStep + 1;

    if (start > totalStep) start = 1;
    if (!prevPlayAnimation && playAnimation) {
      this.animationTimer = setInterval(() => {
        if (start > totalStep) {
          clearInterval(this.animationTimer);
          this.settings.onSetPlayAnimation(false);
        } else {
          this.settings.onSetCurrentStep(start);
          // this.settings.onSetOverlayStep(Math.min(start + 1, totalStep));
        }
        start++;
      }, 500);
    } else if (prevPlayAnimation && !playAnimation) {
      clearInterval(this.animationTimer);
      this.settings.onSetPlayAnimation(false);
    }
  };

  showGrid = (visible) => {
    if (!visible) {
      this.camera = this.perspcamera;

      this.perspcamera.children.forEach((v) => {
        v.visible = true;
      });
      this.orthocamera.children.forEach((v) => {
        v.visible = false;
      });
    } else {
      this.camera = this.orthocamera;
      this.perspcamera.children.forEach((v) => {
        v.visible = false;
      });
      this.orthocamera.children.forEach((v) => {
        v.visible = true;
      });
    }
    this.camera.updateProjectionMatrix();
  };

  recreateGrid = (zoom) => {
    if (this.grid === null || this.grid === undefined) return;
    if (zoom > 40 && this.grid.scaleState === 0) {
      this.grid.scaleState = 1;
      this.grid.thinLines.scale.set(0.2, 0.2, 0.2);
      this.grid.thickLines.scale.set(0.2, 0.2, 0.2);
    } else if (zoom < 4 && this.grid.scaleState === 0) {
      this.grid.scaleState = -1;
      this.grid.thinLines.scale.set(5, 5, 5);
      this.grid.thickLines.scale.set(5, 5, 5);
    } else if (zoom > 4 && zoom < 50) {
      this.grid.thinLines.scale.set(1, 1, 1);
      this.grid.thickLines.scale.set(1, 1, 1);
      this.grid.scaleState = 0;
    }
  };

  smoothView = (value) => {
    var lowerGum = this.lowerGumBody.children.find(
      (obj) => obj.step === this.currentStep
    );
    var upperGum = this.upperGumBody.children.find(
      (obj) => obj.step === this.currentStep
    );
    if (!lowerGum || !upperGum) return;
    if (value < 0.8) {
      this.lowerTeethBody.children.forEach((v) => {
        v.material.transparent = true;
        v.material.opacity = value;
      });
      lowerGum.visible = false;
      upperGum.visible = true;
      this.lowerhidden = true;
      this.upperhidden = false;
      this.overlayBody.children.forEach((v) => {
        if (v.lowerTooth) v.visible = false;
      });
    } else if (value >= 0.8 && value <= 1.2) {
      this.lowerTeethBody.children.forEach((v) => {
        v.material.transparent = false;
        v.material.opacity = 1;
      });

      if (!this.lowerhidden) lowerGum.visible = true;

      this.upperTeethBody.children.forEach((v) => {
        v.material.transparent = false;
        v.material.opacity = 1;
      });

      if (!this.upperhidden) upperGum.visible = true;

      this.overlayBody.children.forEach((v) => {
        v.visible = true;
      });
      this.lowerhidden = false;
      this.upperhidden = false;
    } else if (value > 1.2) {
      this.upperTeethBody.children.forEach((v) => {
        v.material.transparent = true;
        v.material.opacity = 2 - value;
      });
      upperGum.visible = false;
      lowerGum.visible = true;
      this.lowerhidden = false;
      this.upperhidden = true;
      this.overlayBody.children.forEach((v) => {
        if (!v.lowerTooth) v.visible = false;
      });
    }
  };

  selectView = (value) => {
    this.showAll();
    var distance = this.perspcamera.position.distanceTo(
      new THREE.Vector3(0, 0, 0)
    );
    if (value === 1) {
      this.cameraControls1.setLookAt(0, -distance, 0, 0, 0, 0, true);
      this.cameraControls2.setLookAt(
        0,
        (-this.bodySize * 5) / 3,
        0,
        0,
        0,
        0,
        true
      );
      this.smoothView(0);
      this.settings.onSetSliderValue(0);
      this.lowerhidden = true;
      this.upperhidden = false;
    } else if (value === 2) {
      this.cameraControls1.setLookAt(0, 0, distance, 0, 0, 0, true);
      this.cameraControls2.setLookAt(
        0,
        0,
        (this.bodySize * 5) / 3,
        0,
        0,
        0,
        true
      );
      this.smoothView(1);
      this.settings.onSetSliderValue(1);
      this.lowerhidden = false;
      this.upperhidden = false;
    } else if (value === 3) {
      this.cameraControls1.setLookAt(0, 0, -distance, 0, 0, 0, true);
      this.cameraControls2.setLookAt(
        0,
        0,
        (-this.bodySize * 5) / 3,
        0,
        0,
        0,
        true
      );
      this.smoothView(1);

      this.settings.onSetSliderValue(1);
      this.lowerhidden = false;
      this.upperhidden = false;
    } else if (value === 4) {
      this.cameraControls1.setLookAt(0, distance, 0, 0, 0, 0, true);
      this.cameraControls2.setLookAt(
        0,
        (this.bodySize * 5) / 3,
        0,
        0,
        0,
        0,
        true
      );
      this.smoothView(2);
      this.settings.onSetSliderValue(2);
      this.lowerhidden = false;
      this.upperhidden = true;
    } else if (value === 5) {
      this.cameraControls1.setLookAt(-distance, 0, 0, 0, 0, 0, true);
      this.cameraControls2.setLookAt(
        (-this.bodySize * 5) / 3,
        0,
        0,
        0,
        0,
        0,
        true
      );
      this.smoothView(1);
      this.settings.onSetSliderValue(1);
      this.lowerhidden = false;
      this.upperhidden = false;
    } else if (value === 6) {
      this.cameraControls1.setLookAt(distance, 0, 0, 0, 0, 0, true);
      this.cameraControls2.setLookAt(
        (this.bodySize * 5) / 3,
        0,
        0,
        0,
        0,
        0,
        true
      );
      this.smoothView(1);
      this.settings.onSetSliderValue(1);
      this.lowerhidden = false;
      this.upperhidden = false;
    }
  };

  showAll = () => {
    var element = this.jsonData.find((obj) => obj.step === this.currentStep);
    this.lowerhidden = false;
    this.upperhidden = false;
    this.lowerTeethBody.children.forEach((v) => {
      if (
        element &&
        element.LowerVisibility &&
        element.LowerVisibility[v.toothId - 1] !== undefined
      ) {
        v.visible = Boolean(element.LowerVisibility[v.toothId - 1]);
      } else {
        v.visible = true;
      }
      v.visible = true;
      v.material.transparent = false;
      v.material.opacity = 0;
    });
    this.upperTeethBody.children.forEach((v) => {
      if (
        element &&
        element.UpperVisibility &&
        element.UpperVisibility[v.toothId - 1] !== undefined
      ) {
        v.visible = Boolean(element.UpperVisibility[v.toothId - 1]);
      } else {
        v.visible = true;
      }
      v.material.transparent = false;
      v.material.opacity = 0;
    });
    this.overlayBody.children.forEach((v) => {
      v.visible = true;
    });
  };

  IPRView = (visible) => {
    this.lowerGumBody.children.forEach((v) => {
      if (v.children.length > 0) {
        v.children.forEach((item) => {
          if (item.name === "ipr") {
            item.visible = visible;
            item.show = visible;
          }
        });
      }
    });
    this.upperGumBody.children.forEach((v) => {
      if (v.children.length > 0) {
        v.children.forEach((item) => {
          if (item.name === "ipr") {
            item.visible = visible;
            item.show = visible;
          }
        });
      }
    });
  };

  collisionView = (visible) => {
    this.lowerGumBody.children.forEach((v) => {
      if (v.children.length > 0) {
        v.children.forEach((item) => {
          if (item.name === "collision") {
            item.visible = visible;
            item.show = visible;
          }
        });
      }
    });
    this.upperGumBody.children.forEach((v) => {
      if (v.children.length > 0) {
        v.children.forEach((item) => {
          if (item.name === "collision") {
            item.visible = visible;
            item.show = visible;
          }
        });
      }
    });
  };

  overlayView = (visible) => {
    if (this.jsonData === undefined || this.jsonData.length === 0) return;

    var element = this.jsonData.find((obj) => obj.step === this.currentStep);
    if (element === undefined) return;

    if (visible) this.overlayBody.visible = true;
    else this.overlayBody.visible = false;
    //apply transformation
  };

  setOverlayStep = (step) => {
    if (!this.jsonData) return;
    var element = this.jsonData.find((obj) => obj.step === step);
    if (!element) return;
    this.transformOverlayTeeth(element);
  };

  onEditMode(editMode) {
    this.editMode = editMode;
    if (editMode) {
      this.lowerGumBody.visible = false;
      this.upperGumBody.visible = false;
    } else {
      this.lowerGumBody.visible = true;
      this.upperGumBody.visible = true;
      this.transformControl.detach();
    }
  }

  setMovements = () => {
    var tooth = this.INTERSECTED;
    if (tooth === undefined || tooth === null) return;

    var pos1 = new THREE.Vector3();
    var pos0 = new THREE.Vector3();
    var quat1 = new THREE.Quaternion();
    var quat0 = new THREE.Quaternion();
    var sca1 = new THREE.Vector3();
    var sca0 = new THREE.Vector3();

    if (this.currentStep === 1) {
      this.settings.onSetMovements({ position: pos1, rotation: quat1 });
      return;
    }
    var element = this.jsonData.find((obj) => obj.step === this.currentStep);
    var prevelement = this.jsonData.find((obj) => obj.step === 1);
    var matrix = tooth.lowerTooth
      ? element.LowerTeethMatrices[tooth.toothId - 1]
      : element.UpperTeethMatrices[tooth.toothId - 1];
    var prevmatrix = tooth.lowerTooth
      ? prevelement.LowerTeethMatrices[tooth.toothId - 1]
      : prevelement.UpperTeethMatrices[tooth.toothId - 1];

    matrix.decompose(pos1, quat1, sca1);
    prevmatrix.decompose(pos0, quat0, sca0);

    let dPos = pos1.sub(pos0);
    let dx = dPos.dot(
      new THREE.Vector3(
        prevmatrix.elements[0],
        prevmatrix.elements[1],
        prevmatrix.elements[2]
      )
    );
    let dy = dPos.dot(
      new THREE.Vector3(
        prevmatrix.elements[4],
        prevmatrix.elements[5],
        prevmatrix.elements[6]
      )
    );
    let dz = dPos.dot(
      new THREE.Vector3(
        prevmatrix.elements[8],
        prevmatrix.elements[9],
        prevmatrix.elements[10]
      )
    );

    var euler = new THREE.Euler();
    euler.setFromQuaternion(
      new THREE.Quaternion().multiplyQuaternions(quat0.inverse(), quat1)
    );
    this.settings.onSetMovements({
      position: new THREE.Vector3(dx, dy, dz),
      rotation: euler,
    });
  };
}

export default Scene;
