import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Tag,
  Tooltip,
  Input,
  Icon,
  Spin,
  message,
  Checkbox,
  Modal,
  Button as AntButton,
} from "antd";
import { Link, withRouter, Prompt } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { useDropzone } from "react-dropzone";
import {
  ChevronDown,
  Minus,
  Plus,
  Check,
  ArrowLeft,
  ArrowRight,
  Image,
} from "react-feather";
import classNames from "classnames";
import { createPatient, updatePatient, uploadPatientFile } from "./Patients";
import { convertUNS } from "./PatientView2";
import { hashCode } from "./Patients";
import { stringToColour } from "./PatientTable2";
import { Button } from "./Button";
import {
  getBillingAccount,
  getProductCheckout,
  archform_products,
  getSubscriptions,
  getDefaultSource,
  getFreeTrialActive,
  getUserId,
  getUser,
} from "./User";
import AddressView, { ValidateAddress } from "./AddressView";
import PrescriptionView from "./PrescriptionView";
import "./CreatePatient.css";

import stl_upper_fill from "./media/patient/stl_upper_fill.svg";
import stl_upper_outline from "./media/patient/stl_upper_outline.svg";
import stl_lower_fill from "./media/patient/stl_lower_fill.svg";
import stl_lower_outline from "./media/patient/stl_lower_outline.svg";
import full_face_smiling from "./media/patient/full_face_smiling.svg";
import full_face_not_smiling from "./media/patient/full_face_not_smiling.svg";
import profile_not_smiling from "./media/patient/profile_not_smiling.svg";
import upper_occlusal from "./media/patient/upper_occlusal.svg";
import lower_occlusal from "./media/patient/lower_occlusal.svg";
import right_lateral from "./media/patient/right_lateral.svg";
import front_view from "./media/patient/front_view.svg";
import left_lateral from "./media/patient/left_lateral.svg";
import xray_pano from "./media/patient/xray_pano.svg";
import xray_ceph from "./media/patient/xray_ceph.svg";

import { SCANS_ACCRPTED } from "./utils"

const product_description = {
  alignment: {
    "In Office": {
      description: [
        "Align the teeth yourself using ArchForm's desktop software",
      ],
      price: [0, "$50/patient or subscription or supply order"],
    },

    Orthodontist: {
      description: [
        "Optimized setup created by an orthodontist",
        "Refinements $149/setup",
      ],
      price: [220, ""],
    },

    Technician: {
      description: [
        "Segmentation and initial alignment done by a technician.  No attachments or modifications.",
        "Refinements $49/setup",
      ],
      price: [120, ""],
    },
  },
  manufacturing: {
    "In Office": {
      description: ["Manufacture the aligners yourself"],
      price: [0, ""],
    },

    "Form Aligners": {
      description: [
        "Orthodontist setups and aligners made in ArchForm's factory",
        "Refinements $149/setup +$15/aligner",
      ],
      price: [
        {
          "5 Stages": 299,
          "7 Stages": 399,
          "10 Stages": 499,
          "14 Stages": 699,
          "Unlimited Stages": 899,
        },
        "",
      ],
    },


    "Per Aligner": {
      description: [
        "Aligners made in ArchForm's factory",
        "Refinements $17 per aligner",
      ],
      price: [0, "$17 per aligner manufactured"],
    },
  },
};

const getProductPriceTotal = (alignment, manufacturing) => {
  let total = 0;
  if (alignment && product_description.alignment[alignment]) {
    total += product_description.alignment[alignment].price[0];
  }
  if (
    (manufacturing && product_description.manufacturing[manufacturing]) ||
    manufacturing.includes("Stages")
  ) {
    if (manufacturing.includes("Stages")) {
      total +=
        product_description.manufacturing["Form Aligners"].price[0][
        manufacturing
        ] - 199;
    } else {
      total += product_description.manufacturing[manufacturing].price[0];
    }
  }
  return total;
};

const getProductPriceLabel = (alignment, manufacturing) => {
  let label = "";
  if (alignment) {
    label += product_description.alignment[alignment].price[1];
  }
  if (manufacturing && !manufacturing.includes("Stages")) {
    let new_label = product_description.manufacturing[manufacturing].price[1];
    if (label && new_label) new_label = " and " + new_label;
    label += new_label;
  }
  return label;
  return label ? " and " + label : "";
};

const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

const TeethTable = ({ table, set_table, system }) => {
  const ToothCheckbox = ({ val }) => (
    <div
      className={classNames("teeth-checkbox-item", {
        "teeth-checkbox-selected": table.includes(val.toString()),
      })}
      onClick={() =>
        table.includes(val.toString())
          ? set_table(table.filter((tooth) => tooth !== val.toString()))
          : set_table([...table, val.toString()])
      }
    >
      {convertUNS(val, system)}
    </div>
  );
  return (
    <div className="teeth-checkbox teeth-table">
      <div>
        <div>
          {[...Array(9).keys()].slice(1).map((val) => (
            <ToothCheckbox val={val} key={val} />
          ))}
        </div>
        <div className="teeth-checkbox-horizontal-divider" />
        <div>
          {[...Array(33).keys()]
            .slice(25)
            .reverse()
            .map((val) => (
              <ToothCheckbox val={val} key={val} />
            ))}
        </div>
      </div>
      <div className="teeth-checkbox-vertical-divider" />
      <div>
        <div>
          {[...Array(17).keys()].slice(9).map((val) => (
            <ToothCheckbox val={val} key={val} />
          ))}
        </div>
        <div className="teeth-checkbox-horizontal-divider" />
        <div>
          {[...Array(25).keys()]
            .slice(17)
            .reverse()
            .map((val) => (
              <ToothCheckbox val={val} key={val} />
            ))}
        </div>
      </div>
    </div>
  );
};

const DropdownInput = ({ value, setValue, choices, required }) => {
  let dropdown_list_ref = null;

  const hide_list = () => (dropdown_list_ref.style.display = "none");
  const show_list = () => (dropdown_list_ref.style.display = "flex");

  return (
    <div
      onClick={() => {
        dropdown_list_ref.style.display === "flex" ? hide_list() : show_list();
      }}
      onMouseLeave={hide_list}
      className="dropdown-input"
    >
      <p>{value}</p>
      <ChevronDown size={16} color="#8A8F98" />
      <div
        className="dropdown-input-list"
        ref={(ref) => (dropdown_list_ref = ref)}
        onMouseLeave={hide_list}
      >
        {choices.map((choice) => (
          <p
            onClick={() => {
              setTimeout(() => {
                hide_list();
                setValue(choice);
              }, 50);
            }}
            key={choice}
          >
            {choice}
          </p>
        ))}
      </div>
      <input
        type="text"
        value={value}
        required={required || false}
        className="hidden-input"
        onChange={() => null}
      />
    </div>
  );
};

const CustomTreatmentTags = ({ tags, set_tags }) => {
  let tag_input_ref = null;
  const [tag_input, set_tag_input] = useState("");
  const [tag_input_visisble, set_tag_input_visisble] = useState(false);

  React.useEffect(() => {
    if (tag_input_visisble) {
      tag_input_ref.focus();
    }
  }, [tag_input_visisble]);

  const handleCustomTreatmentInputConfirm = (current_tags) => () => {
    let tags = current_tags;
    if (tag_input && tags.indexOf(tag_input) === -1) {
      tags = [...tags, tag_input];
    }
    set_tags(tags);
    set_tag_input("");
    set_tag_input_visisble(false);
  };

  const showCustomTreatmentInput = () => {
    set_tag_input_visisble(true);
  };

  const handleCustomTreatmentInputClose = (removedTag) => {
    const new_tags = tags.filter((tag) => tag !== removedTag);
    set_tags(new_tags);
  };

  return (
    <div>
      {tags.map((tag, index) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag
            closable
            color={stringToColour(tag)}
            key={tag}
            onClose={() => handleCustomTreatmentInputClose(tag)}
          >
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {tag_input_visisble ? (
        <Input
          ref={(ref) => (tag_input_ref = ref)}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={tag_input}
          onChange={(e) => set_tag_input(e.target.value)}
          onBlur={handleCustomTreatmentInputConfirm(tags)}
          onPressEnter={handleCustomTreatmentInputConfirm(tags)}
        />
      ) : null}
      {!tag_input_visisble ? (
        <Tag
          onClick={showCustomTreatmentInput.bind(this)}
          style={{ background: "#fff", borderStyle: "dashed" }}
        >
          <Icon type="plus" /> New Tag
        </Tag>
      ) : null}
    </div>
  );
};

export const FileUpload = ({
  file,
  setFile,
  label,
  preview,
  uploadedPreview,
  accept,
  required,
}) => {
  const [new_file, setNewFile] = useState(file);
  const [new_file_view, setNewFileView] = useState(file);
  const [new_file_name, setNewFileName] = useState('');
  useEffect(() => {
    setNewFileView(
      new_file && new_file.path ? URL.createObjectURL(new_file) : new_file
    );
  }, [new_file]);
  const onDrop = useCallback((file) => {

    const isNotAccepted = !file.length

    if (isNotAccepted) {

      message.error({
        content: `File is not ${accept}`
      })

      return
    }

    setNewFile(URL.createObjectURL(file[0]));
    setFile(file[0]);
    setNewFileName(file[0].name)

  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFile: 1,
    onDrop,
    accept,
  });

  if (label === "Full Composite") {
    return (
      <div
        key={label}
        className="full-composite-upload-field"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {new_file || file ? (
          <img src={new_file_view || file} className="file-upload-image" />
        ) : (
          <h4>{`Upload ${label}`}</h4>
        )}
      </div>
    );
  } else if (uploadedPreview) {
    return (
      <div className="file-upload-field" {...getRootProps()}>
        <div>
          <input {...getInputProps()} />
          {new_file || file ? (
            <img src={uploadedPreview} className="file-upload-preview-image" />
          ) : (
            <img src={preview} className="file-upload-preview-image" />
          )}
        </div>
        <p className="uppercase-text">{label}</p>
        {new_file_name ?
          <p>{new_file_name}</p>
          :
          <p>Drag file or click to select</p>
        }
        {/* <input type='text' onChange={()=>{}} className='file-upload-required-input' value={new_file? ' ' : ''} 
                required={required} name={label} /> */}
      </div>
    );
  }
  return (
    <div className="file-upload-field" {...getRootProps()}>
      <div style={{ border: new_file || file ? "none" : "1px dashed #cccccc" }}>
        <input {...getInputProps()} />
        {new_file || file ? (
          <img src={new_file_view || file} className="file-upload-image" />
        ) : preview ? (
          <img src={preview} className="file-upload-preview-image" />
        ) : null}
      </div>
      <p className="uppercase-text">{label}</p>
      <p>Drag file or click to select</p>
      <input
        type="text"
        onChange={() => { }}
        className="file-upload-required-input"
        value={new_file ? " " : ""}
        required={required}
        name={label}
      />
    </div>
  );
};

const ProductCard = ({
  name,
  description,
  price,
  active,
  expands,
  onClick,
  summary,
}) => {
  return (
    <div className="product-card">
      <div>
        <p className="product-card-product-name">{name}</p>
        <div>
          {summary ? null : (
            <>
              <p className="product-card-product-price">{price[0]}</p>
              <p className="product-card-product-price-description">
                {price[1]}
              </p>
            </>
          )}
        </div>
      </div>
      <p className="product-card-product-description">{description[0]}</p>
      <p className="product-card-product-description-sub">{description[1]}</p>
      {summary ? (
        <>
          <p className="product-card-product-price-summary">{price[0]}</p>
          <p className="product-card-product-price-description-summary">
            {price[1]}
          </p>
        </>
      ) : (
        <>
          <div
            className={
              active ? "product-selected-button" : "product-select-button"
            }
            onClick={onClick}
          >
            {active ? (
              expands ? (
                <p>Expanded</p>
              ) : (
                <p>Selected</p>
              )
            ) : expands ? (
              <p>Expand</p>
            ) : (
              <p>Select</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const PatientWindowNavbar = ({
  stage,
  set_stage,
  stage_complete,
  patient,
  currySaveAsDraft,
  curryUploadPatient,
  can_submit,
  set_submit_req_modal_visible,
}) => {
  return (
    <>
      <div className="patient-window-navbar-container">
        <div className="patient-window-navbar-left">
          <button
            className={classNames(
              "patient-window-navbar-button patient-window-navbar-button-white",
              {
                "patient-window-navbar-button-active": stage > 0,
              }
            )}
            onClick={() => set_stage(stage - 1)}
            disabled={stage === 0}
          >
            <ArrowLeft height={16} />
            Back
          </button>
        </div>

        <div className="patient-window-navbar-stage-container">
          {[
            "Patient Info",
            "Files",
            "Alignment",
            "Manufacturing",
            "Prescription",
            "Summary",
          ].map((ele, idx) => (
            <>
              <div
                className={classNames("patient-window-navbar-stage", {
                  "patient-window-navbar-stage-complete": idx < stage,
                  "patient-window-navbar-stage-active": idx === stage,
                })}
                onClick={() => set_stage(idx)}
              >
                <div className="patient-window-navbar-circle">
                  {idx < stage ? (
                    <Check color="white" size={16} />
                  ) : (
                    <p>{idx + 1}</p>
                  )}
                </div>
                <p>{ele}</p>
              </div>
            </>
          ))}
          <div className="patient-window-navbar-stage-line" />
        </div>
        <div className="patient-window-navbar-right">
          <button
            className={classNames(
              "patient-window-navbar-button patient-window-navbar-button-white"
            )}
            onClick={async () => await currySaveAsDraft()}
          // disabled={stage === 0}
          // style={{ marginLeft: "64px",
          //  opacity: stage === 0 ? "0" : "100"
          // }}
          >
            Save as draft
          </button>
          <button
            className={classNames(
              "patient-window-navbar-button patient-window-navbar-button-blue",
              {
                "patient-window-navbar-button-blue": stage_complete,
                "patient-window-navbar-button-active": stage_complete,
              }
            )}
            onClick={async () =>
              stage === 5
                ? can_submit
                  ? await curryUploadPatient()
                  : set_submit_req_modal_visible(true)
                : set_stage(stage + 1)
            }
            style={{ marginLeft: "12px" }}
          >
            {stage === 5 ? "Submit" : "Next"}
            <ArrowRight height={16} />
          </button>
        </div>
        <div className="patient-window-navbar-divider" />
      </div>
      <p className="patient-window-navbar-step-label">Step {stage + 1} of 6</p>
    </>
  );
};

const PatientWindowBottomNavbar = ({
  stage,
  stage_complete,
  set_stage,
  patient,
}) => {
  return (
    <>
      <div className="patient-window-bottom-navbar-clear" />

      <div className="patient-window-bottom-navbar-container ">
        <button
          className={classNames("patient-window-navbar-button", {
            "patient-window-navbar-button-active": stage > 0,
          })}
          onClick={() => set_stage(stage - 1)}
          disabled={stage === 0}
        >
          Back
        </button>
        <button
          className={classNames("patient-window-navbar-button", {
            "patient-window-navbar-button-blue": stage_complete,
            "patient-window-navbar-button-active": stage_complete,
          })}
          onClick={() => set_stage(stage + 1)}
        // disabled={!stage_complete}
        >
          {stage === 4 ? "Submit" : "Next"}
        </button>
      </div>
    </>
  );
};

const loadFiles = async (setters, userid, patientid) => {
  const options = {
    level: "protected",
    identityId: userid,
    bucket: "archform-patient-data-v1",
  };

  ["composite", "models", "xray"].forEach(async (folder) => {
    const files_info = await Amplify.Storage.list(
      `${patientid}/${folder}`,
      options
    );
    for (var file_info of files_info.results) {
      let filename = file_info.key.split("/").slice(-1)[0].split(".")[0];
      const file = await Amplify.Storage.get(file_info.key, options);
      if (setters[filename]) setters[filename](file);
    }
  });
};

const uploadPatient = async (
  patient,
  files,
  update_address,
  create,
  upload_progress,
  set_upload_progress,
  set_total_upload_progress,
  draft,
  can_submit
) => {
  // if (!draft && !can_submit) {
  //   message.warn("Please upload scans");
  //   return;
  // }
  for (var key in patient) {
    if (
      patient[key] === "" ||
      (Array.isArray(patient[key]) && !patient[key].length)
    ) {
      delete patient[key];
    }
  }
  const user_userid = await getUserId();
  if (!patient.userid) {
    patient.userid = user_userid;
  }

  const promises = [];

  for (var folder in files) {
    for (var key in files[folder]) {
      if (!files[folder][key] || typeof files[folder][key] === "string")
        continue;
      const extension = folder === "models" ? "stl" : "jpg";

      // const upload_url = `${patient.patientid}/${folder}/${key}.${extension}`;
      const upload_url = `${folder}/${key}.${extension}`;
      const upload_name = key;
      promises.push(
        uploadPatientFile(
          upload_url,
          files[folder][key],
          folder,
          patient,
          (progress) => {
            upload_progress[upload_name] = progress;
            set_upload_progress(upload_progress);
            const total_progress =
              (Object.values(upload_progress).reduce((a, b) => a + b)) /
              Object.values(upload_progress).length;
            set_total_upload_progress(parseInt(total_progress));
          }
        )
        // Amplify.Storage.put(upload_url, files[folder][key], {
        //   level: "protected",
        //   identityId: userid,
        //   bucket: "archform-patient-data-v1",
        //   progressCallback(progress) {
        //     upload_progress[upload_name] = progress.loaded / progress.total;
        //     set_upload_progress(upload_progress);
        //     const total_progress =
        //       (100 * Object.values(upload_progress).reduce((a, b) => a + b)) /
        //       Object.values(upload_progress).length;

        //     set_total_upload_progress(total_progress.toFixed());
        //   },
        // })
      );
    }
  }
  window.scrollTo(0, 0);
  const manufacturing = patient.manufacturing;
  const alignment = patient.alignment;

  // set address if being created/updated by patient owner
  if (manufacturing !== "In Office" && user_userid === patient.userid) {
    const user = await getUser()
    // new patient
    if (!patient.shipping_address) {
      patient.shipping_address = user.attributes.address;
      // patient being updated
    } else {
      if (user_userid === patient.userid && update_address) {
        patient.shipping_address = user.attributes.address;
      }
    }
  }
  if (!draft && patient.status === "Draft") {
    patient.status = "Unprocessed";
  }
  if (create) {
    try {
      await createPatient(patient);
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      await updatePatient(patient);
    } catch (err) {
      console.log(err);
    }
  }
  await Promise.all(promises);
  // if (!create) return;

  // const default_source = await getDefaultSource();
  // const subscription = await getSubscriptions();
  // const subscription_plan = subscription ?
  //     subscription.plan.id : null;

  // // console.log(subscription)
  // // console.log(subscription_plan)
  // // console.log(default_source)
  // // console.log(alignment)
  // // console.log(manufacturing)

  // const callback_url = `${window.location.origin}/patients/${hashCode(patient)}`;
  // console.log(callback_url)
  // let checkout;
  // if (manufacturing == 'In Office') {
  //     const free_trial = await getFreeTrialActive();
  //     if (!free_trial) {
  //         checkout = await getProductCheckout(callback_url);
  //     }
  //     // if (!getFreeTrialActive)
  //     // if (alignment == 'In Office') {
  //     //     if (!subscription_plan || subscription_plan === 'price_1J0tPPIffkoglhLDaRMcVdLn') {
  //     //         checkout = await getProductCheckout(callback_url, archform_products[alignment]);
  //     //     }
  //     // } else {
  //     //     checkout = await getProductCheckout(callback_url, archform_products[alignment]);
  //     // }
  // } else if (!default_source) {
  //     checkout = await getProductCheckout(callback_url);
  // }
  // // console.log(checkout)
  // if (checkout) {
  //     window.location = checkout;
  // }
};

const saveAsDraft = async (
  patient,
  files,
  update_address,
  upload_progress,
  set_upload_progress,
  set_total_upload_progress,
  history,
  set_is_submitting,
  form_ref
) => {
  if (form_ref && form_ref.current) {
    form_ref.current.reportValidity();
    if (!form_ref.current.checkValidity()) {
      return;
    }
  }
  patient.status = "Draft";
  await uploadPatient(
    patient,
    files,
    update_address,
    true,
    upload_progress,
    set_upload_progress,
    set_total_upload_progress,
    true,
    true
  );
  // history.push("/#Draft_1");
};

const _isActiveProduct = (alignment, manufacturing, patient) =>
  (!alignment || patient.alignment === alignment) &&
  (!manufacturing || patient.manufacturing === manufacturing);

const _setProduct = (alignment, manufacturing, setters, patient) => {
  if (_isActiveProduct(alignment, manufacturing, patient)) {
    if (alignment) setters.alignment("");
    if (manufacturing) setters.manufacturing("");
  } else {
    if (alignment) setters.alignment(alignment);
    if (
      manufacturing &&
      manufacturing.includes("Stages") &&
      alignment !== "Orthodontist"
    ) {
      setters.manufacturing("");
    }
    if (manufacturing) setters.manufacturing(manufacturing);
    // if (manufacturing && manufacturing !== "In Office") {
    //   setters.aligner_trim_style("Straight");
    // }
  }
};

const setAlignment = (alignment, setters, patient) => {
  if (patient.alignment === alignment) {
    setters.alignment("");
  } else {
    setters.alignment(alignment);
  }
};

const setManufacturing = (manufacturing, setters, patient) => {
  if (patient.manufacturing === manufacturing) {
    setters.manufacturing("");
  } else {
    setters.manufacturing(manufacturing);
  }
};

const CreatePatient = ({
  patients,
  user,
  updatePatientList,
  history,
  location,
  match,
}) => {
  const tooth_num_system = user
    ? user.attributes["custom:tooth_num_system"]
    : "UNS";

  let patient =
    location.state && location.state.patient
      ? location.state.patient
      : {
        firstname: "",
        lastname: "",
        dob: "",
        gender: "",
        appointmentdate: "",
        setupapprovaldate: "",
        notes: "",
        id: "#" + Math.random().toString(36).substr(2, 9),
        patientid: uuidv4(),
        attachment_restrictions: [],
        movement_restrictions: [],
        extractions: [],
        missing_teeth: [],
        alignment: "",
        manufacturing: "",
        custom_treatment: [],
        aligner_trim_style: "",
        clinical_preferences: {
          arch_to_treat: "Both",
          treatment_type: "Full Arch",
          overjet: "Maintain",
          overbite: "Do not correct",
          midline: "Maintain",
          posterior_crossbite: "Do not correct",
          spacing: "Close All",
          crowding_upper: {
            expand: "As Needed",
            procline: "As Needed",
            ipr_anterior: "As Needed",
            ipr_posterior_right: "As Needed",
            ipr_posterior_left: "As Needed",
          },
          crowding_lower: {
            expand: "As Needed",
            procline: "As Needed",
            ipr_anterior: "As Needed",
            ipr_posterior_right: "As Needed",
            ipr_posterior_left: "As Needed",
          },
          ap_relationship: {
            left_canine: "Maintain",
            right_canine: "Maintain",
            left_molar: "Maintain",
            right_molar: "Maintain",
            distalization: "None",
            posterior_ipr: "No",
          },
        },
      };

  const files =
    location.state && location.state.files
      ? location.state.files
      : {
        composite: {
          full_face_smiling: "",
          front_view: "",
          full_face_not_smiling: "",
          left_lateral: "",
          lower_occlusal: "",
          profile_not_smiling: "",
          right_lateral: "",
          upper_occlusal: "",
          full_composite: "",
        },
        xray: {
          xray_pano: "",
          xray_fmx: "",
        },
        models: {
          stl_upper: "",
          stl_lower: "",
        },
      };

  let setters = {
    clinical_preferences: {
      crowding_upper: {},
      crowding_lower: {},
      ap_relationship: {},
    },
  };

  for (var folder in files) {
    for (var key in files[folder]) {
      // eslint-disable-next-line
      [files[folder][key], setters[key]] = useState();
    }
  }

  if (history.location.pathname.includes("edit-patient")) {
    // const old_patient = patients.find(patient => patient.patientid === match.params.num);
    const old_patient = {
      ...patients.find((patient) => {
        if (
          match.params.num.match(/-/g) &&
          match.params.num.match(/-/g).length === 4
        )
          return patient.patientid === match.params.num;
        else return hashCode(patient) === match.params.num;
      }),
    };
    patient = {
      ...patient,
      ...old_patient,
      clinical_preferences: {
        ...patient.clinical_preferences,
        ...old_patient.clinical_preferences,
      },
    };
    // eslint-disable-next-line
    useMemo(() => {
      loadFiles(setters, patient.userid, patient.patientid);
    }, [patient.userid, patient.patientid]);
  }

  for (var key in patient) {
    if (key === "clinical_preferences") {
      for (var cckey in patient[key]) {
        if (cckey.includes("crowding") || cckey.includes("ap_relationship")) {
          for (var innercckey in patient[key][cckey]) {
            [patient[key][cckey][innercckey], setters[key][cckey][innercckey]] =
              // eslint-disable-next-line
              useState(patient[key][cckey][innercckey]);
          }
        } else {
          // eslint-disable-next-line
          [patient[key][cckey], setters[key][cckey]] = useState(
            patient[key][cckey]
          );
        }
      }
    } else {
      // eslint-disable-next-line
      [patient[key], setters[key]] = useState(patient[key]);
    }
  }
  let valid_address = true;
  // try {
  //     JSON.parse(user.attributes.address)
  //     valid_address = true;
  // } catch(err) {
  //     valid_address = false;
  // }

  const is_edit_patient = history.location.pathname.includes("edit-patient");
  const [is_submitting, set_is_submitting] = useState(false);
  const [update_address, set_update_address] = useState(is_edit_patient && !patient.shipping_address);
  const [submit_req_modal_visible, set_submit_req_modal_visible] =
    useState(false);
  const can_submit =
    !is_submitting &&
    (is_edit_patient ||
      (patient.alignment &&
        !is_submitting &&
        patient.manufacturing &&
        patient.firstname &&
        patient.lastname &&
        files.models.stl_lower &&
        files.models.stl_upper));
  const [expanded_product, set_expanded_product] = useState("");
  const [in_office_alingers_printed, set_in_office_alingers_printed] =
    useState(3);

  const clearActiveProduct = () => setProduct("", "", setters, patient);
  const clearExpandedProduct = () => set_expanded_product("");

  const setProduct = (alignment, manufacturing, keep_open) => {
    if (!keep_open) clearExpandedProduct();
    _setProduct(alignment, manufacturing, setters, patient);
  };
  const setExpandedProduct = (product) => {
    clearActiveProduct();
    set_expanded_product(expanded_product === product ? "" : product);
  };

  const isActiveProduct = (alignment, manufacturing) =>
    _isActiveProduct(alignment, manufacturing, patient);

  const [upload_progress, set_upload_progress] = useState({});
  const [total_upload_progress, set_total_upload_progress] = useState(0);
  const [checked_duplicates, set_checked_duplicates] = useState(false);

  const form_ref = React.createRef();

  const [stage, _set_stage] = useState(0);
  // let curr_stage_complete = false;
  // switch (stage) {
  //     case 0:
  //         if (patient.firstname && patient.lastname) curr_stage_complete = true
  //         break;
  //     case 1:
  //         if (files.models.stl_upper && files.models.stl_lower) curr_stage_complete = true
  //         break;
  //     default:
  //         break;
  // }
  const [stage_complete, set_stage_complete] = useState(true);

  const check_input = (set_field) => (e) => {
    set_field(e.target.value);
    // if (form_ref.current) {
    //     set_stage_complete(form_ref.current.checkValidity())
    // }
    // console.log(e);
  };

  const set_stage = (new_stage) => {
    if (new_stage < stage && stage > 0) {
      _set_stage(new_stage);
      window.scrollTo(0, 0);
      // set_stage_complete(true)
    }
    // else if (new_stage === 4) {
    //     for (const key of Object.keys(patient.clinical_preferences)) {
    //         if (['crowding_upper', 'crowding_lower', 'ap_relationship'].includes(key)) {
    //             for (const sub_key of Object.keys(patient.clinical_preferences[key])) {

    //             }
    //         } else {

    //         }
    //     }
    // }
    else if (stage_complete) {
      if (form_ref.current) {
        form_ref.current.reportValidity();
        if (!form_ref.current.checkValidity()) {
          return;
        }
      }
      if (
        history.location.pathname.includes("create-patient") &&
        !checked_duplicates
      ) {
        set_checked_duplicates(true);
        const idx = patients.findIndex(
          (cpatient) =>
            patient.firstname === cpatient.firstname &&
            patient.lastname === cpatient.lastname
        );
        if (idx !== -1) {
          message.warning({
            content: "A patient with this name already exists",
          });
        }
        console.log(idx);
      }
      _set_stage(new_stage);
      window.scrollTo(0, 0);
      // set_stage_complete(false)
    }
    //  else if (form_ref.current) {
    //     form_ref.current.reportValidity();
    // }
  };

  const set_file = (_set_file) => (new_file) => {
    _set_file(new_file);
    // if (form_ref.current) {
    //     set_stage_complete(form_ref.current.checkValidity())
    // }
    // console.log(new_file);
  };
  // useEffect(
  //     () => {
  //         switch(stage) {
  //             case 0:
  //                 if (patient.firstname && patient.lastname) set_stage_complete(true);
  //                 break;
  //             case 1:
  //                 if (files.models.stl_upper && files.models.stl_lower) set_stage_complete(true);
  //                 break;
  //             default:
  //                 break;
  //         }

  //     },
  //     [stage]
  // );
  return (
    <div className="create-patient-container">
      <PatientWindowNavbar
        stage={stage}
        set_stage={set_stage}
        stage_complete={stage_complete}
        patient={patient}
        can_submit={can_submit}
        set_submit_req_modal_visible={set_submit_req_modal_visible}
        currySaveAsDraft={async () => {
          set_is_submitting(true);
          await saveAsDraft(
            patient,
            files,
            update_address,
            upload_progress,
            set_upload_progress,
            set_total_upload_progress,
            history,
            form_ref
          );
          window.location = `${window.location.origin}/#Draft_1`;
          set_is_submitting(false);
        }}
        curryUploadPatient={async () => {
          set_is_submitting(true);
          await uploadPatient(
            patient,
            files,
            update_address,
            !history.location.pathname.includes("edit-patient"),
            upload_progress,
            set_upload_progress,
            set_total_upload_progress,
            false,
            can_submit
          );
          window.location = `${window.location.origin}/patients/${hashCode(
            patient
          )}`;
          set_is_submitting(false);
        }}
      />
      <div className="patient-window-container">
        <div
          className="submit-patient-overlay"
          style={{ display: is_submitting ? "flex" : "none" }}
        >
          <Spin
            size="large"
            tip={`Uploading patient ${total_upload_progress}%`}
          />
        </div>
        <Prompt
          when={is_submitting}
          message={(location) =>
            `Leaving now will cause patient upload to fail`
          }
        />
        {/* <Spin spinning={is_submitting} > */}
        {stage === 0 ? (
          <>
            <p className="patient-window-stage-title">Patient Info</p>
            <h3>Basic info</h3>
            <form ref={form_ref}>
              <div className="basic-info-container">
                <input
                  className="form-field"
                  placeholder="First name"
                  value={patient.firstname}
                  // onChange={(e) => setters.firstname(e.target.value)}
                  onChange={check_input(setters.firstname)}
                  required
                />
                <input
                  className="form-field"
                  placeholder="Last name"
                  value={patient.lastname}
                  onChange={check_input(setters.lastname)}
                  required
                />
                <input
                  className="form-field"
                  placeholder="Date of birth"
                  type="date"
                  value={patient.dob}
                  onChange={check_input(setters.dob)}
                />
                <input
                  className="form-field"
                  placeholder="Gender"
                  value={patient.gender}
                  onChange={check_input(setters.gender)}
                />
              </div>
            </form>
            <h1>Additional info (optional)</h1>
            <div className="additional-info-container">
              <h3>Internal tracking ID</h3>
              <input
                className="form-field"
                value={patient.id}
                onChange={(e) => setters.id(e.target.value)}
              />
              <h3>Treatment timeline</h3>
              <div className="treatment-timeline-container">
                <input
                  className="form-field"
                  placeholder="Appointment date"
                  type="date"
                  value={patient.appointmentdate}
                  onChange={(e) => setters.appointmentdate(e.target.value)}
                />
                <input
                  className="form-field"
                  placeholder="Setup approval date"
                  type="date"
                  value={patient.setupapprovaldate}
                  onChange={(e) => setters.setupapprovaldate(e.target.value)}
                />
              </div>
              <h3>Custom Tags</h3>
              <CustomTreatmentTags
                tags={patient.custom_treatment}
                set_tags={setters.custom_treatment}
              />
            </div>
            <div style={{ height: "50px" }} />
          </>
        ) : stage == 1 ? (
          <>
            <p className="patient-window-stage-title">Files</p>
            <h3>Scans</h3>
            <form ref={form_ref}>
              <div className="scans-upload-container">
                {/* <div className='file-upload-field'>
                    <div><img src={stl_upper_outline} /></div>
                    <p className='uppercase-text'>STL Upper</p>
                    <p>Drag file or click to select</p>
                </div> */}

                <FileUpload
                  file={files.models.stl_upper}
                  setFile={set_file(setters.stl_upper)}
                  label="STL Upper"
                  preview={stl_upper_outline}
                  uploadedPreview={stl_upper_fill}
                  accept={SCANS_ACCRPTED}
                  required
                />
                <FileUpload
                  file={files.models.stl_lower}
                  setFile={set_file(setters.stl_lower)}
                  label="STL Lower"
                  preview={stl_lower_outline}
                  uploadedPreview={stl_lower_fill}
                  accept={SCANS_ACCRPTED}
                />
              </div>
              <h1 style={{ marginTop: "36px" }}>Photos</h1>
              <h3>Patient photos</h3>
              <div className="patient-photos-container">
                {[
                  ["Full face smiling", full_face_smiling],
                  ["Full face not smiling", full_face_not_smiling],
                  ["Profile Not Smiling", profile_not_smiling],
                  ["Upper Occlusal", upper_occlusal],
                  ["Full Composite"],
                  ["Lower Occlusal", lower_occlusal],
                  ["Right lateral", right_lateral],
                  ["Front View", front_view],
                  ["Left lateral", left_lateral],
                ].map((photo) => {
                  const photo_var = photo[0].toLowerCase().replace(/ /g, "_");
                  return (
                    <FileUpload
                      key={photo[0]}
                      file={files.composite[photo_var]}
                      setFile={setters[photo_var]}
                      label={photo[0]}
                      preview={photo[1]}
                      accept=".jpeg,.jpg,.png"
                    />
                  );
                })}
              </div>
              <h3>X-rays</h3>
              <div className="xray-photos-container">
                <FileUpload
                  file={files.xray.xray_pano}
                  setFile={setters.xray_pano}
                  label="Panoramic"
                  preview={xray_pano}
                  accept=".jpeg,.jpg,.png"
                />
                <FileUpload
                  file={files.xray.xray_fmx}
                  setFile={setters.xray_fmx}
                  label="Cephalometric"
                  preview={xray_ceph}
                  accept=".jpeg,.jpg,.png"
                />
              </div>
            </form>
            <div style={{ height: "50px" }} />
          </>
        ) : stage == 2 ? (
          <>
            <p className="patient-window-stage-title">Alignment</p>

            <div className="product-category-title">
              <p>
                Do you want to design the setup yourself, or have ArchForm do it
                for you?{" "}
              </p>
            </div>
            <div className="product-category-title product-category-divider-label">
              <p>Self Setup</p>
              <p>ArchForm Setup</p>
            </div>
            <div className="product-card-container">
              <ProductCard
                name="Self Setup"
                description={[
                  "Align the teeth yourself using ArchForm's desktop software",
                ]}
                price={["$50/patient or subscription", "or supply order"]}
                active={isActiveProduct("In Office")}
                expands={false}
                onClick={() => setProduct("In Office")}
              />
              <div className="product-card-divider" />
              <div className="archform-product-card-container">
                <ProductCard
                  name="Orthodontist Setup"
                  description={[
                    "Optimized setup created by an  orthodontist",
                    "Refinements $149/setup",
                  ]}
                  price={["$220", ""]}
                  expands={false}
                  active={isActiveProduct("Orthodontist")}
                  onClick={() => setProduct("Orthodontist")}
                />
                <ProductCard
                  name="Technician Setup"
                  description={[
                    "Segmentation and initial alignment done by a technician.  No attachments or modifications.",
                    "Refinements $49/setup",
                  ]}
                  price={["$120", ""]}
                  expands={false}
                  active={isActiveProduct("Technician")}
                  onClick={() => setProduct("Technician")}
                />
              </div>
            </div>
          </>
        ) : stage == 3 ? (
          <>
            <p className="patient-window-stage-title">Manufacturing</p>
            <div className="product-category-title">
              <p>
                Do you want ArchForm to make the aligners for you, or do you
                want to make them in-office?
              </p>
            </div>
            <div className="product-category-title product-category-divider-label">
              <p>In-Office Manufacturing</p>
              <p>ArchForm Manufacturing</p>
            </div>
            <div className="product-card-container">
              <ProductCard
                name="In Office"
                description={["Print and manufacture aligners in your office"]}
                price={[]}
                expands={false}
                active={isActiveProduct("", "In Office")}
                onClick={() => setProduct("", "In Office")}
              />
              <div className="product-card-divider" />
              <div className="archform-product-card-container">
                {/* {patient.alignment === "Orthodontist" ? (
                  <ProductCard
                    name="Comprehensive Aligner Packages"
                    description={[
                      "Orthodontist setups and aligners made in ArchForm's factory",
                      "Refinements $149/setup +$15/aligner",
                    ]}
                    price={["$299-$899", "per patient"]}
                    expands={true}
                    active={expanded_product === "Form Aligners"}
                    onClick={() => setExpandedProduct("Form Aligners")}
                  />
                ) : null} */}
                <ProductCard
                  name="Per Aligner Manufacturing"
                  description={[
                    "Aligners made in ArchForm's factory",
                    "Refinements $17 per aligner",
                  ]}
                  price={["$17", "per aligner"]}
                  expands={false}
                  active={isActiveProduct("", "Per Aligner")}
                  onClick={() => setProduct("", "Per Aligner")}
                />
              </div>
              {/* <ProductCard
                  name="Combined Manufacturing"
                  description={[
                    "Self setup. Print some stages in-office and have the rest made in ArchForm's factory",
                    "Refinements $15 per aligner or free when printed in-office",
                  ]}
                  price={["$15", "per aligner"]}
                  expands={true}
                  active={expanded_product === "In Office + Form Aligners"}
                  onClick={() =>
                    setExpandedProduct("In Office + Form Aligners")
                  } */}
            </div>

            {expanded_product === "In Office + Form Aligners" ? (
              <div className="product-expanded-container">
                <div>
                  <p className="product-expanded-title">Form Aligners</p>
                  <div
                    className={
                      "product-select-button product-expanded-close-button"
                    }
                    onClick={clearExpandedProduct}
                  >
                    <p>Close</p>
                  </div>
                </div>
                <p className="product-expanded-product-name">
                  Self Setup + In-office print
                </p>
                <p className="product-expanded-product-description">
                  Do the setup yourself and have us make the aligners for you.
                </p>
                <div className="product-expanded-partial-manufacturing-selection">
                  <div className="product-info-container product-form-aligners-info-container">
                    <div className="product-info-price-container">
                      <p>Cost per aligner</p>
                      <div>
                        <p>$17</p>
                      </div>
                    </div>
                    <div
                      className={
                        isActiveProduct(
                          "In Office",
                          `${in_office_alingers_printed} In Office + Form Aligners`
                        )
                          ? "product-selected-button"
                          : "product-select-button"
                      }
                      onClick={() =>
                        setProduct(
                          "In Office",
                          `${in_office_alingers_printed} In Office + Form Aligners`,
                          true
                        )
                      }
                    >
                      {isActiveProduct(
                        "In Office",
                        `${in_office_alingers_printed} In Office + Form Aligners`
                      ) ? (
                        <p>Selected</p>
                      ) : (
                        <p>Select</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="product-expanded-product-name">
                      How many stages do you want to print in office?{" "}
                    </p>
                    <p className="product-expanded-product-description">
                      Verify number of stages to print in-office
                    </p>
                    <div className="product-selection-self-manufacture-selector">
                      <Minus
                        onClick={() => {
                          if (in_office_alingers_printed > 0) {
                            const num_in_office =
                              in_office_alingers_printed - 1;
                            set_in_office_alingers_printed(num_in_office);
                            if (
                              patient.manufacturing.includes(
                                "In Office + Form Aligners"
                              )
                            ) {
                              setProduct(
                                "In Office",
                                `${num_in_office} In Office + Form Aligners`,
                                true
                              );
                            }
                          }
                        }}
                        size={12}
                      />
                      <p>{in_office_alingers_printed}</p>
                      <Plus
                        onClick={() => {
                          const num_in_office = in_office_alingers_printed + 1;
                          set_in_office_alingers_printed(num_in_office);
                          if (
                            patient.manufacturing.includes(
                              "In Office + Form Aligners"
                            )
                          ) {
                            setProduct(
                              "In Office",
                              `${num_in_office} In Office + Form Aligners`,
                              true
                            );
                          }
                        }}
                        size={12}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* {expanded_product === "Form Aligners" ? (
              <div className="product-expanded-container">
                <div>
                  <p className="product-expanded-title">Form Aligners</p>
                  <div
                    className={
                      "product-select-button product-expanded-close-button"
                    }
                    onClick={clearExpandedProduct}
                  >
                    <p>Close</p>
                  </div>
                </div>
                <p className="product-expanded-product-name">
                  Packaged Orthodontist Setups
                </p>
                <p className="product-expanded-product-description">
                  Includes Orthodontist setup ($200 value)
                </p>
                <p className="product-expanded-product-description">
                  Includes 1 refinement. Subsequent refinements are $15/aligner
                </p>

                <div className="product-selection-form-aligner-stages">
                  {[
                    ["5", "299"],
                    ["7", "399"],
                    ["10", "499"],
                    ["14", "699"],
                    ["Unlimited", "899"],
                  ].map((product) => (
                    <div>
                      <div className="product-info-container product-form-aligners-info-container">
                        <div className="product-info-price-container">
                          <p>{`${product[0]} Stages`}</p>
                          <div>
                            <p>{`$${product[1]}`}</p>
                          </div>
                        </div>
                        <div
                          className={
                            isActiveProduct(
                              "Orthodontist",
                              `${product[0]} Stages`
                            )
                              ? "product-selected-button"
                              : "product-select-button"
                          }
                          onClick={() =>
                            setProduct(
                              "Orthodontist",
                              `${product[0]} Stages`,
                              true
                            )
                          }
                        >
                          {isActiveProduct(
                            "Orthodontist",
                            `${product[0]} Stages`
                          ) ? (
                            <p>Selected</p>
                          ) : (
                            <p>Select</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null} */}
            {/* {(patient.manufacturing &&
                patient.manufacturing !== "In Office") ||
              expanded_product === "Form Aligners" ||
              expanded_product === "In Office + Form Aligners" ? ( */}
            <div
              style={{
                opacity:
                  (patient.manufacturing &&
                    patient.manufacturing !== "In Office") ||
                    // expanded_product === "Form Aligners" ||
                    expanded_product === "In Office + Form Aligners"
                    ? 1
                    : 0,
              }}
            >
              <AddressView />
              {is_edit_patient ?
                <Checkbox style={{ marginTop: '10px' }} checked={update_address} onChange={(e) => set_update_address(e.target.checked)}>Use this address for patient</Checkbox>
                : null
              }
            </div>
            {/* ) : null} */}

            <div style={{ height: "50px" }} />
          </>
        ) : stage === 4 ? (
          <>
            <form ref={form_ref}>
              <h1>Prescription</h1>
              <h3>Notes</h3>
              <textarea
                value={patient.notes}
                onChange={(e) => setters.notes(e.target.value)}
                className="notes-input"
                placeholder="Type here..."
              />

              <h3>Prescription</h3>
              <div className="clinical-preferences-container">
                {[
                  ["Arch To Treat", ["Both", "Upper", "Lower"]],
                  ["Treatment Type", ["Full Arch", "Full Anterior"]],
                  ["Overjet", ["Maintain", "Improve"]],
                  ["Midline", ["Maintain", "Improve"]],
                  ["Overbite", ["Do not correct", "Correct"]],
                  ["Posterior Crossbite", ["Do not correct", "Correct"]],
                  ["Spacing", ["Close All", "Leave All"]],
                ].map((cc) => {
                  const cc_var = cc[0].toLowerCase().replace(/ /g, "_");
                  return (
                    <div key={cc} className="clinical-preferences-input">
                      <p>{cc[0]}</p>
                      <DropdownInput
                        value={patient["clinical_preferences"][cc_var]}
                        setValue={setters["clinical_preferences"][cc_var]}
                        choices={cc[1]}
                        required
                      />
                    </div>
                  );
                })}
              </div>

              <h3>Clinical conditions</h3>
              <div className="clinical-conditions-container-crowding">
                <h4>Crowding</h4>
                <h4>Upper</h4>
                <h4>Lower</h4>
                {[
                  "Expand",
                  "Procline",
                  "IPR Anterior",
                  "IPR Posterior Right",
                  "IPR Posterior Left",
                ].map((cc) => {
                  const cc_var = cc.toLowerCase().replace(/ /g, "_");
                  return (
                    <React.Fragment key={cc}>
                      <p>{cc}</p>
                      <DropdownInput
                        value={
                          patient["clinical_preferences"].crowding_lower[cc_var]
                        }
                        setValue={
                          setters["clinical_preferences"].crowding_lower[cc_var]
                        }
                        choices={["As Needed", "Primarily", "None"]}
                        required
                      />
                      <DropdownInput
                        value={
                          patient["clinical_preferences"].crowding_upper[cc_var]
                        }
                        setValue={
                          setters["clinical_preferences"].crowding_upper[cc_var]
                        }
                        choices={["As Needed", "Primarily", "None"]}
                        required
                      />
                    </React.Fragment>
                  );
                })}
                <div className="clinical-conditions-divider-crowding" />
              </div>
              <div className="clinical-conditions-container-ap-relationship">
                <h4>A - P Relationship</h4>
                <h4>Left</h4>
                <h4>Right</h4>
                {["Canine", "Molar"].map((cc) => {
                  const cc_var = cc.toLowerCase().replace(/ /g, "_");
                  return (
                    <React.Fragment key={cc}>
                      <p>{cc}</p>
                      <DropdownInput
                        value={
                          patient["clinical_preferences"].ap_relationship[
                          `left_${cc_var}`
                          ]
                        }
                        setValue={
                          setters["clinical_preferences"].ap_relationship[
                          `left_${cc_var}`
                          ]
                        }
                        choices={["Maintain", "Improve"]}
                        required
                      />
                      <DropdownInput
                        value={
                          patient["clinical_preferences"].ap_relationship[
                          `right_${cc_var}`
                          ]
                        }
                        setValue={
                          setters["clinical_preferences"].ap_relationship[
                          `right_${cc_var}`
                          ]
                        }
                        choices={["Maintain", "Improve"]}
                        required
                      />
                    </React.Fragment>
                  );
                })}
                <p>Sequential Distalization</p>
                <DropdownInput
                  value={
                    patient["clinical_preferences"].ap_relationship
                      .distalization
                  }
                  setValue={
                    setters["clinical_preferences"].ap_relationship
                      .distalization
                  }
                  choices={["Yes", "No"]}
                  required
                />
                <div />
                <p>Posterior IPR</p>
                <DropdownInput
                  value={
                    patient["clinical_preferences"].ap_relationship
                      .posterior_ipr
                  }
                  setValue={
                    setters["clinical_preferences"].ap_relationship
                      .posterior_ipr
                  }
                  choices={["Yes", "No"]}
                  required
                />
                <div />

                <div className="clinical-conditions-divider-ap-relationship" />
              </div>

              <h3>Tooth movement restrictions</h3>
              <TeethTable
                table={patient.movement_restrictions || []}
                set_table={setters.movement_restrictions}
                system={tooth_num_system}
              />
              <h3>Attachment restrictions</h3>
              <TeethTable
                table={patient.attachment_restrictions || []}
                set_table={setters.attachment_restrictions}
                system={tooth_num_system}
              />
              <h3>Extractions</h3>
              <TeethTable
                extractions
                table={patient.extractions || []}
                set_table={setters.extractions}
                system={tooth_num_system}
              />
              <h3>Missing teeth</h3>
              <TeethTable
                table={patient.missing_teeth || []}
                set_table={setters.missing_teeth}
                system={tooth_num_system}
              />
              <div style={{ height: "50px" }} />
            </form>
          </>
        ) : stage == 5 ? (
          <>
            <p className="patient-window-stage-title">Summary</p>
            <h2 className="patient-summary-section-header">
              Patient Info and Photos
            </h2>
            <div className="patient-summary-info-and-photos-container">
              <div className="patient-summary-info-container">
                <div>
                  <div className="patient-summary-info-field-container">
                    <p>First name</p>
                    <p>{patient.firstname}</p>
                  </div>
                  <div className="patient-summary-info-field-container">
                    <p>Last name</p>
                    <p>{patient.lastname}</p>
                  </div>
                  <div className="patient-summary-info-field-container">
                    <p>Gender</p>
                    <p>{patient.gender || "-"}</p>
                  </div>
                </div>
                <div>
                  <div className="patient-summary-info-field-container">
                    <p>Date of Birth</p>
                    <p>{patient.dob || "-"}</p>
                  </div>
                  <div className="patient-summary-info-field-container">
                    <p>Appointment date</p>
                    <p>{patient.appointmentdate || "-"}</p>
                  </div>
                  <div className="patient-summary-info-field-container">
                    <p>Setup approval date</p>
                    <p>{patient.setupapprovaldate || "-"}</p>
                  </div>
                </div>
                <div>
                  <div className="patient-summary-info-field-container">
                    <p>Internal tracking ID</p>
                    <p>{patient.id || "-"}</p>
                  </div>
                </div>
              </div>

              <div className="patient-summary-photos-container">
                <div className="flex-column">
                  <p>Photos</p>

                  {[
                    [
                      "full_face_smiling",
                      "full_face_not_smiling",
                      "profile_not_smiling",
                    ],
                    ["upper_occlusal", "full_composite", "lower_occlusal"],
                    ["right_lateral", "front_view", "left_lateral"],
                  ].map((photos) => {
                    const is_full_composite = photos[1] === "full_composite";
                    photos = photos.map((photo) => {
                      const photo_obj = files.composite[photo];
                      if (photo_obj && photo_obj.path) {
                        return URL.createObjectURL(photo_obj);
                      } else {
                        return photo_obj;
                      }
                    });
                    return (
                      <div className="flex-row">
                        {photos[0] ? (
                          <div className="patient-summary-photo-field-container-clear">
                            <img src={photos[0]} />
                          </div>
                        ) : (
                          <div className="patient-summary-photo-field-container">
                            <Image color="var(--gray-3)" />
                          </div>
                        )}
                        {photos[1] ? (
                          <div className="patient-summary-photo-field-container-clear">
                            <img src={photos[1]} />
                          </div>
                        ) : is_full_composite ? (
                          <div
                            style={{ opacity: "0" }}
                            className="patient-summary-photo-field-container"
                          ></div>
                        ) : (
                          <div className="patient-summary-photo-field-container">
                            <Image color="var(--gray-3)" />
                          </div>
                        )}
                        {photos[2] ? (
                          <div className="patient-summary-photo-field-container-clear">
                            <img src={photos[2]} />
                          </div>
                        ) : (
                          <div className="patient-summary-photo-field-container">
                            <Image color="var(--gray-3)" />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div style={{ marginLeft: "48px" }} className="flex-column">
                  <p>X-Rays</p>
                  {["xray_pano", "xray_fmx"].map((xray) => {
                    let xray_obj = files.xray[xray];
                    if (xray_obj && xray_obj.path) {
                      xray_obj = URL.createObjectURL(xray_obj);
                    }
                    if (xray_obj)
                      return (
                        <div className="patient-summary-xray-field-container-clear">
                          <img src={xray_obj} />
                        </div>
                      );
                    else
                      return (
                        <div className="patient-summary-xray-field-container">
                          <Image color="var(--gray-3)" />
                        </div>
                      );
                  })}
                </div>
              </div>
            </div>
            <div
              className="flex-row align-center"
              style={{ margin: "64px 0px 8px 0px" }}
            >
              <h2 className="patient-summary-section-header">Product</h2>
              <div
                className="patient-summary-edit-button"
                onClick={() => set_stage(2)}
              >
                <p>Change Product</p>
              </div>
            </div>
            <div className="patient-summary-product-container flex-row">
              {patient.alignment ? (
                <div className="flex-column">
                  <p className="proudct-summary-category-label">Alignment</p>
                  <ProductCard
                    name={patient.alignment}
                    description={
                      product_description.alignment[patient.alignment]
                        .description
                    }
                    // price={
                    //   product_description.alignment[patient.alignment].price
                    // }
                    price={[""]}
                    // active={isActiveProduct("In Office", "In Office")}
                    expands={false}
                    summary={true}
                    style={{ marginRight: "16px" }}
                  // onClick={() => setProduct("In Office", "In Office")}
                  />
                </div>
              ) : null}
              {patient.manufacturing ? (
                <div className="flex-column">
                  <p className="proudct-summary-category-label">
                    Manufacturing
                  </p>
                  <ProductCard
                    name={patient.manufacturing}
                    description={
                      patient.manufacturing.includes("Stage") ||
                        patient.manufacturing.includes("Aligners")
                        ? product_description.manufacturing["Form Aligners"]
                          .description
                        : 
                      product_description.manufacturing[
                        patient.manufacturing
                        ] &&
                        product_description.manufacturing[
                          patient.manufacturing
                        ].description
                    }
                    price={[""]}
                    // active={isActiveProduct("In Office", "In Office")}
                    expands={false}
                    summary={true}
                  // onClick={() => setProduct("In Office", "In Office")}
                  />
                </div>
              ) : null}
            </div>
            <div className="patient-summary-product-container flex-row">
              <h2 className="patient-summary-section-header">
                {`Total Price: $${getProductPriceTotal(
                  patient.alignment,
                  patient.manufacturing
                )}`}
                <span className="product-summary-price-details">{`${getProductPriceLabel(
                  patient.alignment,
                  patient.manufacturing
                )}`}</span>
              </h2>
            </div>

            {patient.manufacturing && patient.manufacturing !== "In Office" ? (
              <>
                <AddressView />
                {is_edit_patient ?
                  <Checkbox style={{ marginTop: '10px' }} checked={update_address} onChange={(e) => set_update_address(e.target.checked)}>Use this address for patient</Checkbox>
                  : null
                }
              </>
            ) : null}

            <div
              className="flex-row align-center"
              style={{ margin: "64px 0px 8px 0px" }}
            >
              <h2 className="patient-summary-section-header">Prescription</h2>
              <div
                className="patient-summary-edit-button"
                onClick={() => set_stage(4)}
              >
                <p>Edit Prescription</p>
              </div>
            </div>
            <PrescriptionView patient={patient} user={user} />
          </>
        ) : (
          <></>
        )}
        {/* </Spin> */}
      </div>

      {/* <PatientWindowBottomNavbar
        stage={stage}
        stage_complete={stage_complete}
        set_stage={set_stage}
      /> */}
      <Modal
        title="Patient Details Missing"
        visible={submit_req_modal_visible}
        onOk={() => set_submit_req_modal_visible(false)}
        onCancel={() => set_submit_req_modal_visible(false)}
        footer={[
          <AntButton
            key="OK"
            type="primary"
            onClick={() => set_submit_req_modal_visible(false)}
          >
            OK
          </AntButton>,
        ]}
      >
        <p>
          All patients must have scans uploaded and a treatment plan selected
          from the Alignment and Manufacturing tabs
        </p>
        {patient.manufacturing !== "In Office" ? (
          <p>ArchForm aligner orders require a valid shipping address</p>
        ) : null}
      </Modal>
    </div>
  );
};

export default withRouter(CreatePatient);