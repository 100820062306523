import React from "react";
import { Row, Col, Divider } from "antd";
import "./PatientView.css";

export function convertUNS(num, system) {
  if (system === "Palmer") {
    if (num < 9) return 9 - num;
    if (num < 17) return num - 8;
    if (num < 25) return 25 - num;
    return num - 24;
  } else if (system === "ISO") {
    if (num < 9) return 19 - num;
    if (num < 17) return num + 12;
    if (num < 25) return 55 - num;
    return num + 16;
  } else if (system === "UNS") {
    return num;
  }
  console.error("Cannot convert UNS to " + system);
}

export const TeethTable = ({ table, system }) => (
  <Row className="teeth-checkbox teeth-table">
    <Col span={24} style={{ display: "flex" }}>
      <div>
        <div>
          {[...Array(9).keys()].slice(1).map((val) => {
            return table.includes(val.toString()) ? (
              <div
                key={val}
                className="teeth-checkbox-item teeth-checkbox-selected"
              >
                {convertUNS(val, system)}
              </div>
            ) : (
              <div key={val} className="teeth-checkbox-item">
                {convertUNS(val, system)}
              </div>
            );
            // <Select size="small"> {val}</Select>:
            // <Select size="small"> {val}</Select>
          })}
        </div>
        <Divider />
        <div>
          {[...Array(33).keys()]
            .slice(25)
            .reverse()
            .map((val) => {
              return table.includes(val.toString()) ? (
                <div
                  key={val}
                  className="teeth-checkbox-item teeth-checkbox-selected"
                >
                  {convertUNS(val, system)}
                </div>
              ) : (
                <div key={val} className="teeth-checkbox-item">
                  {convertUNS(val, system)}
                </div>
              );
            })}
        </div>
      </div>
      <div>
        <Divider style={{ height: "100%" }} type="vertical" />
      </div>
      <div>
        <div>
          {[...Array(17).keys()].slice(9).map((val) => {
            return table.includes(val.toString()) ? (
              <div
                key={val}
                className="teeth-checkbox-item teeth-checkbox-selected"
              >
                {convertUNS(val, system)}
              </div>
            ) : (
              <div key={val} className="teeth-checkbox-item">
                {convertUNS(val, system)}
              </div>
            );
          })}
        </div>
        <Divider />
        <div>
          {[...Array(25).keys()]
            .slice(17)
            .reverse()
            .map((val) => {
              return table.includes(val.toString()) ? (
                <div
                  key={val}
                  className="teeth-checkbox-item teeth-checkbox-selected"
                >
                  {convertUNS(val, system)}
                </div>
              ) : (
                <div key={val} className="teeth-checkbox-item">
                  {convertUNS(val, system)}
                </div>
              );
            })}
        </div>
      </div>
    </Col>
  </Row>
);

function PrescriptionView({ patient, user }) {
  const tooth_num_system = user
    ? user.attributes["custom:tooth_num_system"]
    : "UNS";
  return (
    <>
      <div className="perscription-grid">
        {[
          "Arch To Treat",
          "Treatment Type",
          "Overjet",
          "Midline",
          "Overbite",
          "Posterior Crossbite",
          "Spacing",
          "Aligner Trim Style",
        ].map((cp, idx) => {
          const key = cp.toLowerCase().replace(/ /g, "_");
          return (
            <div key={cp} className="perscription-item">
              <p>{cp}</p>
              <p className="caption-medium-400">
                {patient.clinical_preferences[key] || patient[key] || "-"}
              </p>
            </div>
          );
        })}
      </div>
      <h4 className="patient-details-subtitle">Clinical conditons</h4>
      <div className="clinical-conditions">
        <div className="crowding-table">
          <div className="crowding-col-1">
            <p className="crowding-500">Crowding</p>
            <p className="crowding-gray">Expand</p>
            <p className="crowding-gray">Procline</p>
            <p className="crowding-gray">IPR Anterior</p>
            <p className="crowding-gray">IPR Posterior Right</p>
            <p className="crowding-gray">IPR Posterior Left</p>
          </div>

          <div className="crowding-col">
            <p className="crowding-500">Upper</p>
            {[
              "expand",
              "procline",
              "ipr_anterior",
              "ipr_posterior_right",
              "ipr_posterior_left",
            ].map((cp) => (
              <p key={cp}> {patient.clinical_preferences.crowding_upper && patient.clinical_preferences.crowding_upper[cp] || "-"}</p>
            ))}
            {/* <p>As needed</p>
                  <p>As needed</p>
                  <p>As needed</p>
                  <p>As needed</p>
                  <p>As needed</p> */}
          </div>

          <div className="crowding-col">
            <p className="crowding-500">Lower</p>
            {[
              "expand",
              "procline",
              "ipr_anterior",
              "ipr_posterior_right",
              "ipr_posterior_left",
            ].map((cp) => (
              <p key={cp}> {patient.clinical_preferences.crowding_lower && patient.clinical_preferences.crowding_lower[cp] || "-"}</p>
            ))}
          </div>
        </div>

        <div className="relationship-table">
          <div className="crowding-col-1">
            <p className="crowding-500">A - P Relationship</p>
            <p className="crowding-gray">Canine</p>
            <p className="crowding-gray">Molar</p>
          </div>

          <div className="crowding-col">
            <p className="crowding-500">Left</p>
            <p>
              {" "}
              {patient.clinical_preferences.ap_relationship && patient.clinical_preferences.ap_relationship["left_canine"] || "-"}
            </p>
            <p> {patient.clinical_preferences.ap_relationship && patient.clinical_preferences.ap_relationship["left_molar"] || "-"}</p>
          </div>

          <div className="crowding-col">
            <p className="crowding-500">Right</p>
            <p>
              {patient.clinical_preferences.ap_relationship && patient.clinical_preferences.ap_relationship["right_canine"] || "-"}
            </p>
            <p>{patient.clinical_preferences.ap_relationship && patient.clinical_preferences.ap_relationship["right_molar"] || "-"}</p>
          </div>
        </div>

        <div className="relationship-table-two">
          <div className="crowding-col-1">
            <p className="crowding-gray">Distalization</p>
            <p className="crowding-gray">Posterior IPR</p>
          </div>

          <div className="crowding-col-2">
            <p>
              {patient.clinical_preferences.ap_relationship && patient.clinical_preferences.ap_relationship["distalization"] || "-"}
            </p>
            <p>
              {patient.clinical_preferences.ap_relationship && patient.clinical_preferences.ap_relationship["posterior_ipr"] || "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="teeth-table-container">
        <h4 className="patient-details-subtitle">
          Tooth movement restrictions
        </h4>
        <TeethTable
          table={
            patient.movement_restrictions ? patient.movement_restrictions : []
          }
          system={tooth_num_system}
        />
      </div>
      <div className="teeth-table-container">
        <h4 className="patient-details-subtitle">Attachment restrictions</h4>
        <TeethTable
          table={
            patient.attachment_restrictions
              ? patient.attachment_restrictions
              : []
          }
          system={tooth_num_system}
        />
      </div>
      <div className="teeth-table-container">
        <h4 className="patient-details-subtitle">Extractions</h4>
        <TeethTable
          table={patient.extractions ? patient.extractions : []}
          system={tooth_num_system}
        />
      </div>
      <div className="teeth-table-container">
        <h4 className="patient-details-subtitle">Missing teeth</h4>
        <TeethTable
          table={patient.missing_teeth ? patient.missing_teeth : []}
          system={tooth_num_system}
        />
      </div>
      {patient.clinical_preferences.retainer_trim_style ||
      patient.clinical_preferences.retainer_arches_to_fab ? (
        <>
          <h4 className="patient-details-subtitle">Retainer</h4>
          <div className="perscription-grid">
            <div className="perscription-item">
              <p>Trim Style</p>
              <p className="caption-medium-400">
                {patient.clinical_preferences.retainer_trim_style || "-"}
              </p>
            </div>
            <div className="perscription-item">
              <p>Arches to Fabricate</p>
              <p className="caption-medium-400">
                {patient.clinical_preferences.retainer_arches_to_fab.join('/') || "-"}
              </p>
            </div>
            <div className="perscription-item">
              <p>Manufacturing</p>
              <p className="caption-medium-400">
                {patient.clinical_preferences.retainer_manufacturing || "-"}
              </p>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default PrescriptionView;
