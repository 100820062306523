import React from "react";
import "./Button.css";

export function ButtonDropdownItem(props) {
  return (
    <p className="button-dropdown-item" onClick={props.onClick}>
      {props.children}
    </p>
  );
}

export function Button(props) {
  const button_size = props.size
    ? "comment-button-" + props.size
    : "comment-button";
  return props.dropdown ? (
    <div
      className="comment-button-dropdown"
      style={props.style}
      onClick={props.onClick}
    >
      <button className={button_size}>{props.children}</button>
      <div className="comment-button-dropdown-list">{props.dropdown}</div>
    </div>
  ) : (
    <button className={button_size} onClick={props.onClick} style={props.style}>
      {props.children}
    </button>
  );
}
