import React from "react";
import { useTable, useFlexLayout, useRowSelect, useSortBy } from "react-table";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import moment from "moment";
import { Tag } from "antd";
import { ArrowUpRight, ArrowUp, ArrowDown } from "react-feather";
import { hashCode, deletePatient } from "./Patients";
import { getPatientAvatar } from "./Patients";
import { withRouter } from "react-router-dom";
import { status_color_map } from "./Sidebar";
import { Tag as ArchFormTag } from "./Tag";
// import './Dashboard.css';
import "./PatientTable.css";

export const tag_color = {
  "In Office": "blue",
  ArchForm: "salmon",
  Technician: "red",
  Orthodontist: "magenta",
  "Simple 5": "green",
  "Simple 7": "orange",
  "Simple 10": "yellow",
  "Simple 14": "cyan",
  Complete: "purple",
};

export const stringToColour = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  }
);

const openInArchform = (patient) => {
  window.location =
    "archform:" +
    encodeURIComponent(
      JSON.stringify({
        UUID: patient.patientid,
        IdentityId: patient.userid,
        filename: "",
        refinement: -1,
      })
    );
};

const patient_has_avatar = {}; // hack to deal with row-rerendering of avatars

const Table = ({ avatars, columns, data, onSelectRow, history }) => {
  const initialState = React.useMemo(
    () => ({
      sortBy: [
        {
          id: "created",
          desc: true,
        },
      ],
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useFlexLayout,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => {
            return (
              <div onClick={(e) => e.stopPropagation()}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            );
          },
          width: "5%",
          maxWidth: "30px",
        },
        ...columns,
      ]);
    }
  );
  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      onSelectRow(selectedFlatRows);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="table-row"
        >
          {row.cells.map((cell, i) => {
            const width = cell.column.width ? cell.column.width : "150px";
            const maxWidth = cell.column.maxWidth
              ? cell.column.maxWidth
              : width;
            let onClick = i
              ? () => history.push("/patients/" + hashCode(row.original))
              : undefined;
            if (row.original.status === "Draft") {
              onClick = () =>
                history.push("/edit-patient/" + hashCode(row.original));
            }
            return (
              <div
                {...cell.getCellProps()}
                onClick={onClick}
                style={{ width, maxWidth }}
                className="td"
              >
                {cell.render("Cell")}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds]
  );
  return (
    <AutoSizer>
      {({ height, width }) => (
        <div {...getTableProps()} className="table">
          <div>
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                style={{ width: width, display: "flex" }}
                className="tr"
              >
                {headerGroup.headers.map((column) => {
                  const width = column.width ? column.width : "150px";
                  const maxWidth = column.maxWidth ? column.maxWidth : width;
                  return (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ width, maxWidth }}
                      className="th"
                    >
                      {column.render("Header")}
                      <span style={{ marginLeft: "10px" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDown size={12} color="#46454D" />
                          ) : (
                            <ArrowUp size={12} color="#46454D" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            <FixedSizeList
              className="patient-table-list"
              height={height - 53}
              itemCount={rows.length}
              itemSize={53}
              width={width}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
        </div>
      )}
    </AutoSizer>
  );
};

const RoutedTable = withRouter(Table);

const columns = (avatars) => [
  {
    Header: "Patient",
    id: "patientname",
    width: "15%",
    maxWidth: "250px",
    accessor: (row) =>
      `${row.firstname ? row.firstname + " " : ""}${
        row.lastname ? row.lastname : ""
      }`,
    Cell: ({ value, row }) => {
      const patientid = row.original.patientid;

      const [hasAvatar, setHasAvatar] = React.useState(
        (avatars && avatars[patientid]) || false
      );
      return (
        <span className="patient-table-col-1">
          <div className="patient-table-avatar">
            {hasAvatar ? (
              <img
                alt=""
                src={avatars[patientid]}
                onError={(e) => setHasAvatar(false)}
              />
            ) : (
              <img style={{ display: "none" }} alt="" />
            )}
          </div>
          <p className="patient-list-patient-name">{value}</p>
        </span>
      );
    },
  },
  {
    Header: "Created Date",
    accessor: (row) => moment(row.created).toDate(),
    width: "20%",
    id: "created",
    maxWidth: "200px",
    sortType: "datetime",
    sortDescFirst: true,
    Cell: ({ value }) => <span>{moment(value).format("MMMM Do YYYY")}</span>,
  },
  {
    Header: "Products",
    id: "treatment",
    accessor: (row) => `${row.manufacturing} ${row.alignment}`,
    width: "25%",
    maxWidth: "400px",
    Cell: ({ row }) => {
      row = row.original;
      let manufacturing = row.manufacturing;
      let alignment = row.alignment;
      if (!manufacturing) manufacturing = "In Office";
      if (!alignment) alignment = "In Office";
      let custom_treatment = row.custom_treatment;
      return (
        <div className="patient-table-treatment-tag-container">
          {alignment === "Technician" ? (
            <ArchFormTag
              color="#46454D"
              key={alignment + "_alignment"}
              fontSize="12px"
            >
              {alignment + " Setup"}
            </ArchFormTag>
          ) : (
            <ArchFormTag
              color="#46454D"
              key={alignment + "_alignment"}
              fontSize="12px"
            >
              {alignment + " Alignment"}
            </ArchFormTag>
          )}
          <ArchFormTag
            color="#46454D"
            key={manufacturing + "_manufacturing"}
            fontSize="12px"
          >
            {manufacturing + " Manufacturing"}
          </ArchFormTag>
          {custom_treatment && custom_treatment.length
            ? custom_treatment.map((tag) => (
                <ArchFormTag
                  color="#46454D"
                  key={"custom_treatment_" + tag}
                  fontSize="12px"
                >
                  {tag}
                </ArchFormTag>
              ))
            : null}
        </div>
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    width: "20%",
    maxWidth: "150px",
    Cell: ({ value }) => {
      if (!value) value = "Unprocessed";
      // let tag_color = { Shipping: 'blue', Review: 'red' }[value];
      // if (!tag_color) tag_color = 'purple';
      return (
        <ArchFormTag
          color={status_color_map[value]}
          style={{ fontSize: "16px" }}
        >
          {value}
        </ArchFormTag>
      );
    },
  },
  {
    Header: "Days in Status",
    accessor: (row) => {
      const status_timeline = row.status_timeline;
      if (!status_timeline) return 9999999; // Hack to put patients with no value on bottom
      let status_date = status_timeline[status_timeline.length - 1][1];
      if (!status_date)
        status_date = status_timeline[status_timeline.length - 1][0][1];
      const start_date = moment(status_date);
      const days_in_status = moment().diff(start_date, "days");
      return days_in_status;
    },
    width: "10%",
    Cell: ({ value }) => {
      if (value === 9999999) return "";
      return (
        <span className="days-in-status">
          {value}
          {/* {value > 5 ?
                        <Tag color={'red'} style={{ fontSize: '16px' }}>{value}</Tag>
                        :
                        <Tag color={'green'} style={{ fontSize: '16px' }}>{value}</Tag>} */}
        </span>
      );
    },
  },
  {
    id: "actions",
    // width: '50%',
    // width: '5%',
    Cell: ({ row }) => (
      <button
        onClick={async (e) => {
          e.stopPropagation();
          if (row.original.status === "Draft") {
            await deletePatient(row.original.patientid);
            window.location.reload();
          } else {
            openInArchform(row.original);
          }
        }}
        className="comment-button patient-table-comment-button"
      >
        <ArrowUpRight size={24} color="#46454D" />
        {row.original.status === "Draft" ? (
          <p>Delete</p>
        ) : (
          <p>Open in ArchForm</p>
        )}
      </button>
    ),
  },
];
class PatientTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { avatars, patients, search, status, onSelectRow } = this.props;
    let filtered_patients = patients.filter((patient) => {
      if (search) {
        if (
          `${patient.firstname}${patient.lastname}`
            .toLowerCase()
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (`${patient.id}`.toLowerCase().includes(search.replace(/ /g, "")))
          return true;
        if (
          `${patient.lastname}${patient.firstname}`
            .toLowerCase()
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (
          moment(patient.created)
            .format("MMMM Do YYYY")
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (
          patient.manufacturing &&
          patient.manufacturing
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (
          patient.alignment &&
          patient.alignment
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (
          patient.dentist_email &&
          patient.dentist_email
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (patient.custom_treatment && patient.custom_treatment.length) {
          if (
            patient.custom_treatment.some((tag) =>
              tag
                .replace(/ /g, "")
                .toLowerCase()
                .includes(search.replace(/ /g, ""))
            )
          )
            return true;
        }
        return false;
      }
      if (status === "All Patients") return true;
      if (status === "Unprocessed" && !patient.status) return true;
      return patient.status === status;
    });

    return (
      <div className="patient-list-table-container">
        <RoutedTable
          className="patient-table"
          avatars={avatars}
          columns={columns(avatars)}
          data={filtered_patients}
          onSelectRow={onSelectRow}
        />
      </div>
    );
  }
}

export default PatientTable;
