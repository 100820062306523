import React, { useState } from "react";
import { Button, Modal, Checkbox, Radio, Input, message } from "antd";
import "./RetainerOrder.css";
import AddressView from "./AddressView";
import { FileUpload } from "./CreatePatient";
import stl_upper_fill from "./media/patient/stl_upper_fill.svg";
import stl_upper_outline from "./media/patient/stl_upper_outline.svg";
import stl_lower_fill from "./media/patient/stl_lower_fill.svg";
import stl_lower_outline from "./media/patient/stl_lower_outline.svg";
const { TextArea } = Input;
const RetainerOrder = ({
  retainerOrderModalVisible,
  setRetainerOrderModalVisible,
  handleNewRetainerOrder,
}) => {
  // const [showRO, setShowRO] = useState(retainerOrderModalVisible)
  const [arches_to_fab, set_arches_to_fab] = useState();
  const [trim_style, set_trim_style] = useState();
  const [retainer_manufacturing, set_retainer_manufacturing] =
    useState("Last Stage of Setup");
  const [stl_lower, set_stl_lower] = useState();
  const [stl_upper, set_stl_upper] = useState();
  const Options1 = ["Upper", "Lower"];
  const Options2 = ["Scalloped", "Smooth"];
  const Options3 = ["Last Stage of Setup", "Upload Scans"];
  const [comment, set_comment] = useState("");

  function OpenRO() {
    setRetainerOrderModalVisible(true);
  }

  async function ROOK() {
    "";
    if (!arches_to_fab || !trim_style) {
      message.error("Please fill out all fields");
      return;
    }
    setRetainerOrderModalVisible(false);
    await handleNewRetainerOrder({
      arches_to_fab,
      trim_style,
      retainer_manufacturing,
      stl_lower,
      stl_upper,
      comment,
    });
  }

  function ROCancel() {
    setRetainerOrderModalVisible(false);
  }

  function onChange(what) {
    console.log(what);
  }
  return (
    <div className="RO_root">
      // <Button onClick={OpenRO}>files_retainer_order</Button>
      <Modal
        className="RO_modal"
        open={retainerOrderModalVisible}
        visible={retainerOrderModalVisible}
        // closable={true}
        onOk={ROOK}
        onCancel={ROCancel}
        footer={null}
        closable={false}
        bodyStyle={{
          // height: 750,
          // borderRadius:'20px',
          padding: 32,
        }}
        width={480}
        style={
          {
            // borderRadius:'100%'
          }
        }
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        }}
      >
        <div className="RO_check">
          <p className="RO_headline">Retainer Order</p>
          <p className="RO_p1"> Arches to Fabricate</p>
          {/* <p className="RO_p2"> $35 per arch</p> */}
          <Checkbox.Group
            options={Options1}
            value={arches_to_fab}
            onChange={set_arches_to_fab}
          />
          <p className="RO_p1"> Trim Style</p>
          <Radio.Group
            options={Options2}
            value={trim_style}
            onChange={(e) => set_trim_style(e.target.value)}
          />
          <p className="RO_p1">Manufacturing</p>
          <Radio.Group
            options={Options3}
            value={retainer_manufacturing}
            onChange={(e) => {
              if (e.target.value == "Last Stage of Setup") {
                set_stl_lower();
                set_stl_upper();
              }
              set_retainer_manufacturing(e.target.value);
            }}
          />
        </div>
        <div className="RO_AS">
          {retainer_manufacturing == "Upload Scans" ? (
            <>
              <FileUpload
                setFile={(file) => set_stl_upper(file)}
                label="STL Upper"
                preview={stl_upper_outline}
                uploadedPreview={stl_upper_fill}
                accept=".stl"
              />
              <FileUpload
                setFile={(file) => set_stl_lower(file)}
                label="STL Lower"
                preview={stl_lower_outline}
                uploadedPreview={stl_lower_fill}
                accept=".stl"
              />
            </>
          ) : null}
        </div>
        <p className="RO_p1">Add comments</p>
        <div className="RO_textArea">
          <TextArea
            value={comment}
            onChange={(e) => set_comment(e.target.value)}
            placeholder="Type your comment"
            rows={4}
            overflow="auto"
            style={{
              resize: "none",
            }}
          ></TextArea>
        </div>
        <AddressView />
        <div className="RO_button">
          <Button onClick={ROCancel}>Cancel</Button>
          <Button
            style={{
              color: "#0076FF",
              borderColor: "#0076FF",
            }}
            onClick={ROOK}
          >
            Order
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RetainerOrder;
