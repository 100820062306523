import {Amplify} from "aws-amplify";
import * as THREE from "three";
import $ from "jquery";
import AWS from "aws-sdk";
import _Buffer from "buffer";

const Buffer = _Buffer.Buffer;
AWS.config.region = "us-east-2";

const s3 = new AWS.S3();
const _AWS_BUCKET = "archform-patient-data-v1";
const _AWS_PUBLIC_BUCKET = "archform-webviewer-data-v1";

const _AWS_STORAGE_OPTIONS = {
  level: "protected",
  bucket: _AWS_BUCKET,
};

Amplify.Storage.configure({
  AWSS3: {
    bucket: _AWS_BUCKET,
    region: "us-east-2",
  },
});

async function makeUnauthenticatedRequest(op, params) {
  return new Promise((res, rej) => {
    s3.makeUnauthenticatedRequest(op, params, (err, data) => {
      if (err) {
        rej(err);
      } else {
        res(data);
      }
    });
  });
}
export class FileReader {
  constructor(props) {
    this.userid = props.userid;
    this.patientid = props.patientid;
    this.path = props.path || "";
    this.settings = props;
    this.folderList = [];
    this.stepFolderList = [];
    this.inputJSON = [];
    this.steps = 0;
    this.hasManifest = true;
    this.manifest = false;
    this.lowFiles = [];
    this.upperFiles = [];

    if (!props.userid && !props.patientid) {
      _AWS_STORAGE_OPTIONS.bucket = _AWS_PUBLIC_BUCKET;
    }

    if (props.loadPatientFiles) {
      this.readTeethFiles(this.path).then(() => {
        this.createJson();
      });
    }
  }

  async list(folder = "", delimiter = false, ContinuationToken) {
    let params;
    if (this.userid && this.patientid) {
      let root_folder = folder.split("/")[0];
      let manifest;
      try {
        if (this.hasManifest && !this.manifest) {
          this.manifest = await (
            await this.get("", `${root_folder}/manifest.json`, false)
          ).Body.arrayBuffer();
          this.manifest = JSON.parse(
            Buffer.from(this.manifest).toString("utf-8")
          );
        }
      } catch (err) {
        this.manifest = false;
        this.hasManifest = false;
      }
      if (this.manifest && folder.length) {
        // let root_folder = folder.split('/')[0]
        // const buffer = await (await this.get('', `${root_folder}/manifest.json`, false)).Body.arrayBuffer()
        // const files =  JSON.parse(Buffer.from(this.manifest).toString('utf-8'))
        const files = this.manifest.map(
          (ele) => `${this.patientid}/web_viewer/${root_folder}/${ele}`
        );
        if (folder.includes("/")) {
          return files.filter((ele) => ele.includes(folder));
        } else {
          return files;
        }
      } else {
        const creds = await Amplify.Auth.currentCredentials();
        s3.config.credentials = creds;
        params = {
          Bucket: _AWS_BUCKET,
          Prefix: `protected/${this.userid}/${this.patientid}/web_viewer/${folder}`,
          ContinuationToken: ContinuationToken,
        };

        if (delimiter) params.Delimiter = "/";
        const res = await s3.listObjectsV2(params).promise();
        let files = res.Contents;
        files = files.map((file) => file.Key.split(`${this.userid}/`)[1]);
        if (res.IsTruncated) {
          files = files.concat(
            await this.list(folder, delimiter, res.NextContinuationToken)
          );
        }
        return files;
      }
    } else {
      const root_folder = folder.split("/")[0];
      params = {
        Bucket: _AWS_PUBLIC_BUCKET,
        Key: `${root_folder}/manifest.json`,
      };
      const res = await makeUnauthenticatedRequest("getObject", params);
      const files = JSON.parse(Buffer.from(res.Body).toString("utf-8")).map(
        (ele) => `${root_folder}/${ele}`
      );
      if (folder.includes("/")) {
        return files.filter((ele) => ele.includes(folder));
      } else {
        return files;
      }
    }
  }

  async get(key = "", filename = "", parse = true, download = true) {
    if (this.userid && this.patientid) {
      let path = "";
      if (key) {
        path = key;
      } else {
        path = `${this.patientid}/web_viewer/${filename}`;
      }
      const res = await Amplify.Storage.get(path, {
        ..._AWS_STORAGE_OPTIONS,
        identityId: this.userid,
        download,
      });
      if (parse) return new TextDecoder("utf-8").decode(res.Body);
      else return res;
    } else {
      if (!download) {
        return `https://${_AWS_PUBLIC_BUCKET}.s3.us-east-2.amazonaws.com/${key}`;
      }
      const params = {
        Bucket: _AWS_PUBLIC_BUCKET,
        Key: key,
      };
      const res = await makeUnauthenticatedRequest("getObject", params);
      if (parse) return new TextDecoder("utf-8").decode(res.Body);
      else return res;
    }
  }

  run() {}

  init() {}

  async getFolders() {
    if (!this.userid && !this.patientid) {
      this.folderList = [];
      return;
    }
    const creds = await Amplify.Auth.currentCredentials();
    s3.config.credentials = creds;
    const params = {
      Bucket: _AWS_BUCKET,
      Prefix: `protected/${this.userid}/${this.patientid}/web_viewer/`,
      Delimiter: "/",
    };
    const res = await s3.listObjectsV2(params).promise();
    const folders = res.CommonPrefixes.map(
      (prefix) => prefix.Prefix.split("/")[4]
    );
    folders.sort((a, b) => {
      if (a.split("_").length !== 3 || b.split("_").length !== 3) return 0;
      a = parseInt(a.split("_")[0]);
      b = parseInt(b.split("_")[0]);
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    this.folderList = Array.from(folders);
  }

  async getStepFolders(folder) {
    const folders = new Set();
    let files_info = await this.list(folder);
    for (const file_info of files_info) {
      const idx = this.userid && this.patientid ? 3 : 1;
      if (!file_info.split("/")[idx].includes("_STAGE_")) continue;
      folders.add(file_info.split("/")[idx]);
      // if (file_info.Key.split('/')[3].includes('.')) continue
      // folders.add(file_info.Key.split('/')[3])
    }
    // files_info = files_info.filter(file_info => !file_info.size)
    // this.stepFolderList = files_info.map(file_info => file_info.Key)
    this.stepFolderList = Array.from(folders);
  }

  async getFileNames(folder) {
    let files_info = await this.list(folder, true);
    // files_info = files_info.filter(file_info => file_info.Key.includes('.'))
    // return files_info.map(file_info => file_info.Key)
    files_info = files_info.filter((file_info) => file_info.includes("."));
    return files_info.map((file_info) => file_info);
  }

  getTotalSteps() {}

  traverseFolder(folderPath) {}

  async readTeethFiles(folder = "") {
    const files = await this.list(folder);

    let upperTeethNum = 0;
    let lowTeethNum = 0;
    for (const file of files) {
      // const key = file.Key;
      const key = file;
      if (key.includes("GUM.drc")) continue;
      const sig_file = await this.get(key, "", false, false);
      if (key.includes(".drc") && key.includes("LOWER__TOOTH")) {
        this.lowFiles.push(sig_file);
      } else if (key.includes(".drc") && key.includes("UPPER__TOOTH")) {
        this.upperFiles.push(sig_file);
      }
    }
  }

  createJson = () => {
    this.getStepFolders(this.path).then(() => {
      this.createInputJSON(this.stepFolderList, this.path);
      var timer = setInterval(() => {
        if (this.jsonCompleted(this.inputJSON, this.stepFolderList.length)) {
          clearInterval(timer);

          this.settings.onSetTotalStep(this.inputJSON.length);
          this.settings.finish();
        }
      }, 1000);
    });
  };

  jsonCompleted = (jsonData, length) => {
    if (jsonData.length < length) return false;
    var flag = true;
    $.each(jsonData, (index, item) => {
      if (
        item.LowerGumMatrix === undefined ||
        item.LowerGumName === undefined ||
        item.LowerIPR === undefined ||
        item.LowerTeethMatrices === undefined ||
        item.UpperGumMatrix === undefined ||
        item.UpperGumName === undefined ||
        item.UpperIPR === undefined ||
        item.UpperTeethMatrices === undefined
      ) {
        flag = false;
      }
      if (
        !item.LowerTeethMatrices ||
        item.LowerTeethMatrices.length < this.lowFiles.length ||
        !item.UpperTeethMatrices ||
        item.UpperTeethMatrices.length < this.upperFiles.length
      ) {
        flag = false;
      }
    });
    return flag;
  };

  createInputJSON(folderList, basicFolderPath) {
    var _this = this;
    $.each(folderList, function (i, v) {
      var step = v.match(/\d+/g);
      var stepJson = {};
      if (step && step.length > 0 && v.includes("STAGE")) {
        stepJson.step = parseInt(step.slice(-1)[0]);
        stepJson.path = basicFolderPath + "/" + v + "/";
        stepJson.LowerTeethMatrices = Array(_this.lowFiles.length).fill(null);
        stepJson.UpperTeethMatrices = Array(_this.upperFiles.length).fill(null);
        _this.getFileNames(basicFolderPath + "/" + v + "/").then((data) => {
          $.each(data, async function (i, path) {
            // var path = _this.dataUrl + basicFolderPath + "/" + v + "/" + path;
            if (path.includes("LOWER__GUM.drc")) {
              stepJson.LowerGumName = await _this.get(path, "", false, false);
            } else if (path.includes("UPPER__GUM.drc")) {
              stepJson.UpperGumName = await _this.get(path, "", false, false);
            } else if (path.includes("PONTIC")) {
              if (path.includes("LOWER")) {
                stepJson.LowerPonticName = await _this.get(
                  path,
                  "",
                  false,
                  false
                );
              } else if (path.includes("UPPER")) {
                stepJson.UpperPonticName = await _this.get(
                  path,
                  "",
                  false,
                  false
                );
              }
            } else if (path.includes("LOWER__GUM.raw")) {
              stepJson.LowerGumMatrix = await _this.createGumMatrixFromBin(
                path
              );
            } else if (path.includes("UPPER__GUM.raw")) {
              stepJson.UpperGumMatrix = await _this.createGumMatrixFromBin(
                path
              );
            } else if (path.includes("LOWER__TOOTH.raw")) {
              stepJson.LowerTeethMatrices =
                await _this.createTeethMatricesFromBin(path);
            } else if (path.includes("UPPER__TOOTH.raw")) {
              stepJson.UpperTeethMatrices =
                await _this.createTeethMatricesFromBin(path);
            } else if (path.includes("LOWER__IPR.raw")) {
              stepJson.LowerIPR = await _this.createIPRDataFromBin(path);
            } else if (path.includes("UPPER__IPR.raw")) {
              stepJson.UpperIPR = await _this.createIPRDataFromBin(path);
            } else if (path.includes("LOWER__VISIBILITY.raw")) {
              stepJson.LowerVisibility =
                await _this.createVisibilityDataFromBin(path);
            } else if (path.includes("UPPER__VISIBILITY.raw")) {
              stepJson.UpperVisibility =
                await _this.createVisibilityDataFromBin(path);
            }
          });
          _this.inputJSON.push(stepJson);
        });
      }
    });
  }

  async createMatrixFromCsv(csv) {
    let matrix = await this.get(csv);
    matrix = this.processCsvData(matrix);
    return matrix;
  }

  async createGumMatrixFromBin(bin_url) {
    let bin = await this.get(bin_url, "", false);
    if (this.patientid && this.userid) {
      bin = await bin.Body.arrayBuffer();
      bin = Buffer.from(bin);
    } else {
      bin = Buffer.from(bin.Body);
    }

    const mat = new THREE.Matrix4();
    mat.elements.splice(0);
    for (let mm = 0; mm < 16; mm++) {
      mat.elements[mm] = bin.readFloatLE(mm * 4);
    }
    mat.transpose();
    return mat;
  }

  async createTeethMatricesFromBin(bin_url) {
    let bin = await this.get(bin_url, "", false);
    if (this.patientid && this.userid) {
      bin = await bin.Body.arrayBuffer();
      bin = Buffer.from(bin);
    } else {
      bin = Buffer.from(bin.Body);
    }

    const mats = [];
    for (let ii = 0; ii < bin.readInt32LE(0); ii++) {
      var mat = new THREE.Matrix4();
      const elems = new Array(16);
      for (let mm = 0; mm < 16; mm++) {
        elems[mm] = parseFloat(bin.readFloatLE(4 + mm * 4 + ii * 16 * 4));
      }
      mat.fromArray(elems);

      // mat.set(elems)
      mat.transpose();
      mats.push(mat);
    }

    return mats;
  }

  async createIPRDataFromBin(bin_url) {
    let bin = await this.get(bin_url, "", false);
    if (this.patientid && this.userid) {
      bin = await bin.Body.arrayBuffer();
      bin = Buffer.from(bin);
    } else {
      bin = Buffer.from(bin.Body);
    }
    const ipr_data = [];
    let byte_offset = 0;

    for (let ii = 0; ii < bin.readInt32LE(0); ii++) {
      const ipr = {};
      ipr.tooth1 = bin.readInt32LE(byte_offset + 4);
      ipr.tooth2 = bin.readInt32LE(byte_offset + 8);
      ipr.IPRValue = bin.readDoubleLE(byte_offset + 12);

      // TODO: convert to threejs vertex
      ipr.centerPosition = new THREE.Vector3(
        bin.readDoubleLE(byte_offset + 20),
        bin.readDoubleLE(byte_offset + 28),
        bin.readDoubleLE(byte_offset + 36)
      );

      const num_points = bin.readInt32LE(byte_offset + 44);
      ipr.vertices = new Array(num_points);
      for (let pp = 0; pp < bin.readInt32LE(byte_offset + 44); pp++) {
        ipr.vertices[pp * 3] = bin.readDoubleLE(byte_offset + 48 + pp * 24);
        ipr.vertices[pp * 3 + 1] = bin.readDoubleLE(byte_offset + 56 + pp * 24);
        ipr.vertices[pp * 3 + 2] = bin.readDoubleLE(byte_offset + 64 + pp * 24);
      }
      ipr_data.push(ipr);
      byte_offset += 68 + (num_points - 1) * 24;
    }

    return ipr_data;
  }

  async createVisibilityDataFromBin(bin_url) {
    let bin = await this.get(bin_url, "", false);
    if (this.patientid && this.userid) {
      bin = await bin.Body.arrayBuffer();
      bin = Buffer.from(bin);
    } else {
      bin = Buffer.from(bin.Body);
    }
    const visibility_data = [];

    for (let ii = 1; ii < bin.readInt32LE(0); ii++)
      visibility_data.push(bin.readInt32LE(ii * 4));

    return visibility_data;
  }

  processCsvData(allText) {
    var allTextLines = allText.split(/\r\n|\n/);
    var mat = new THREE.Matrix4();
    mat.elements.splice(0);
    for (var i = 0; i < 4; i++) {
      var segments = allTextLines[i].split(",");
      segments.forEach((v) => {
        mat.elements.push(parseFloat(v));
      });
    }
    mat.transpose();
    return mat;
  }

  async readIPR(csv) {
    var matrix = await this.get(csv);
    matrix = await this.processIPRData(matrix);
    return matrix;
  }

  processIPRData(allText) {
    var _this = this;
    var IPRData = [];
    var allTextLines = allText.split(/\r\n|\n/);
    $.each(allTextLines, function (index, item) {
      var segmentData = item.split(",");
      if (_this.checkValide_IPRData(segmentData)) {
        var vertices = [];
        for (var i = 7; i < segmentData.length; i++) {
          vertices.push(segmentData[i]);
        }
        var pos = new THREE.Vector3(
          segmentData[3],
          segmentData[4],
          segmentData[5]
        );
        var proxValue = "" + segmentData[2];
        IPRData.push({
          tooth1: segmentData[0],
          tooth2: segmentData[1],
          IPRValue: proxValue,
          centerPosition: pos,
          vertices: vertices,
        });
      }
    });

    return IPRData;
  }

  checkValide_IPRData(data) {
    if (data.length === parseInt(data[6]) * 3 + 7) return true;
    else {
      console.log("No IPR Data or Invalid IPR Data");
      return false;
    }
  }
}

export default FileReader;
