import React from "react";
import { Spin, Alert, message } from "antd";
import {Amplify} from "aws-amplify";
import { getUser } from "./User";
import { Link, withRouter } from "react-router-dom";
import "./LoginPage.css";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
    };
    if (this.props.location.search) {
      let query = this.props.location.search.replace("?", "").split("&");
      for (var param of query) {
        param = param.split("=");
        this.state[param[0]] = param[1];
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (this.state.code && this.state.username) {
      try {
        await Amplify.Auth.confirmSignUp(this.state.username, this.state.code);
        message.info(
          "You have successfully actived your account.  Please login!"
        );
      } catch (err) {
        console.log(err);
        message.error(
          "There was an error activating your account, please contact support"
        );
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.loading) return;
    this.setState({ loading: true }, async () => {
      try {
        await Amplify.Auth.signIn(
          this.state.email.toLowerCase(),
          this.state.password
        );
      } catch (err) {
        try {
          await Amplify.Auth.signIn(this.state.email, this.state.password);
        } catch (err) {
          Amplify.Cache.setItem("isAuthenticated", false);
          this.setState({ loading: false, error: true });
          return;
        }
      }
      Amplify.Cache.setItem("isAuthenticated", true);
      await getUser();
      if (
        this.props.history.location.state &&
        this.props.history.location.state.from
      ) {
        this.props.history.push(
          this.props.history.location.state.from.pathname
        );
      } else {
        this.props.history.push("/");
      }
    });
  };

  render() {
    return (
      <div className="login-body">
        <Spin tip="Logging in" spinning={this.state.loading}>
          <div className="login-container">
            <p className="login-title">Welcome back to ArchForm</p>
            <form className="login-form-container">
              <input
                className="form-field"
                placeholder="Email address"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <input
                type="password"
                className="form-field"
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
              <button className="login-submit-button" onClick={this.handleSubmit}>
                Login
              </button>
            </form>
            <div className="login-links-group">
              <a className="login-link" href="/resetpassword">
                Reset password
              </a>
              <a className="login-link" href="/signup">
                Create account
              </a>
            </div>
            {this.state.error ? (
              <Alert message="Login failed, please try again." type="error" showIcon />
            ) : null}
          </div>
        </Spin>
      </div>
    );
  }
}

export default withRouter(LoginPage);
