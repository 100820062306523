import React from "react";
import {
  Popconfirm,
  Popover,
  Upload,
  Avatar,
  Layout,
  Icon,
  Row,
  Col,
  Divider,
  Modal,
  Carousel,
  Tag,
  Tabs,
  Button,
  Input,
  Spin,
  Empty,
  List,
  DatePicker,
  Comment,
  Switch,
  Dropdown,
  Menu,
  message,
} from "antd";
import {Amplify} from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import {
  getPatientChat,
  updatePatientChat,
  getAligners,
  updatePatient,
  getPatients,
  deletePatient,
} from "./Patients";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { ClickAwayListener } from "@material-ui/core";
import { Radio } from "antd";
import Header from "./Header";
import { tag_color, stringToColour } from "./PatientTable";
import { getUser } from "./User";
import { hashCode } from "./Patients";
import stl_upper_bw from "./composite2/STL_UpperVector01.png";
import stl_lower_bw from "./composite2/STL_LowerVector01.png";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Content } = Layout;
const { TextArea } = Input;

export function convertUNS(num, system) {
  if (system === "Palmer") {
    if (num < 9) return 9 - num;
    if (num < 17) return num - 8;
    if (num < 25) return 25 - num;
    return num - 24;
  } else if (system === "ISO") {
    if (num < 9) return 19 - num;
    if (num < 17) return num + 12;
    if (num < 25) return 55 - num;
    return num + 16;
  } else if (system === "UNS") {
    return num;
  }
  console.error("Cannot convert UNS to " + system);
}

const EditPatientFieldModal = (patient, key, onOk) => (
  <Modal
    title="Edit Field"
    visible={true}
    closable={true}
    onOk={this.handleFieldChange}
    onCancel={() =>
      this.setState({ editPatientFieldModalVisible: false, chatMsgText: "" })
    }
  >
    <TextArea
      autosize={{ minRows: 8 }}
      value={this.state.chatMsgText}
      onChange={(evt) => this.setState({ chatMsgText: evt.target.value })}
    />
  </Modal>
);

export const TeethTable = ({ table, system }) => (
  <Row className="teeth-checkbox">
    <Col span={24} style={{ display: "flex" }}>
      <div>
        <div>
          {[...Array(9).keys()].slice(1).map((val) => {
            return table.includes(val.toString()) ? (
              <div className="teeth-checkbox-item teeth-checkbox-selected">
                {convertUNS(val, system)}
              </div>
            ) : (
              <div className="teeth-checkbox-item">
                {convertUNS(val, system)}
              </div>
            );
            // <Select size="small"> {val}</Select>:
            // <Select size="small"> {val}</Select>
          })}
        </div>
        <Divider />
        <div>
          {[...Array(33).keys()]
            .slice(25)
            .reverse()
            .map((val) => {
              return table.includes(val.toString()) ? (
                <div className="teeth-checkbox-item teeth-checkbox-selected">
                  {convertUNS(val, system)}
                </div>
              ) : (
                <div className="teeth-checkbox-item">
                  {convertUNS(val, system)}
                </div>
              );
            })}
        </div>
      </div>
      <div>
        <Divider style={{ height: "100%" }} type="vertical" />
      </div>
      <div>
        <div>
          {[...Array(17).keys()].slice(9).map((val) => {
            return table.includes(val.toString()) ? (
              <div className="teeth-checkbox-item teeth-checkbox-selected">
                {convertUNS(val, system)}
              </div>
            ) : (
              <div className="teeth-checkbox-item">
                {convertUNS(val, system)}
              </div>
            );
          })}
        </div>
        <Divider />
        <div>
          {[...Array(25).keys()]
            .slice(17)
            .reverse()
            .map((val) => {
              return table.includes(val.toString()) ? (
                <div className="teeth-checkbox-item teeth-checkbox-selected">
                  {convertUNS(val, system)}
                </div>
              ) : (
                <div className="teeth-checkbox-item">
                  {convertUNS(val, system)}
                </div>
              );
            })}
        </div>
      </div>
    </Col>
  </Row>
);

class PatientView extends React.Component {
  constructor(props) {
    super(props);
    let patient = {};
    if (Amplify.Cache.getItem("patientdata")) {
      patient = {
        ...Amplify.Cache.getItem("patientdata", getPatients).find((patient) => {
          const count = this.props.match.params.num.match(/-/g);
          if (count && count.length === 4)
            return patient.patientid === this.props.match.params.num;
          else return hashCode(patient) === this.props.match.params.num;
        }),
      };
      patient.clinical_conditions = patient.clinical_conditions
        ? patient.clinical_conditions
        : [];
    }
    this.state = {
      modalImg: null,
      imgModalVisible: false,
      chatModalVisible: false,
      chatMsgTitle: "Edit Note",
      patient: patient,
      chatMsgText: "",
      chatMsgFiles: [],
      chatLoaded: false,
      chatMessages: [],
      files: [],
      filesLoading: false,
      filesUploading: false,
      editing_preferences: false,
      user: null,
      loadedChatMsgFiles: {},
      chatImgModalVisible: false,
      newRefinementModalVisible: false,
      newUpperRefinementFile: [],
      newLowerRefinementFile: [],
    };
    if (patient.patientid) this.state.patientid = patient.patientid;

    getUser().then((user) => this.setState({ user: user }));
    this.carousel = React.createRef();
    this.handleNewChatMsg = this.handleNewChatMsg.bind(this);
    this.handleChatMsgFiles = this.handleChatMsgFiles.bind(this);
    this.handlePatientFieldChange = this.handlePatientFieldChange.bind(this);
    this.handlePatientStatusChange = this.handlePatientStatusChange.bind(this);
    this.handlePatientNextStatusChange =
      this.handlePatientNextStatusChange.bind(this);
    this.handlePatientSetupNeedsChanges =
      this.handlePatientSetupNeedsChanges.bind(this);
    this.uploadArchformFile = this.uploadArchformFile.bind(this);
    this.handleOpenInArchform = this.handleOpenInArchform.bind(this);
    this.setXrayModalIdx = this.setXrayModalIdx.bind(this);
    this.handleDeletePatient = this.handleDeletePatient.bind(this);
    this.handleNewRefinement = this.handleNewRefinement.bind(this);
  }

  async componentDidMount() {
    // await getAligners(this.state.patient.patientid)
    let patient = null;
    if (!Object.keys(this.state.patient).length) {
      const patients = await getPatients();
      patient = {
        ...patients.find((patient) => {
          if (
            this.props.match.params.num.match(/-/g) &&
            this.props.match.params.num.match(/-/g).length === 4
          )
            return patient.patientid === this.props.match.params.num;
          else return hashCode(patient) === this.props.match.params.num;
        }),
      };
      patient.clinical_conditions = patient.clinical_conditions
        ? patient.clinical_conditions
        : [];
      this.setState({ patient: patient, patientid: patient.patientid });
    } else patient = this.state.patient;

    this.setState({
      chatMessages: (await getPatientChat(this.state.patientid)).reverse(),
      chatLoaded: true,
    });
    await this.loadFiles();
    await this.loadNoteImages();

    this.setState({
      unity_data: {
        UUID: patient.patientid,
        IdentityId: patient.userid,
      },
    });

    let patient_changed = false;
    if (!patient.status) {
      patient.status = "Unprocessed";
      patient_changed = true;
    }
    if (!patient.manufacturing) {
      patient.manufacturing = "In Office";
      patient_changed = true;
    }
    if (!patient.alignment) {
      patient.alignment = "In Office";
      patient_changed = true;
    }
    if (patient_changed) {
      this.setState({ patient: patient });
      await updatePatient(patient);
    }
  }

  setImgModal = (idx) => (evt) => {
    this.setState({ imgModalVisible: true, carouselIdx: idx });
    if (this.carousel.current) this.carousel.current.goTo(idx);
  };

  async loadNoteImages() {
    this.setState({ filesLoading: true });
    let files = {};
    const options = {
      level: "protected",
      identityId: this.state.patient.userid,
      bucket: "archform-patient-data-v1",
    };
    const filenames = await Amplify.Storage.list(
      `${this.state.patient.patientid}/notes`,
      options
    );

    for (const filename of filenames) {
      const f = await Amplify.Storage.get(filename.key, options);
      const file_date = filename.key.split("notes/")[1].split("/")[0];
      if (!files[file_date]) files[file_date] = [];
      files[file_date].push(f);
    }
    this.setState({ loadedChatMsgFiles: files, filesLoading: false });
  }

  async loadFiles() {
    this.setState({ filesLoading: true });
    let archform_files = [];
    for (const folder of [
      "composite",
      "xray",
      "models",
      "refinements",
      "archform-patients-v1",
    ]) {
      const options = {
        level: "protected",
        identityId: this.state.patient.userid,
        bucket: "archform-patient-data-v1",
      };
      if (folder === "archform-patients-v1") {
        options.customPrefix = { protected: "" };
        options.bucket = folder;
      }
      const filenames = await Amplify.Storage.list(
        folder === "archform-patients-v1"
          ? `${this.state.patient.patientid}/`
          : `${this.state.patient.patientid}/${folder}`,
        options
      );
      const files = [];
      for (const filename of filenames) {
        const f = await Amplify.Storage.get(filename.key, options);
        files.push(f);
      }
      if (files.length) {
        if (folder === "archform-patients-v1") files.reverse();
        this.setState({
          files: {
            ...this.state.files,
            [folder === "archform-patients-v1" ? "archform" : folder]: files,
          },
        });
        if (folder === "archform-patients-v1") archform_files = files;
      }
    }
    this.setState({ filesLoading: false });
  }

  async handleDeleteArchformFile(file, link, folder) {
    console.log(file);
    console.log(link);
    const options = {
      level: "protected",
      identityId: this.state.patient.userid,
      bucket: "archform-patients-v1",
      customPrefix: { protected: "" },
    };
    console.log(this.state.files);
    console.log(`${this.state.patient.patientid}/${file}`);

    const res = await Amplify.Storage.remove(
      decodeURI(`${this.state.patient.patientid}/${file}`),
      options
    );
    const files = this.state.files;
    files[folder] = files[folder].filter((ii) => ii !== link);
    this.setState({ files: files });
    console.log(res);
    console.log(files);
  }

  async uploadArchformFile(file) {
    this.setState({ filesUploading: true });
    const res = await Amplify.Storage.put(
      `${this.state.patient.patientid}/${file.file.name}`,
      file.file,
      {
        level: "protected",
        identityId: this.state.patient.userid,
        bucket: "archform-patients-v1",
        customPrefix: { protected: "" },
        contentType: file.file.type,
      }
    );
    this.setState({
      filesUploading: false,
      files: {
        ...this.state.files,
        archform: this.state.files.archform.concat(file.file.name),
      },
    });
  }

  async handleNewRefinement(evt) {
    const upper = this.state.newUpperRefinementFile;
    const lower = this.state.newLowerRefinementFile;
    if (!upper || !lower) return;
    const refinements = this.state.files.refinements || [];
    this.setState({ filesUploading: true, newRefinementModalVisible: false });
    const refinement_version = refinements.length / 2 + 1;

    const options = {
      level: "protected",
      identityId: this.state.patient.userid,
      bucket: "archform-patient-data-v1",
    };

    await Amplify.Storage.put(
      `${this.state.patient.patientid}/refinements/stl_upper_refinement_${refinement_version}.stl`,
      upper.originFileObj,
      options
    );
    await Amplify.Storage.put(
      `${this.state.patient.patientid}/refinements/stl_lower_refinement_${refinement_version}.stl`,
      lower.originFileObj,
      options
    );

    this.setState({
      filesUploading: false,
      files: {
        ...this.state.files,
        refinements: [
          ...refinements,
          URL.createObjectURL(upper.originFileObj),
          URL.createObjectURL(lower.originFileObj),
        ],
      },
    });
  }

  async handleNewChatMsg() {
    if (this.state.chatMsgText === "") return;
    const date = new Date().toISOString();
    const message = {
      userid: (await Amplify.Auth.currentCredentials()).data.IdentityId,
      date: date,
      username: Amplify.Cache.getItem("user").name,
      patientid: this.state.patient.patientid,
      message: this.state.chatMsgText,
      patient_userid: this.state.patient.userid,
    };
    this.setState({ filesUploading: true, chatModalVisible: false });
    await updatePatientChat(message);
    const newLoadedChatMsgFiles = { [message.date]: [] };
    for (const file of this.state.chatMsgFiles) {
      const res = await Amplify.Storage.put(
        `${this.state.patient.patientid}/notes/${date}/${file.name}`,
        file.originFileObj,
        {
          level: "protected",
          identityId: this.state.patient.userid,
          bucket: "archform-patient-data-v1",
          contentType: file.type,
        }
      );
      newLoadedChatMsgFiles[message.date].push(
        URL.createObjectURL(file.originFileObj)
      );
    }
    this.setState({
      chatMessages: [message, ...this.state.chatMessages],
      chatMsgText: "",
      chatMsgTitle: "Edit Note",
      filesUploading: false,
      chatMsgFiles: [],
      loadedChatMsgFiles: {
        ...this.state.loadedChatMsgFiles,
        ...newLoadedChatMsgFiles,
      },
    });
  }

  // Do not use until updatePatient stops using Put!
  handlePatientFieldChange = (key) => async (evt) => {
    // const value = evt.target.value;
    // if (this.state.patient[key] === value) return;
    // this.setState(({ patient }) => {
    //     patient[key] = value;
    //     return patient
    // });
    // const d = await updatePatient({
    //     userid: this.state.patient.userid,
    //     patientid: this.state.patient.patientid,
    //     [key]: value,
    // })
    // // if (this.state.editing_preferences) return;
    // console.log(d)
  };

  async handlePatientStatusChange(status) {
    if (
      this.state.patient.status === "Unprocessed" &&
      this.state.patient.alignment !== "In Office" &&
      this.state.user.attributes["custom:user_type"] !== "Technician"
    )
      return message.warn(
        "Only technicians can update the state of this patient"
      );
    this.setState({
      patient: { ...this.state.patient, status: status.key },
      patient_status: status.key,
    });
    await updatePatient({ ...this.state.patient, status: status.key });
  }

  async handlePatientNextStatusChange(evt) {
    if (
      this.state.patient.status === "Unprocessed" &&
      this.state.patient.alignment !== "In Office" &&
      this.state.user.attributes["custom:user_type"] !== "Technician"
    )
      return message.warn(
        "Only technicians can update the state of this patient"
      );
    let new_status = "";
    const status = this.state.patient.status;
    if (status === "Archived") return;
    if (status === "Unprocessed") new_status = "Planning";
    if (status === "Planning") new_status = "Needs Review";
    if (status === "Needs Review") new_status = "Accepted";
    if (status === "Accepted") new_status = "In Production";
    if (status === "In Production") new_status = "In Treatment";
    if (status === "In Treatment") new_status = "Archived";
    this.setState({
      patient: { ...this.state.patient, status: new_status },
      patient_status: new_status,
    });
    await updatePatient({ ...this.state.patient, status: new_status });
  }

  async handlePatientSetupNeedsChanges(evt) {
    this.setState({
      patient: { ...this.state.patient, status: "Planning" },
      patient_status: "Planning",
    });
    this.setState({
      chatModalVisible: true,
      chatMsgTitle: "What changes need to be made to the setup?",
    });
    await updatePatient({ ...this.state.patient, status: "Planning" });
  }

  handleOpenInArchform(filename = "", refinement = -1) {
    window.location =
      "archform:" +
      encodeURIComponent(
        JSON.stringify({
          ...this.state.unity_data,
          filename: filename,
          refinement: refinement,
        })
      );
  }

  async handleDeletePatient() {
    await deletePatient(this.state.patientid);
    this.props.history.push("/");
  }

  handleChatMsgFiles = ({ fileList }) => {
    this.setState({ chatMsgFiles: [...fileList] });
  };

  setXrayModalIdx = (idx) => (e) => {
    console.log(idx);
    if (this.state.files.composite && this.state.files.composite.length)
      idx += 8;
    this.setImgModal(idx)();
  };

  render() {
    const patient = this.state.patient;
    const files = this.state.files;
    const editing_preferences = this.state.editing_preferences;
    const handlePatientFieldChange = this.handlePatientFieldChange;
    const tooth_num_system = this.state.user
      ? this.state.user.attributes["custom:tooth_num_system"]
      : "UNS";
    return (
      <Layout
        className="layout"
        style={{ background: "#fff", wordBreak: "break-all" }}
      >
        <Header />
        {/* <Header className='header' style={{ background: '#fff', boxShadow: '0 1px 0 0 #e6e8eb', zIndex: 1 }}> */}
        {/* <Header className='header' style={{ background: '#fff' }}>
                    <Link to='/' className='logo'/>
                    <Link to='/' style={{ color: 'rgba(0, 0, 0, 0.65)' }}><Icon type="left" /> Back to Patients</Link>
                </Header> */}
        <Spin spinning={this.state.filesUploading}>
          <Content style={{ padding: "0 100px" }}>
            {/* <Content style={{ padding: '0' }}> */}
            <Row gutter={{ xs: 0, lg: 64 }}>
              <Col xs={24} lg={4} style={{ background: "#fff" }}>
                {files.composite ? (
                  files.composite.length === 1 ? (
                    <div style={{ width: "100%" }}>
                      <img
                        onClick={this.setImgModal(0)}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          borderRadius: "16px",
                          marginBottom: "20px",
                        }}
                        src={files.composite[0]}
                      />
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <img
                        onClick={this.setImgModal(1)}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          borderRadius: "16px",
                          marginBottom: "20px",
                        }}
                        src={files.composite[1]}
                      />
                      <img
                        onClick={this.setImgModal(0)}
                        style={{
                          cursor: "pointer",
                          width: "calc(100%/3)",
                          borderRadius: "16px",
                          paddingRight: "3px",
                        }}
                        src={files.composite[2]}
                      />
                      <img
                        onClick={this.setImgModal(1)}
                        style={{
                          cursor: "pointer",
                          width: "calc(100%/3)",
                          borderRadius: "16px",
                          paddingRight: "3px",
                        }}
                        src={files.composite[1]}
                      />
                      <img
                        onClick={this.setImgModal(2)}
                        style={{
                          cursor: "pointer",
                          width: "calc(100%/3)",
                          borderRadius: "16px",
                        }}
                        src={files.composite[5]}
                      />
                      <div style={{ marginTop: "3px" }} />
                      <img
                        onClick={this.setImgModal(3)}
                        style={{
                          cursor: "pointer",
                          width: "calc(100%/3)",
                          borderRadius: "16px",
                          paddingRight: "3px",
                        }}
                        src={files.composite[7]}
                      />
                      <div
                        style={{
                          width: "calc(100%/3)",
                          display: "inline-block",
                          paddingRight: "3px",
                        }}
                      />
                      <img
                        onClick={this.setImgModal(4)}
                        style={{
                          cursor: "pointer",
                          width: "calc(100%/3)",
                          borderRadius: "16px",
                        }}
                        src={files.composite[4]}
                      />
                      <div style={{ marginTop: "3px" }} />
                      <img
                        onClick={this.setImgModal(5)}
                        style={{
                          cursor: "pointer",
                          width: "calc(100%/3)",
                          borderRadius: "16px",
                          paddingRight: "3px",
                        }}
                        src={files.composite[6]}
                      />
                      <img
                        onClick={this.setImgModal(6)}
                        style={{
                          cursor: "pointer",
                          width: "calc(100%/3)",
                          borderRadius: "16px",
                          paddingRight: "3px",
                        }}
                        src={files.composite[0]}
                      />
                      <img
                        onClick={this.setImgModal(7)}
                        style={{
                          cursor: "pointer",
                          width: "calc(100%/3)",
                          borderRadius: "16px",
                        }}
                        src={files.composite[3]}
                      />
                    </div>
                  )
                ) : null}
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                {files.xray && files.xray.length && (
                  <img
                    onClick={this.setXrayModalIdx(0)}
                    style={{
                      cursor: "pointer",
                      width: "50%",
                      borderRadius: "16px",
                      paddingRight: "3px",
                    }}
                    src={files.xray[0]}
                  />
                )}
                {files.xray && files.xray.length > 1 && (
                  <img
                    onClick={this.setXrayModalIdx(1)}
                    style={{
                      cursor: "pointer",
                      width: "50%",
                      borderRadius: "16px",
                    }}
                    src={files.xray[1]}
                  />
                )}
              </Col>
              <Col xs={24} lg={15} style={{ background: "#fff" }}>
                <Row gutter={{ xs: 0, lg: 64 }}>
                  <Col
                    xs={24}
                    lg={15}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h1 style={{ margin: 0, marginRight: "0.75em" }}>
                      {patient.lastname ? patient.lastname : ""}
                      {patient.firstname && patient.lastname ? ", " : ""}
                      {patient.firstname ? patient.firstname : ""}
                    </h1>
                    <Tag color="blue">
                      <span>{patient.status}</span>
                    </Tag>
                  </Col>
                </Row>
                <Tabs style={{ margin: "20px 0 50px 0" }} defaultActiveKey="1">
                  <Tabs.TabPane tab="Treatment" key="1">
                    <Row>
                      <Col span={24} style={{ marginTop: "10px" }} />

                      <Col span={4}>
                        <p style={{ color: "#757A80" }}>Clinical Conditons</p>
                      </Col>
                      <Col span={20}>
                        {patient.clinical_conditions &&
                        patient.clinical_conditions.length ? (
                          <p style={{ color: "#121925" }}>
                            {patient.clinical_conditions
                              .map((condition) =>
                                condition
                                  .split("_")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")
                              )
                              .join(", ")}
                          </p>
                        ) : (
                          <p>
                            <Link to={`/edit-patient/${patient.patientid}#3`}>
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </p>
                        )}
                      </Col>
                      <Col span={24} />

                      <Col span={4}>
                        <p style={{ color: "#757A80" }}>Alignment</p>
                      </Col>
                      <Col span={20}>
                        {patient.alignment ? (
                          <Tag
                            color={tag_color[patient.alignment]}
                            key={patient.alignment}
                          >
                            {patient.alignment}
                          </Tag>
                        ) : (
                          <p>
                            <Link to={`/edit-patient/${patient.patientid}#1`}>
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </p>
                        )}
                      </Col>
                      <Col span={24} />

                      <Col span={4}>
                        <p style={{ color: "#757A80" }}>Manufacturing</p>
                      </Col>
                      <Col span={20}>
                        {patient.manufacturing ? (
                          <Tag
                            color={tag_color[patient.manufacturing]}
                            key={patient.manufacturing}
                          >
                            {patient.manufacturing}
                          </Tag>
                        ) : (
                          <p>
                            <Link to={`/edit-patient/${patient.patientid}#1`}>
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </p>
                        )}
                      </Col>
                      <Col span={24} />
                      <Col span={4}>
                        <p style={{ color: "#757A80" }}>Custom Treatment</p>
                      </Col>
                      <Col span={20}>
                        {patient.custom_treatment &&
                        patient.custom_treatment.length ? (
                          patient.custom_treatment.map((tag) => (
                            <Tag color={stringToColour(tag)} key={tag}>
                              {tag}
                            </Tag>
                          ))
                        ) : (
                          <p>
                            <Link to={`/edit-patient/${patient.patientid}#1`}>
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </p>
                        )}
                      </Col>
                      <Col span={24} />
                      {/* <Col span={4}><p style={{ color: '#757A80' }}>Treatment</p></Col>
                                        <Col span={20}>
                                            {patient.product || patient.treatment_plan ?
                                                <p style={{ color: '#121925' }}>{patient.product || patient.treatment_plan}</p>
                                                :
                                                <p><Link to={`/edit-patient/${patient.patientid}#1`}><Icon type='edit' style={{ color: '#757A80' }} /></Link></p>
                                            }
                                        </Col> */}
                      {/* <Col span={4}><p style={{ color: '#757A80' }}>Treatment Started</p></Col>
                                        <Col span={20}>
                                            {patient.treatment_started_date ?
                                                <p style={{ color: '#121925' }}>{patient.treatment_started_date}</p>
                                                :
                                                <p><DatePicker size='small' format={'MMMM Do YYYY'} /></p>
                                                // <p style={{ color: '#757A80' }}>No treatment plan started</p>
                                            }
                                        </Col>
                                        <Col span={4}><p style={{ color: '#757A80' }}>Progress</p></Col>
                                        {patient.treatment_progress ?
                                            <Col span={20}>
                                                <span style={{ color: '#121925' }}>Aligner 2 of 12</span>
                                                <p style={{ fontWeight: 500, color: '#0076FF', cursor: 'pointer', lineHeight: '20px', display: 'inline-block', marginLeft: '1em' }}>See Details</p>
                                            </Col>
                                            :
                                            <Col span={20}><p style={{ color: '#757A80' }}>No aligners found for patient</p></Col>
                                        }*/}

                      <Col span={4}>
                        <p style={{ color: "#757A80" }}>Notes</p>
                      </Col>
                      <Col span={20}>
                        {patient.notes ? (
                          <p style={{ color: "#121925" }}>{patient.notes}</p>
                        ) : (
                          <p>
                            <Link to={`/edit-patient/${patient.patientid}#0`}>
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </p>
                        )}
                      </Col>
                      <Col span={24} style={{ marginTop: "30px" }} />

                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        span={24}
                      >
                        <h2 style={{ color: "#121925" }}>Recents</h2>
                        <h3
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ chatModalVisible: true })
                          }
                        >
                          <Icon type="plus" /> New
                        </h3>
                      </Col>

                      <Col span={24}>
                        {/* <Scrollbars  autoHeight  > */}
                        <Row>
                          {this.state.chatMessages.length === 0 &&
                          this.state.chatLoaded ? (
                            <Empty description={<span>No Notes</span>} />
                          ) : (
                            <Spin spinning={!this.state.chatLoaded}>
                              {!this.state.chatLoaded && (
                                <div style={{ height: "200px" }} />
                              )}
                              {this.state.chatMessages.map((msg) => (
                                <Col span={24} key={msg.date}>
                                  <Comment
                                    author={<span>{msg.username}</span>}
                                    avatar={<Avatar icon="user" />}
                                    content={
                                      <div>
                                        <p style={{ wordBreak: "break-word" }}>
                                          {msg.message}
                                        </p>
                                        <div style={{ display: "flex" }}>
                                          {this.state.loadedChatMsgFiles &&
                                            this.state.loadedChatMsgFiles[
                                              msg.date
                                            ] &&
                                            this.state.loadedChatMsgFiles[
                                              msg.date
                                            ].map((img) => (
                                              <img
                                                key={img}
                                                className="chat-msg-image"
                                                onClick={(e) =>
                                                  this.setState({
                                                    chatImgModalVisible: true,
                                                    chatModalImg: img,
                                                  })
                                                }
                                                src={img}
                                              />
                                            ))}
                                        </div>
                                      </div>
                                    }
                                    datetime={
                                      <span>
                                        {moment(msg.date).format(
                                          "MMMM Do YYYY"
                                        )}
                                      </span>
                                    }
                                  />
                                </Col>
                              ))}
                            </Spin>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="About Patient" key="2">
                    <Row>
                      <Col span={24} style={{ marginTop: "10px" }} />
                      <Col span={12}>
                        <span
                          style={{
                            color: "#757A80",
                            display: "block",
                            lineHeight: "24px",
                            fontSize: "14px",
                          }}
                        >
                          Patient ID
                        </span>
                        {patient.id ? (
                          <span
                            style={{
                              color: "#121925",
                              lineHeight: "32px",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {patient.id}
                          </span>
                        ) : (
                          <span>
                            <Link to="/create-patient">
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </span>
                        )}
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            color: "#757A80",
                            display: "block",
                            lineHeight: "24px",
                            fontSize: "14px",
                          }}
                        >
                          Doctor
                        </span>
                        {patient.dentist_email ? (
                          <span
                            style={{
                              color: "#121925",
                              lineHeight: "32px",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {patient.dentist_email}
                          </span>
                        ) : (
                          <span>
                            <Link to="/create-patient">
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </span>
                        )}
                      </Col>

                      <Col span={24} style={{ marginTop: "30px" }} />
                      <Col span={12}>
                        <span
                          style={{
                            color: "#757A80",
                            display: "block",
                            lineHeight: "24px",
                            fontSize: "14px",
                          }}
                        >
                          Gender
                        </span>
                        {patient.gender ? (
                          <span
                            style={{
                              color: "#121925",
                              lineHeight: "32px",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {patient.gender}
                          </span>
                        ) : (
                          <span>
                            <Link to="/create-patient">
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </span>
                        )}
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            color: "#757A80",
                            display: "block",
                            lineHeight: "24px",
                            fontSize: "14px",
                          }}
                        >
                          Age
                        </span>
                        {patient.age ? (
                          <span
                            style={{
                              color: "#121925",
                              lineHeight: "32px",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {patient.age}
                          </span>
                        ) : (
                          <span>
                            <Link to="/create-patient">
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </span>
                        )}
                      </Col>

                      {/* <Col span={24} style={{ marginTop: '30px' }} />
                                        <Col span={12} >
                                            <span style={{ color: '#757A80', display: 'block', lineHeight: '24px', fontSize: '14px' }}>Start Date</span>
                                            <span style={{ color: '#121925', lineHeight: '32px', fontSize: '16px', fontWeight: 400 }}>1/3/19</span>
                                        </Col> */}

                      {/* <Col span={24} style={{ marginTop: '30px' }} />
                                        <Col span={12} >
                                            <span style={{ color: '#757A80', display: 'block', lineHeight: '24px', fontSize: '14px' }}>Address</span>
                                            <span style={{ color: '#121925', lineHeight: '32px', fontSize: '16px', fontWeight: 400, display: 'block' }}>123 Blueberry Lane</span>
                                            <span style={{ color: '#121925', lineHeight: '32px', fontSize: '16px', fontWeight: 400 }}>Los Angeles, CA 90210</span>
                                        </Col>
                                        <Col span={12} >
                                            <span style={{ color: '#757A80', display: 'block', lineHeight: '24px', fontSize: '14px' }}>Status</span>
                                            <span style={{ color: '#121925', lineHeight: '32px', fontSize: '16px', fontWeight: 400 }}><Tag color='blue'>{patient.status}</Tag></span>
                                        </Col> */}

                      <Col span={24} style={{ marginTop: "30px" }} />
                      <Col span={24}>
                        <span
                          style={{
                            color: "#757A80",
                            lineHeight: "32px",
                            fontSize: "10px",
                            fontWeight: 400,
                          }}
                        >
                          (Optional)
                        </span>
                      </Col>

                      <Col span={12}>
                        <span
                          style={{
                            color: "#757A80",
                            display: "block",
                            lineHeight: "24px",
                            fontSize: "14px",
                          }}
                        >
                          Appointment Date
                        </span>
                        {patient.appointment_date ? (
                          <span
                            style={{
                              color: "#121925",
                              lineHeight: "32px",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {patient.appointment_date}
                          </span>
                        ) : (
                          <span>
                            <Link to="/create-patient">
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </span>
                        )}
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            color: "#757A80",
                            display: "block",
                            lineHeight: "24px",
                            fontSize: "14px",
                          }}
                        >
                          Setup Approval Date
                        </span>
                        {patient.setup_approval_date ? (
                          <span
                            style={{
                              color: "#121925",
                              lineHeight: "32px",
                              fontSize: "16px",
                              fontWeight: 400,
                            }}
                          >
                            {patient.setup_approval_date}
                          </span>
                        ) : (
                          <span>
                            <Link to="/create-patient">
                              <Icon type="edit" style={{ color: "#757A80" }} />
                            </Link>
                          </span>
                        )}
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Prescription" key="3">
                    {patient.clinical_preferences ? (
                      <Row>
                        {[
                          ["Arch To Treat", ["Both", "Upper", "Lower"]],
                          ["Treatment Type", ["Full Arch", "Anterior"]],
                          ["Overjet", ["Maintain", "Improve"]],
                          ["Midline", ["Maintain", "Improve"]],
                          ["Overbite", ["Do not correct", "Correct"]],
                          [
                            "Posterior Crossbite",
                            ["Do not correct", "Correct"],
                          ],
                          ["Spacing", ["Close All", "Leave All"]],
                        ].map((cp, idx) => {
                          const key = cp[0].toLowerCase().replace(/ /g, "_");
                          return (
                            <Col span={8} style={{ marginTop: "30px" }}>
                              <p
                                style={{
                                  color: "#757A80",
                                  lineHeight: "20px",
                                  fontSize: "14px",
                                }}
                              >
                                {cp[0]}
                              </p>
                              <span
                                style={{
                                  color: "#121925",
                                  lineHeight: "24px",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                }}
                              >
                                {/* {patient.clinical_preferences[cp[0].toLowerCase().replace(/ /g, '_')]} */}
                                {editing_preferences ? (
                                  <RadioGroup
                                    // onChange={handlePatientFieldChange(cp[0])}
                                    onChange={handlePatientFieldChange(key)}
                                    size="small"
                                    defaultValue={
                                      patient.clinical_preferences
                                        ? patient.clinical_preferences[key]
                                        : cp[1][0]
                                    }
                                  >
                                    {cp[1].map((val) => (
                                      <RadioButton
                                        disabled={
                                          editing_preferences
                                            ? false
                                            : patient.clinical_preferences &&
                                              patient.clinical_preferences[
                                                key
                                              ] === val
                                            ? false
                                            : true
                                        }
                                        value={val}
                                      >
                                        {val}
                                      </RadioButton>
                                    ))}
                                  </RadioGroup>
                                ) : (
                                  patient.clinical_preferences[key]
                                )}
                              </span>
                            </Col>
                          );
                        }, this)}
                        <Col span={24} style={{ marginTop: "30px" }} />
                        <Col xs={24} lg={12} style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "inline-grid",
                              marginRight: "30px",
                              textAlign: "right",
                            }}
                          >
                            <p
                              style={{
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Crowding
                            </p>
                            <p
                              style={{
                                alignSelf: "start",
                                marginBottom: "5px",
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Expand
                            </p>
                            <p
                              style={{
                                alignSelf: "center",
                                marginBottom: "5px",
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Procline
                            </p>
                            <p
                              style={{
                                alignSelf: "center",
                                marginBottom: "5px",
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              IPR Anterior
                            </p>
                            <p
                              style={{
                                alignSelf: "center",
                                marginBottom: "5px",
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              IPR Posterior Right
                            </p>
                            <p
                              style={{
                                alignSelf: "center",
                                marginBottom: "5px",
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              IPR Posterior Left
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              display: "inline-grid",
                            }}
                          >
                            <p
                              style={{
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Upper
                            </p>
                            {/* <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}> */}
                            {[
                              ["expand", ["As Needed", "Primarily"]],
                              ["procline", ["As Needed", "Primarily"]],
                              ["ipr_anterior", ["As Needed", "Primarily"]],
                              [
                                "ipr_posterior_right",
                                ["As Needed", "Primarily"],
                              ],
                              [
                                "ipr_posterior_left",
                                ["As Needed", "Primarily"],
                              ],
                            ].map((cp) =>
                              editing_preferences ? (
                                <RadioGroup
                                  key={cp[0]}
                                  defaultValue={
                                    patient.clinical_preferences
                                      ? patient.clinical_preferences
                                          .crowding_upper[cp[0]]
                                      : cp[1][0]
                                  }
                                >
                                  {cp[1].map((val) => (
                                    <RadioButton
                                      key={val}
                                      disabled={
                                        editing_preferences
                                          ? false
                                          : patient.clinical_preferences &&
                                            patient.clinical_preferences
                                              .crowding_upper[cp[0]] === val
                                          ? false
                                          : true
                                      }
                                      value={val}
                                    >
                                      {val}
                                    </RadioButton>
                                  ))}
                                </RadioGroup>
                              ) : (
                                <p
                                  key={cp[0]}
                                  style={{
                                    marginBottom: "5px",
                                    color: "#121925",
                                    lineHeight: "24px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {" "}
                                  {
                                    patient.clinical_preferences.crowding_upper[
                                      cp[0]
                                    ]
                                  }
                                </p>
                              )
                            )}

                            {/* </p> */}
                            {/* <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_upper.procline}</p>
                                                    <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_upper.ipr_anterior}</p>
                                                    <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_upper.ipr_posterior_right}</p>
                                                    <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_upper.ipr_posterior_left}</p> */}
                          </div>
                          <div style={{ display: "inline-grid" }}>
                            <Divider
                              style={{ height: "100%" }}
                              type="vertical"
                            />
                          </div>
                          <div style={{ display: "inline-grid" }}>
                            <p
                              style={{
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Lower
                            </p>

                            {[
                              ["expand", ["As Needed", "Primarily"]],
                              ["procline", ["As Needed", "Primarily"]],
                              ["ipr_anterior", ["As Needed", "Primarily"]],
                              [
                                "ipr_posterior_right",
                                ["As Needed", "Primarily"],
                              ],
                              [
                                "ipr_posterior_left",
                                ["As Needed", "Primarily"],
                              ],
                            ].map((cp) =>
                              editing_preferences ? (
                                <RadioGroup
                                  key={cp[0]}
                                  defaultValue={
                                    patient.clinical_preferences
                                      ? patient.clinical_preferences
                                          .crowding_lower[cp[0]]
                                      : cp[1][0]
                                  }
                                >
                                  {cp[1].map((val) => (
                                    <RadioButton
                                      key={val}
                                      disabled={
                                        editing_preferences
                                          ? false
                                          : patient.clinical_preferences &&
                                            patient.clinical_preferences
                                              .crowding_lower[cp[0]] === val
                                          ? false
                                          : true
                                      }
                                      value={val}
                                    >
                                      {val}
                                    </RadioButton>
                                  ))}
                                </RadioGroup>
                              ) : (
                                <p
                                  key={cp[0]}
                                  style={{
                                    marginBottom: "5px",
                                    color: "#121925",
                                    lineHeight: "24px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {" "}
                                  {
                                    patient.clinical_preferences.crowding_lower[
                                      cp[0]
                                    ]
                                  }
                                </p>
                              )
                            )}

                            {/* <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_lower.expand}</p>
                                                    <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_lower.procline}</p>
                                                    <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_lower.ipr_anterior}</p>
                                                    <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_lower.ipr_posterior_right}</p>
                                                    <p style={{ marginBottom: '5px', color: '#121925', lineHeight: '24px', fontSize: '16px' }}>{patient.clinical_preferences.crowding_lower.ipr_posterior_left}</p> */}
                          </div>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Row>
                            <Col span={24} style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "inline-grid",
                                  marginRight: "30px",
                                  textAlign: "right",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#757A80",
                                    lineHeight: "20px",
                                    fontSize: "14px",
                                  }}
                                >
                                  A-P Relationship
                                </p>
                                <p
                                  style={{
                                    alignSelf: "center",
                                    marginBottom: "5px",
                                    color: "#757A80",
                                    lineHeight: "20px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Canine
                                </p>
                                <p
                                  style={{
                                    alignSelf: "center",
                                    marginBottom: "5px",
                                    color: "#757A80",
                                    lineHeight: "20px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Molar
                                </p>
                              </div>
                              <div
                                style={{
                                  textAlign: "right",
                                  display: "inline-grid",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#757A80",
                                    lineHeight: "20px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Left
                                </p>
                                {[
                                  ["left_canine", ["Maintain", "Improve"]],
                                  ["left_molar", ["Maintain", "Improve"]],
                                ].map((cp) =>
                                  editing_preferences ? (
                                    <RadioGroup
                                      key={cp[0]}
                                      buttonStyle={
                                        editing_preferences
                                          ? "solid"
                                          : "outline"
                                      }
                                      defaultValue={
                                        patient.clinical_preferences
                                          ? patient.clinical_preferences
                                              .ap_relationship[cp[0]]
                                          : cp[1][0]
                                      }
                                    >
                                      {cp[1].map((val) => (
                                        <RadioButton
                                          key={val}
                                          disabled={
                                            editing_preferences
                                              ? false
                                              : patient.clinical_preferences &&
                                                patient.clinical_preferences
                                                  .ap_relationship[cp[0]] ===
                                                  val
                                              ? false
                                              : true
                                          }
                                          value={val}
                                        >
                                          {val}
                                        </RadioButton>
                                      ))}
                                    </RadioGroup>
                                  ) : (
                                    <p
                                      key={cp[0]}
                                      style={{
                                        marginBottom: "5px",
                                        color: "#121925",
                                        lineHeight: "24px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {" "}
                                      {
                                        patient.clinical_preferences
                                          .ap_relationship[cp[0]]
                                      }
                                    </p>
                                  )
                                )}
                              </div>
                              <div style={{ display: "inline-grid" }}>
                                <Divider
                                  style={{ height: "100%" }}
                                  type="vertical"
                                />
                              </div>
                              <div style={{ display: "inline-grid" }}>
                                <p
                                  style={{
                                    color: "#757A80",
                                    lineHeight: "20px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Right
                                </p>
                                {[
                                  ["right_canine", ["Maintain", "Improve"]],
                                  ["right_molar", ["Maintain", "Improve"]],
                                ].map((cp) =>
                                  editing_preferences ? (
                                    <RadioGroup
                                      buttonStyle={
                                        editing_preferences
                                          ? "solid"
                                          : "outline"
                                      }
                                      defaultValue={
                                        patient.clinical_preferences
                                          ? patient.clinical_preferences
                                              .ap_relationship[cp[0]]
                                          : cp[1][0]
                                      }
                                    >
                                      {cp[1].map((val) => (
                                        <RadioButton
                                          disabled={
                                            editing_preferences
                                              ? false
                                              : patient.clinical_preferences &&
                                                patient.clinical_preferences
                                                  .ap_relationship[cp[0]] ===
                                                  val
                                              ? false
                                              : true
                                          }
                                          value={val}
                                        >
                                          {val}
                                        </RadioButton>
                                      ))}
                                    </RadioGroup>
                                  ) : (
                                    <p
                                      style={{
                                        marginBottom: "5px",
                                        color: "#121925",
                                        lineHeight: "24px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {" "}
                                      {
                                        patient.clinical_preferences
                                          .ap_relationship[cp[0]]
                                      }
                                    </p>
                                  )
                                )}
                              </div>
                            </Col>
                            <Col span={24} style={{ marginTop: "15px" }}>
                              {/* <span style={{ color: '#757A80', lineHeight: '20px', fontSize: '14px', marginRight: '2em' }}>Corrections to Class I</span>
                                                        <span style={{ color: '#121925', lineHeight: '24px', fontSize: '16px', fontWeight: 400 }}>
                                                            {/* {patient.clinical_preferences[cp[0].toLowerCase().replace(/ /g, '_')]} */}
                              {
                                // editing_preferences ?
                                //     // <RadioGroup buttonStyle={editing_preferences ? 'solid' : 'outline'} defaultValue={patient.clinical_preferences ? patient.clinical_preferences.ap_relationship[key] : cp[1][0]} >
                                //     <RadioGroup buttonStyle={editing_preferences ? 'solid' : 'outline'} defaultValue={patient.clinical_preferences ? patient.clinical_preferences.ap_relationship[key] : cp[1][0]} >
                                //         {cp[1].map(val =>
                                //             <RadioButton
                                //                 disabled={
                                //                     editing_preferences ? false :
                                //                         patient.clinical_preferences && patient.clinical_preferences.ap_relationship[key] === val ? false : true}
                                //                 value={val}>
                                //                 {val}
                                //             </RadioButton>
                                //         )}
                                //     </RadioGroup>
                                // :
                                //     !patient.clinical_preferences || (!patient.clinical_preferences.ap_relationship.correct_class_I_left && !patient.clinical_preferences.ap_relationship.correct_class_I_right) ?
                                //         'None' :
                                //         `${patient.clinical_preferences.ap_relationship.correct_class_I_left ? 'Left ' : ''} ${patient.clinical_preferences.ap_relationship.correct_class_I_right ? 'Right' : ''}`
                              }
                              {/*</span> */}
                            </Col>
                            {[
                              [
                                "Distalization",
                                ["Full Anchorage", "Partial Anchorage", "None"],
                              ],
                              ["Posterior IPR", ["Yes", "No"]],
                            ].map((cp, idx) => {
                              const key = cp[0]
                                .toLowerCase()
                                .replace(/ /g, "_");
                              return (
                                <Col span={24} style={{ marginTop: "15px" }}>
                                  <span
                                    style={{
                                      color: "#757A80",
                                      lineHeight: "20px",
                                      fontSize: "14px",
                                      marginRight: "2em",
                                    }}
                                  >
                                    {cp[0]}
                                  </span>
                                  <span
                                    style={{
                                      color: "#121925",
                                      lineHeight: "24px",
                                      fontSize: "16px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {/* {patient.clinical_preferences[cp[0].toLowerCase().replace(/ /g, '_')]} */}
                                    {editing_preferences ? (
                                      // <RadioGroup buttonStyle={editing_preferences ? 'solid' : 'outline'} defaultValue={patient.clinical_preferences ? patient.clinical_preferences.ap_relationship[key] : cp[1][0]} >
                                      <RadioGroup
                                        buttonStyle={
                                          editing_preferences
                                            ? "solid"
                                            : "outline"
                                        }
                                        defaultValue={
                                          patient.clinical_preferences
                                            ? patient.clinical_preferences
                                                .ap_relationship[key]
                                            : cp[1][0]
                                        }
                                      >
                                        {cp[1].map((val) => (
                                          <RadioButton
                                            disabled={
                                              editing_preferences
                                                ? false
                                                : patient.clinical_preferences &&
                                                  patient.clinical_preferences
                                                    .ap_relationship[key] ===
                                                    val
                                                ? false
                                                : true
                                            }
                                            value={val}
                                          >
                                            {val}
                                          </RadioButton>
                                        ))}
                                      </RadioGroup>
                                    ) : (
                                      patient.clinical_preferences
                                        .ap_relationship[key]
                                    )}
                                  </span>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                        <Col span={24} />
                        <Col xl={24} xxl={12} style={{ marginTop: "30px" }}>
                          <Col span={24}>
                            <p
                              style={{
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Tooth Movement Restrictions
                            </p>
                          </Col>
                          <TeethTable
                            table={
                              patient.movement_restrictions
                                ? patient.movement_restrictions
                                : []
                            }
                            system={tooth_num_system}
                          />
                        </Col>
                        <Col xl={24} xxl={12} style={{ marginTop: "30px" }}>
                          <Col span={24}>
                            <p
                              style={{
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Attachment Restrictions
                            </p>
                          </Col>
                          <TeethTable
                            table={
                              patient.attachment_restrictions
                                ? patient.attachment_restrictions
                                : []
                            }
                            system={tooth_num_system}
                          />
                        </Col>
                        <Col xl={24} xxl={12} style={{ marginTop: "30px" }}>
                          <Col span={24}>
                            <p
                              style={{
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Extractions
                            </p>
                          </Col>
                          <TeethTable
                            table={
                              patient.extractions ? patient.extractions : []
                            }
                            system={tooth_num_system}
                          />
                        </Col>
                        <Col xl={24} xxl={12} style={{ marginTop: "30px" }}>
                          <Col span={24}>
                            <p
                              style={{
                                color: "#757A80",
                                lineHeight: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Missing Teeth
                            </p>
                          </Col>
                          <TeethTable
                            table={
                              patient.missing_teeth ? patient.missing_teeth : []
                            }
                            system={tooth_num_system}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Empty
                        style={{ marginTop: "30px" }}
                        description={<span>No prescription found</span>}
                      >
                        <Button type="primary">
                          <Link to={`/edit-patient/${patient.patientid}#3`}>
                            Create
                          </Link>
                        </Button>
                      </Empty>
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Files" key="4">
                    <Row className="patient-file-list">
                      <Col xs={24} lg={6} style={{ marginTop: "30px" }}>
                        <h3>X-Rays</h3>
                        <Divider
                          style={{
                            width: "33%",
                            minWidth: "33%",
                            margin: "10px 0 -5px 0",
                          }}
                        />
                        <List
                          itemLayout="vertical"
                          // size="large"
                          dataSource={
                            files["xray"] ? files["xray"] : ["FMX", "Pano"]
                          }
                          split={false}
                          loading={this.state.filesLoading && !files["xray"]}
                          renderItem={(file) => (
                            <List.Item
                              actions={[
                                ...(file.includes("http")
                                  ? [
                                      <span>
                                        <Icon
                                          style={{ marginRight: 8 }}
                                          type="download"
                                        />
                                        <a href={file}>Download</a>
                                      </span>,
                                    ]
                                  : []),
                                // <span><Icon style={{ marginRight: 8 }} type='edit' />Edit</span>
                              ]}
                            >
                              <List.Item.Meta
                                title={
                                  file.includes("http") ? (
                                    <span>
                                      <Icon
                                        type="file"
                                        style={{ marginRight: 8 }}
                                      />
                                      {file.split("?")[0].split("/").slice(-1)}
                                    </span>
                                  ) : (
                                    <span>
                                      <Icon type="file-add" />
                                    </span>
                                  )
                                }
                                description={
                                  file.toLowerCase().includes("fmx")
                                    ? "FMX"
                                    : "Pano"
                                }
                                style={{ marginBottom: "0px" }}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                      <Col xs={24} lg={6} style={{ marginTop: "30px" }}>
                        <h3>3D Models</h3>
                        <Divider
                          style={{
                            width: "33%",
                            minWidth: "33%",
                            margin: "10px 0 -5px 0",
                          }}
                        />
                        <List
                          itemLayout="vertical"
                          // size="large"
                          dataSource={
                            files["models"]
                              ? files["models"]
                              : ["upper", "lower"]
                          }
                          split={false}
                          loading={this.state.filesLoading && !files["models"]}
                          renderItem={(file) => (
                            <List.Item
                              actions={[
                                ...(file.length && files["models"]
                                  ? [
                                      <span>
                                        <Icon
                                          style={{ marginRight: 8 }}
                                          type="download"
                                        />
                                        <a href={file}>Download</a>
                                      </span>,
                                    ]
                                  : []),
                                // <span><Icon style={{ marginRight: 8 }} type='edit' />Edit</span>
                              ]}
                            >
                              <List.Item.Meta
                                title={
                                  file.includes("http") ? (
                                    <span>
                                      <Icon
                                        type="file"
                                        style={{ marginRight: 8 }}
                                      />
                                      {file.split("?")[0].split("/").slice(-1)}
                                    </span>
                                  ) : (
                                    <span>
                                      <Icon type="file-add" />
                                    </span>
                                  )
                                }
                                description={
                                  file.includes("upper")
                                    ? "Upper STL"
                                    : "Lower STL"
                                }
                                style={{ marginBottom: "0px" }}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                      <Col xs={24} lg={6} style={{ marginTop: "30px" }}>
                        <h3>Refinements</h3>
                        {files["refinements"] &&
                          files["refinements"].length >= 2 && (
                            <span>
                              <Icon
                                style={{ marginRight: 8 }}
                                type="cloud-download"
                              />
                              <a
                                onClick={() =>
                                  this.handleOpenInArchform(
                                    "",
                                    files["refinements"].length / 2
                                  )
                                }
                              >
                                Open in ArchForm
                              </a>
                            </span>
                          )}

                        <Divider
                          style={{
                            width: "33%",
                            minWidth: "33%",
                            margin: "10px 0 -5px 0",
                          }}
                        />
                        <List
                          itemLayout="vertical"
                          // size="large"
                          dataSource={
                            files["refinements"]
                              ? files["refinements"]
                              : ["upper", "lower"]
                          }
                          split={false}
                          loading={
                            this.state.filesLoading && !files["refinements"]
                          }
                          renderItem={(file) => (
                            <List.Item
                              actions={[
                                ...(file.length && files["refinements"]
                                  ? [
                                      <span>
                                        <Icon
                                          style={{ marginRight: 8 }}
                                          type="download"
                                        />
                                        <a href={file}>Download</a>
                                      </span>,
                                    ]
                                  : []),
                                // <span><Icon style={{ marginRight: 8 }} type='edit' />Edit</span>
                              ]}
                            >
                              <List.Item.Meta
                                title={
                                  file.includes("http") ? (
                                    <span>
                                      <Icon
                                        type="file"
                                        style={{ marginRight: 8 }}
                                      />
                                      {file.split("?")[0].split("/").slice(-1)}
                                    </span>
                                  ) : (
                                    <span>
                                      <Icon type="file-add" />
                                    </span>
                                  )
                                }
                                description={
                                  file.includes("upper")
                                    ? "Upper STL"
                                    : "Lower STL"
                                }
                                style={{ marginBottom: "0px" }}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                      <Col xs={24} lg={6} style={{ marginTop: "30px" }}>
                        <h3>Setup Versions</h3>
                        <Upload
                          accept=".zip"
                          name="archform"
                          beforeUpload={() => false}
                          onChange={this.uploadArchformFile}
                          showUploadList={false}
                        >
                          <span style={{ cursor: "pointer" }}>
                            <Icon style={{ marginRight: 8 }} type="plus" />
                            <p style={{ display: "inline" }}>Upload</p>
                          </span>
                        </Upload>

                        <Divider
                          style={{
                            width: "33%",
                            minWidth: "33%",
                            margin: "10px 0 -5px 0",
                          }}
                        />
                        <List
                          itemLayout="vertical"
                          // size="large"
                          dataSource={files["archform"]}
                          split={false}
                          loading={
                            this.state.filesLoading && !files["archform"]
                          }
                          renderItem={(file) => {
                            const file_name = file
                              .split("?")[0]
                              .split("/")
                              .slice(-1)[0];
                            return (
                              <List.Item
                                actions={[
                                  <span>
                                    <Icon
                                      style={{ marginRight: 8 }}
                                      type="download"
                                    />
                                    <a href={file}>Download</a>
                                  </span>,
                                  <span>
                                    <Icon
                                      style={{ marginRight: 8 }}
                                      type="delete"
                                    />
                                    <a
                                      onClick={() =>
                                        this.handleDeleteArchformFile(
                                          file
                                            .split("?")[0]
                                            .split("/")
                                            .slice(-1)[0],
                                          file,
                                          "archform"
                                        )
                                      }
                                    >
                                      Delete
                                    </a>
                                  </span>,
                                  <span>
                                    <Icon
                                      style={{ marginRight: 8 }}
                                      type="cloud-download"
                                    />
                                    <a
                                      onClick={() =>
                                        this.handleOpenInArchform(
                                          file
                                            .split("?")[0]
                                            .split("/")
                                            .slice(-1)[0]
                                        )
                                      }
                                    >
                                      Open in ArchForm
                                    </a>
                                  </span>,
                                ]}
                              >
                                <List.Item.Meta
                                  title={[
                                    <span>
                                      <Icon
                                        type="file"
                                        style={{ marginRight: 8 }}
                                      />
                                      {/[0-9]*_[0-9]*_[0-9]*/g.test(file_name)
                                        ? `v${file_name.split("_")[0]}.${
                                            file_name.split("_")[1]
                                          } ${moment(
                                            file_name.split("_")[2],
                                            "YYYYMMDHms"
                                          ).format("lll")}`
                                        : file_name}
                                    </span>,
                                  ]}
                                  style={{ marginBottom: "0px" }}
                                />
                              </List.Item>
                            );
                          }}
                        />
                      </Col>
                      {/* {
                                            ['XRays', 'Models', 'ArchForm'].map(filetype => (
                                                <Col xs={24} lg={8} style={{ marginTop: '30px' }} >
                                                    <h3>{filetype.charAt(0).toUpperCase() + filetype.slice(1)}</h3>
                                                    <Divider style={{ width: '33%', minWidth: '33%', margin: '10px 0 -5px 0' }} />
                                                    <List
                                                        itemLayout="vertical"
                                                        // size="large"
                                                        dataSource={files[filetype.toLowerCase()]}
                                                        split={false}
                                                        loading={this.state.filesLoading && !files[filetype.toLowerCase()]}
                                                        renderItem={file => (
                                                            <List.Item
                                                                actions={[
                                                                    <span><Icon style={{ marginRight: 8 }} type='download' />Download</span>,
                                                                    <span><Icon style={{ marginRight: 8 }} type='edit' />Edit</span>]}
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={<Icon type='file' />}
                                                                    title={file.split('?')[0].split('/').slice(-1)}
                                                                    style={{ marginBottom: '0px' }}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                </Col>
                                            ))
                                        } */}

                      {/* <Col xs={24} lg={8} style={{ marginTop: '30px' }} >
                                            <List
                                                itemLayout="vertical"
                                                // size="large"
                                                dataSource={files.models}
                                                renderItem={file => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Icon type='file' />}
                                                            title={file.split('?')[0].split('/').slice(-1)}
                                                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={24} lg={8} style={{ marginTop: '30px' }} >
                                            <List
                                                itemLayout="vertical"
                                                // size="large"
                                                dataSource={files.models}
                                                renderItem={file => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Icon type='file' />}
                                                            title={file.split('?')[0].split('/').slice(-1)}
                                                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </Col> */}
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Col>
              <Col xs={24} lg={5} style={{ background: "#fff" }}>
                {/* <Button type='primary' style={{ width: '75%', background: '#0076FF' }}> Approve Setup </Button> */}
                <Dropdown.Button
                  overlay={
                    <Menu onClick={this.handlePatientStatusChange}>
                      <Menu.Item key="Unprocessed">Unprocessed</Menu.Item>
                      <Menu.Item key="Planning">Planning</Menu.Item>
                      <Menu.Item key="Needs Review">Needs Review</Menu.Item>
                      <Menu.Item key="Accepted">Accepted</Menu.Item>
                      <Menu.Item key="In Production">In Production</Menu.Item>
                      <Menu.Item key="In Treatment">In Treatment</Menu.Item>
                      <Menu.Item key="Archived">Archived</Menu.Item>
                    </Menu>
                  }
                  onClick={this.handlePatientNextStatusChange}
                  type="primary"
                  style={{ width: "75%" }}
                >
                  <span>
                    {(patient.status === "Unprocessed" && "Start Planning") ||
                      (patient.status === "Planning" && "Ready for review") ||
                      (patient.status === "Needs Review" && "Accept Setup") ||
                      (patient.status === "Accepted" && "Start Production") ||
                      (patient.status === "In Production" &&
                        "Start Treatment") ||
                      (patient.status === "In Treatment" &&
                        "Archive Patient") ||
                      (patient.status === "Archived" && "Archived")}
                  </span>
                </Dropdown.Button>
                {patient.status === "Needs Review" && (
                  <Button
                    onClick={this.handlePatientSetupNeedsChanges}
                    type="primary"
                    style={{ marginTop: ".5em" }}
                  >
                    Setup needs changes
                  </Button>
                )}
                <p
                  onClick={() => this.handleOpenInArchform("")}
                  style={{
                    fontWeight: 500,
                    color: "#0076FF",
                    cursor: "pointer",
                    lineHeight: "20px",
                    marginTop: "20px",
                  }}
                >
                  Open in ArchForm
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    color: "#0076FF",
                    cursor: "pointer",
                    lineHeight: "20px",
                  }}
                >
                  <Link to={`/edit-patient/${patient.patientid}`}>
                    Edit Patient
                  </Link>
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    color: "#0076FF",
                    cursor: "pointer",
                    lineHeight: "20px",
                  }}
                >
                  <Link to={`/edit-patient/${patient.patientid}#3`}>
                    Edit Prescription
                  </Link>
                </p>
                <p
                  onClick={() =>
                    this.setState({ newRefinementModalVisible: true })
                  }
                  style={{
                    fontWeight: 500,
                    color: "#0076FF",
                    cursor: "pointer",
                    lineHeight: "20px",
                    marginTop: "20px",
                  }}
                >
                  Create Refinement
                </p>
                <p
                  style={{
                    fontWeight: 500,
                    color: "#0076FF",
                    cursor: "pointer",
                    lineHeight: "20px",
                  }}
                >
                  <Link to={`/edit-patient/${patient.patientid}#2`}>
                    Upload Files
                  </Link>
                </p>
                <Popconfirm
                  title="Permanently delete this patient?"
                  onConfirm={this.handleDeletePatient}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <p
                    style={{
                      fontWeight: 500,
                      color: "#0076FF",
                      cursor: "pointer",
                      lineHeight: "20px",
                      marginTop: "20px",
                    }}
                  >
                    Delete Patient
                  </p>
                </Popconfirm>
                {/* <p style={{ fontWeight: 500, color: '#0076FF', cursor: 'pointer', lineHeight: '20px' }}>Order Aligners</p> */}
              </Col>
            </Row>
          </Content>
        </Spin>
        {this.state.imgModalVisible && (
          <Icon
            type="caret-left"
            onClick={(ev) => {
              if (this.carousel && this.carousel.current)
                this.carousel.current.prev();
            }}
            style={{
              fontSize: "8em",
              color: "white",
              position: "absolute",
              top: "50%",
              zIndex: 1001,
              left: "5%",
            }}
          ></Icon>
        )}
        {this.state.imgModalVisible && (
          <Icon
            type="caret-right"
            onClick={(ev) => {
              if (this.carousel && this.carousel.current)
                this.carousel.current.next();
            }}
            style={{
              fontSize: "8em",
              color: "white",
              position: "absolute",
              top: "50%",
              zIndex: 1001,
              right: "5%",
            }}
          ></Icon>
        )}

        <Modal
          // title="Basic Modal"
          visible={this.state.imgModalVisible}
          footer={null}
          closable={false}
          // onOk={this.handleOk}
          onCancel={() => this.setState({ imgModalVisible: false })}
          width="80%"
          className="patient-image-modal"
        >
          <Carousel
            ref={this.carousel}
            initialSlide={this.state.carouselIdx}
            dotPosition="top"
          >
            {files.composite &&
              files.composite.length &&
              (files.composite.length === 1 ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{
                      maxHeight: "90vh",
                      width: "100%",
                      objectFit: "contain",
                      display: "inherit",
                    }}
                    src={files.composite[0]}
                  />
                  <h3
                    style={{
                      textAlign: "center",
                      lineHeight: "60px",
                      color: "#fff",
                    }}
                  >
                    Full Composite
                  </h3>
                </div>
              ) : (
                [
                  [2, "Full face smiling"],
                  [1, "Full Not face smiling"],
                  [5, "Profile"],
                  [7, "Upper Occlusal"],
                  [4, "Lower Occlusal"],
                  [6, "Right Lateral"],
                  [0, "Front"],
                  [3, "Left Lateral"],
                ].map(([idx, label]) =>
                  files.composite[idx] ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          maxHeight: "90vh",
                          width: "100%",
                          objectFit: "contain",
                          display: "inherit",
                        }}
                        src={files.composite[idx]}
                      />
                      <h3
                        style={{
                          textAlign: "center",
                          lineHeight: "60px",
                          color: "#fff",
                        }}
                      >
                        {label}
                      </h3>
                    </div>
                  ) : null
                )
              ))}
            {files.xray
              ? [
                  [0, "FMX X-Ray"],
                  [1, "Pano X-Ray"],
                ].map(([idx, label]) =>
                  files.xray[idx] ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          maxHeight: "90vh",
                          width: "100%",
                          objectFit: "contain",
                          display: "inherit",
                        }}
                        src={files.xray[idx]}
                      />
                      <h3
                        style={{
                          textAlign: "center",
                          lineHeight: "60px",
                          color: "#fff",
                        }}
                      >
                        {label}
                      </h3>
                    </div>
                  ) : null
                )
              : null}
          </Carousel>
        </Modal>
        <Modal
          title={this.state.chatMsgTitle}
          visible={this.state.chatModalVisible}
          closable={true}
          onOk={this.handleNewChatMsg}
          onCancel={() =>
            this.setState({
              chatModalVisible: false,
              chatMsgText: "",
              chatMsgTitle: "Edit Note",
              chatMsgFiles: [],
            })
          }
        >
          <Upload
            name="archform"
            beforeUpload={() => false}
            onChange={this.handleChatMsgFiles}
            showUploadList={true}
            fileList={this.state.chatMsgFiles}
            previewVisible={true}
            listType="picture-card"
          >
            <span style={{ cursor: "pointer" }}>
              <Icon style={{ marginRight: 8 }} type="plus" />
              <p style={{ display: "inline" }}>Upload</p>
            </span>
          </Upload>
          <TextArea
            style={{ marginTop: "1em" }}
            autosize={{ minRows: 8 }}
            value={this.state.chatMsgText}
            onChange={(evt) => this.setState({ chatMsgText: evt.target.value })}
          />
        </Modal>
        <Modal
          visible={this.state.chatImgModalVisible}
          footer={null}
          closable={true}
          onCancel={() => this.setState({ chatImgModalVisible: false })}
          className="patient-image-modal"
          bodyStyle={{ width: "100%" }}
          width="100%"
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            <img style={{ height: "90vh" }} src={this.state.chatModalImg} />
          </div>
        </Modal>
        <Modal
          id="create-refinement-modal"
          title="Create Refinement"
          visible={this.state.newRefinementModalVisible}
          closable={true}
          onOk={this.handleNewRefinement}
          onCancel={() => this.setState({ newRefinementModalVisible: false })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0",
              cursor: "pointer",
            }}
          >
            <Upload
              name="stl_upper"
              beforeUpload={() => false}
              onChange={(file) =>
                this.setState({
                  newUpperRefinementFile: file.fileList.slice(-1)[0],
                })
              }
              // fileList={this.state.newUpperRefinementFile}
              // previewVisible={true}
              // listType='picture-card'
            >
              <img style={{ width: "100%" }} src={stl_upper_bw} />
              <p>Upper</p>
            </Upload>
            <Upload
              name="stl_lower"
              beforeUpload={() => false}
              onChange={(file) =>
                this.setState({
                  newLowerRefinementFile: file.fileList.slice(-1)[0],
                })
              }
              // fileList={this.state.newLowerRefinementFile}
              // showUploadList={true}
              // fileList={this.state.chatMsgFiles}

              // previewVisible={true}
              // listType='picture-card'
            >
              <img style={{ width: "100%" }} src={stl_lower_bw} />
              <p>Lower</p>
            </Upload>
          </div>
        </Modal>
      </Layout>
    );
  }
}

export default withRouter(PatientView);
