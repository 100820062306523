import React, { useState } from "react";
import { useAppViewCtrl } from "../container/app-view-state";
import {
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
  Divider,
  Slider,
} from "@material-ui/core";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import collapse from "../images/fi_layout.svg";
import { MenuIcon } from "../images/icons";
import "./main.css";

const useStyles = makeStyles((theme) => ({
  options: {
    position: "absolute",
    bottom: 16,
    left: 16,
    width: 140,
  },
  optionButton_active: {
    position: "relative",
    width: "116px",
    height: "40px",
    padding: "12px 24px",
    marginTop: "8px",
    color: "black",
    backgroundColor: "#FAFAFA",
    boxShadow: "none !important",
    border: "1px solid #CCCCCC",
    borderRadius: "4px",
    fontWeight: "normal",
    fontSize: "16px",
    fontFamily: "Inter",
    letterSpacing: "-0.04em",
    textTransform: "none",
  },
  optionbutton_inactive: {
    position: "relative",
    width: "116px",
    height: "40px",
    padding: "12px 24px",
    marginTop: "8px",
    color: "gray",
    backgroundColor: "#FAFAFA",
    boxShadow: "none !important",
    border: "1px solid #CCCCCC",
    borderRadius: "4px",
    fontWeight: "normal",
    fontSize: "16px",
    fontFamily: "Inter",
    letterSpacing: "-0.04em",
    textTransform: "none",
  },
  views: {
    position: "absolute",
    bottom: "15px",
    right: "15px",
    width: "146px",
    height: "146px",
    cursor: "pointer",
  },
  views_mobile: {
    position: "absolute",
    top: 74,
    left: 11,
    width: 100,
    height: 280,
    cursor: "pointer",
  },
  slider: {
    position: "absolute",
    width: "160px",
    height: "40px",
    top: 150,
    right: "55px",
    fontSize: "12px",
    display: "inline-block",
  },
  slider_mobile: {
    position: "absolute",
    width: "160px",
    height: "40px",
    top: 72,
    right: 27,
    fontSize: "12px",
    display: "inline-block",
  },
  active: {
    backgroundColor: "red",
  },
  inactive: {
    backgroundColor: "white",
  },
  navigator: {
    height: 32,
    width: 100,
    borderRadius: 8,
    backgroundColor: "white !important",
    textTransform: "none",
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "black",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "black",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon theme />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const EvalSliderModal = styled.div`
  & .sliders {
    & .MuiSlider-rail {
      height: 8px;
      background: #ffffff;
      border-radius: 8px;
      opacity: 1;
    }

    & .MuiSlider-track {
      height: 10px;
      display: none;
      position: absolute;
      border-radius: 8px;
      color: #f3f4f5;
    }

    & .MuiSlider-mark:nth-child(n) {
      top: -7px;
      height: 30px;
      width: 3.5px;
      background: #f3f4f5;
    }
    & .MuiSlider-mark:nth-child(4n + 0) {
      top: -18px;
      height: 40px;
      width: 4px;

      background: #f3f4f5;
    }

    & .MuiSlider-thumb {
      height: 18px;
      width: 18px;
      color: #8a8f98;
      border: 2px solid white;
    }

    & .MuiSlider-markActive {
      background: #111;
    }
    & .MuiSlider-markLabel {
      font: bold 12px Quantico;
      margin-top: 20px;
    }
    & .MuiSlider-markLabelActive {
      color: #32ad37;
    }
  }
`;

const StyledNavigatorBtn = styled(Button)({
  width: 70,
  height: 32,
  backgroundColor: "white",
  borderRadius: 8,
  marginTop: 12,
  textTransform: "none",
  ":hover": {
    backgroundColor: "white",
  },
});

export const ViewOptions = () => {
  const classes = useStyles();

  const {
    sliderValue,
    setSliderValue,
    gridVisible,
    setGridVisible,
    curvesVisible,
    setCurvesVisible,
    iprVisible,
    setIprVisible,
    cutoutsVisible,
    setCutoutsVisible,
    overlayVisible,
    setOverlayVisible,
    toothnumberVisible,
    setToothnumberVisible,
    axesVisible,
    setAxesVisible,
    collisionVisible,
    setCollisionVisible,
    setViews,
    isMobileView,
  } = useAppViewCtrl();

  const [state, setState] = useState({
    value: sliderValue,
    out: false,
  });

  const [visibilityLayerOpen, setVisibilityLayerOpen] = useState(true);
  const [navigatorOpen, setNavigatorOpen] = useState(false);

  const onChange = (e, v) => {
    if (v > 1.2 || v < 0.8) {
      setState({ value: v, out: true });
    } else if (v === 1.2 || v === 0.8) {
      if (state.out) {
        setState({ value: 1, out: false });
      }
    }
  };

  const onCommitChange = (value) => {
    if (value < 0.8) {
      document.getElementById("lower").style.opacity = 0.5;
      document.getElementById("upper").style.opacity = 1;
    } else if (value > 1.2) {
      document.getElementById("upper").style.opacity = 0.5;
      document.getElementById("lower").style.opacity = 1;
    } else {
      document.getElementById("upper").style.opacity = 1;
      document.getElementById("lower").style.opacity = 1;
    }
    setSliderValue(() => value);
  };

  const onToggleVisibilityLayer = () => {
    setVisibilityLayerOpen(() => !visibilityLayerOpen);
  };

  const CheckboxGroup = [
    {
      label: "Grid",
      checked: gridVisible,
      onCheck: () => setGridVisible(!gridVisible),
    },
    {
      label: "IPR",
      checked: iprVisible,
      onCheck: () => setIprVisible(!iprVisible),
    },
    {
      label: "Cutouts & Hooks",
      checked: cutoutsVisible,
      onCheck: () => setCutoutsVisible(!cutoutsVisible),
    },
    {
      label: "Overlay",
      checked: overlayVisible,
      onCheck: () => setOverlayVisible(!overlayVisible),
    },
    {
      label: "Collisions",
      checked: collisionVisible,
      onCheck: () => setCollisionVisible(!collisionVisible),
    },
  ];

  return (
    <div>
      {!isMobileView && (
        <Paper
          className={classes.options}
          elevation={0}
          style={{
            width: 224,
            height: visibilityLayerOpen ? 270 : 41,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: "500",
              height: 40,
              padding: "7px 15px",
              cursor: "pointer",
            }}
            onClick={onToggleVisibilityLayer}
          >
            <img
              src={collapse}
              alt="sdf"
              style={{ marginRight: 10, marginBottom: 3 }}
            />
            Layers
          </div>
          {visibilityLayerOpen && (
            <div>
              <Divider />
              <FormGroup style={{ padding: "8px 16px" }}>
                {CheckboxGroup.map((c) => (
                  <FormControlLabel
                    control={
                      <BpCheckbox
                        style={{
                          color: "black",
                          "&.MuiChecked": {
                            color: "black",
                          },
                          "&.MuiTypographyBody1:firstChild": {
                            fontSize: 14,
                          },
                        }}
                        size="small"
                        checked={c.checked}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        {c.label}
                      </span>
                    }
                    onChange={c.onCheck}
                    style={{ height: 32 }}
                    key={c.label}
                  />
                ))}
              </FormGroup>
              <Button
                variant="outlined"
                size="medium"
                disableRipple
                style={{ margin: "8px 16px 16px", width: 192 }}
                onClick={() => setVisibilityLayerOpen(false)}
              >
                Done
              </Button>
            </div>
          )}
        </Paper>
      )}

      <div className={isMobileView ? classes.slider_mobile : classes.slider}>
        <EvalSliderModal>
          <Slider
            id="smoothViewer"
            value={sliderValue}
            step={0.05}
            min={0}
            max={2}
            onChange={(e, v) => {
              onCommitChange(v);
              onChange(e, v);
            }}
            className="sliders"
          />
        </EvalSliderModal>

        <div className="sliderLabel">
          <p id="upper">Upper</p> <p id="lower">Lower</p>{" "}
        </div>
      </div>
      {isMobileView ? (
        <div className={classes.views_mobile}>
          <Button
            className={classes.navigator}
            startIcon={<MenuIcon />}
            disableRipple
            onClick={() => setNavigatorOpen(!navigatorOpen)}
          >
            Navigator
          </Button>
          {navigatorOpen && (
            <>
              <StyledNavigatorBtn onClick={() => setViews(1)} disableRipple>
                Upper
              </StyledNavigatorBtn>
              <StyledNavigatorBtn onClick={() => setViews(4)} disableRipple>
                Lower
              </StyledNavigatorBtn>
              <StyledNavigatorBtn onClick={() => setViews(5)} disableRipple>
                Right
              </StyledNavigatorBtn>
              <StyledNavigatorBtn onClick={() => setViews(6)} disableRipple>
                Left
              </StyledNavigatorBtn>
              <StyledNavigatorBtn onClick={() => setViews(2)} disableRipple>
                Front
              </StyledNavigatorBtn>
              <StyledNavigatorBtn onClick={() => setViews(3)} disableRipple>
                Back
              </StyledNavigatorBtn>
            </>
          )}
        </div>
      ) : (
        <div className={classes.views}>
          <svg className="upperBtn" onClick={() => setViews(1)}>
            <polygon points={"11 1 71 1 81 25 1 25"} className="polygon" />
            <text position="relative" x="42" y="18" className="text">
              Upper
            </text>
          </svg>
          <svg className="frontBtn" onClick={() => setViews(2)}>
            <polygon points={"1 1 81 1 81 39 1 39"} className="polygon" />
            <text position="relative" x="42" y="25" className="text">
              Front
            </text>
          </svg>
          <svg className="backBtn" onClick={() => setViews(3)}>
            <polygon points={"1 1 81 1 81 39 1 39"} className="polygon" />
            <text position="relative" x="42" y="25" className="text">
              Back
            </text>
          </svg>
          <svg className="lowerBtn" onClick={() => setViews(4)}>
            <polygon points={"1 1 81 1 71 25 11 25"} className="polygon" />
            <text position="relative" x="42" y="18" className="text">
              Lower
            </text>
          </svg>
          <svg className="rightBtn" onClick={() => setViews(5)}>
            <polygon points={"1 11 25 1 25 81 1 71"} className="polygon" />
            <text
              className="rotateRight text"
              position="relative"
              x="-40"
              y="18"
              textAnchor="middle"
              fill="black"
              fontSize="16"
            >
              Right
            </text>
          </svg>
          <svg className="leftBtn" onClick={() => setViews(6)}>
            <polygon points={"1 1 25 11 25 71 1 81"} className="polygon" />
            <text
              className="rotateLeft text"
              position="relative"
              x="40"
              y="-8"
              textAnchor="middle"
              fill="black"
              fontSize="16"
            >
              Left
            </text>
          </svg>
        </div>
      )}
    </div>
  );
};

export default ViewOptions;
