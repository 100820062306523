import { MeshPhysicalMaterial, MeshPhongMaterial } from "three";

export const Materials = {
  tooth: new MeshPhysicalMaterial({
    color: 0xffffff,
    clearcoat: 1,
    roughness: 0.2,
    metalness: 0.1,
    emissive: 0xf7edd9,
    emissiveIntensity: 0.2,
  }),

  tissue: new MeshPhysicalMaterial({
    color: 0xff9999,
    roughness: 0.2,
    reflectivity: 1,
    emissive: 0x6a1653,
    emissiveIntensity: 0.2,
  }),
  compare: new MeshPhongMaterial({
    color: 0x0066ff,
    reflectivity: 8,
    transparent: true,
    opacity: 0.5,
  }),

  pontic: new MeshPhysicalMaterial({
    color: 0x808080,
    clearcoat: 1,
    roughness: 0.2,
    metalness: 0.1,
    emissive: 0xf7edd9,
    emissiveIntensity: 0.2,
  }),
};

export const diffXZ = [
  [-15, 5],
  [-13, 6],
  [-11, 7],
  [-9, 9],
  [-7, 11],
  [-5, 13],
  [0, 15],
  [5, 13],
  [7, 11],
  [9, 9],
  [11, 7],
  [13, 6],
  [15, 5],
];

export const radian = 57.3;

export const Movements = {
  MoveIO: "MoveInOut",
  MoveLR: "MoveLeftRight",
  MoveUD: "MoveUpDown",
  TiltIO: "TiltInOut",
  TiltLR: "TiltLeftRight",
  TiltTwist: "TiltTwist",
};
