import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Tag, Avatar, Dropdown, Menu } from "antd";
import moment from "moment";
import {Amplify} from "aws-amplify";
import { hashCode } from "./Patients";

class PatientTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patients: [],
      avatar_urls: {},
      loading_avatars: false,
    };
  }

  table = React.createRef();

  async getAvatars(patients) {
    const avatar_urls = {};
    for (const patient of patients) {
      const file = await Amplify.Storage.get(
        `${patient.patientid}/composite/full_face_smiling.jpg`,
        {
          level: "protected",
          identityId: patient.userid,
          bucket: "archform-patient-data-v1-resized",
        }
      );
      avatar_urls[patient.patientid] = file;
    }
    this.setState({ avatar_urls: avatar_urls, loading_avatars: false });
  }

  async componentDidUpdate(prevProps) {
    if (
      !this.state.loading_avatars &&
      this.props.patients !== this.state.patients
    ) {
      this.setState({ loading_avatars: true, patients: this.props.patients });
      await this.getAvatars(this.props.patients);
    }
  }

  render() {
    const {
      patients,
      search,
      status,
      selectedRowKeys,
      onSelectRow,
      onChangeRow,
      currentPage,
      handlePageChange,
    } = this.props;
    let filtered_patients = patients;
    const current_date = moment();
    if (search) {
      filtered_patients = patients.filter((patient) => {
        if (
          `${patient.firstname}${patient.lastname}`
            .toLowerCase()
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (
          `${patient.lastname}${patient.firstname}`
            .toLowerCase()
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (
          moment(patient.created)
            .format("MMMM Do YYYY")
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (
          patient.manufacturing &&
          patient.manufacturing
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (
          patient.alignment &&
          patient.alignment
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search.replace(/ /g, ""))
        )
          return true;
        if (patient.custom_treatment && patient.custom_treatment.length) {
          if (
            patient.custom_treatment.some((tag) =>
              tag
                .replace(/ /g, "")
                .toLowerCase()
                .includes(search.replace(/ /g, ""))
            )
          )
            return true;
        }
        return false;
      });
    }

    const avatar_urls = this.state.avatar_urls;
    const columns = [
      {
        title: "Patient",
        dataIndex: "patient",
        key: "patient",
        render: (text, row, index) => (
          <span>
            <Avatar icon="user" src={avatar_urls[row.patientid]} />{" "}
            {`${row.lastname ? row.lastname + ", " : ""}${
              row.firstname ? row.firstname : ""
            }`}
          </span>
        ),
        sorter: (a, b) =>
          `${a.lastname}${a.firstname}` > `${b.lastname}${b.firstname}`
            ? -1
            : 1,
        // filteredValue: search && [search] || [],
        // onFilter: (value, record) => `${record.firstname}${record.lastname}`.toLowerCase().includes(value.replace(/ /g, '')),
      },
      {
        title: "Days in Status",
        dataIndex: "status_timeline",
        render: (timeline) => {
          if (!timeline) return "";
          let status_date = timeline[timeline.length - 1][1];
          if (!status_date) status_date = timeline[timeline.length - 1][0][1];
          let start_date = moment(status_date);
          const days_in_status = current_date.diff(start_date, "days");
          return (
            <span>
              {days_in_status > 5 ? (
                <Tag color={"red"} style={{ fontSize: "16px" }}>
                  {days_in_status}
                </Tag>
              ) : (
                <Tag color={"green"} style={{ fontSize: "16px" }}>
                  {days_in_status}
                </Tag>
              )}
            </span>
          );
        },
        key: "status_timeline",
      },
      {
        title: "Created Date",
        dataIndex: "created",
        sorter: (a, b, sortOrder) => new Date(b.created) - new Date(a.created),
        defaultSortOrder: "ascend",
        render: (date, row, index) => (
          <span>{moment(date).format("MMMM Do YYYY")}</span>
        ),
        key: "created",
      },
      {
        title: "Treatment",
        dataIndex: "treatment",
        key: "treatment",
        render: (treatment, row, index) => {
          var stringToColour = function (str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
              hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            var colour = "#";
            for (var i = 0; i < 3; i++) {
              var value = (hash >> (i * 8)) & 0xff;
              colour += ("00" + value.toString(16)).substr(-2);
            }
            return colour;
          };
          let manufacturing = row.manufacturing;
          let alignment = row.alignment;
          if (!manufacturing) manufacturing = "In Office";
          if (!alignment) alignment = "In Office";
          let custom_treatment = row.custom_treatment;
          // if (!tag_color) tag_color = 'purple';
          return (
            <span>
              {alignment === "Technician" ? (
                <Tag
                  color={tag_color[alignment]}
                  key={alignment + "_alignment"}
                  style={{ fontSize: "16px" }}
                >
                  {alignment + " Setup"}
                </Tag>
              ) : (
                <Tag
                  color={tag_color[alignment]}
                  key={alignment + "_alignment"}
                  style={{ fontSize: "16px" }}
                >
                  {alignment + " Alignment"}
                </Tag>
              )}
              <Tag
                color={tag_color[manufacturing]}
                key={manufacturing + "_manufacturing"}
                style={{ fontSize: "16px" }}
              >
                {manufacturing + " Manufacturing"}
              </Tag>
              {custom_treatment && custom_treatment.length
                ? custom_treatment.map((tag) => (
                    <Tag
                      color={stringToColour(tag)}
                      key={"custom_treatment_" + tag}
                      style={{ fontSize: "16px" }}
                    >
                      {tag}
                    </Tag>
                  ))
                : null}
            </span>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        filters: [
          { text: "On Hold", value: "On Hold" },
          { text: "Unprocessed", value: "Unprocessed" },
          { text: "Planning", value: "Planning" },
          { text: "Needs Review", value: "Needs Review" },
          { text: "Accepted", value: "Accepted" },
          { text: "In Production", value: "In Production" },
          { text: "In Treatment", value: "In Treatment" },
          { text: "Archived", value: "Archived" },
          { text: "All Patients", value: "All Patients" },
        ],
        onFilter: (value, record) => {
          if (search) return true;
          if (value === "Unprocessed" && !record.status) return true;
          if (value === "All Patients") return true;
          return record.status && record.status.includes(value);
        },
        filteredValue: (status && [status]) || [],
        filterDropdownVisible: false,
        render: (tag) => {
          if (!tag) tag = "Unprocessed";
          let tag_color = { Shipping: "blue", Review: "red" }[tag];
          if (!tag_color) tag_color = "purple";
          return (
            <Tag color={tag_color} key={tag} style={{ fontSize: "16px" }}>
              {tag}
            </Tag>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={filtered_patients}
        onRow={(record) => ({
          onClick: () =>
            this.props.history.push("/patients/" + hashCode(record)),
        })}
        pagination={{
          pageSize: "10000",
          current: currentPage,
          onChange: handlePageChange,
          showSizeChanger: false,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
        }}
        onChange={this.handleChange}
        ref={this.table}
        className="patient-table"
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onChangeRow,
          onSelect: onSelectRow,
          hideDefaultSelections: true,
        }}
        rowKey="patientid"
      />
    );
  }
}

export default withRouter(PatientTable);

export const tag_color = {
  "In Office": "blue",
  ArchForm: "salmon",
  Technician: "red",
  Orthodontist: "magenta",
  "Simple 5": "green",
  "Simple 7": "orange",
  "Simple 10": "yellow",
  "Simple 14": "cyan",
  Complete: "purple",
};

export const stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};
