import React, { useEffect, useState, useRef } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import { FileReader } from "../file-reader";
import { withRouter } from "react-router-dom";
import { getPatientAvatar } from "../../Patients";
import { hashCode } from "../../Patients";
import { Link } from "react-router-dom";
import { useAppViewCtrl } from "../container/app-view-state";
import arrowup from "../images/arrow-right-up.svg";
import circleCheck from "../images/circle-check.svg";
import timeline from "../images/timeline.svg";
import dropdown from "../images/dropdown.svg";
import "./main.css";

const formatVersionDisplay = (folder) =>
  /[0-9]*_[0-9]*_[0-9]*/g.test(folder)
    ? "Setup version " + folder.split("_")[0] + "." + folder.split("_")[1]
    : folder;

const Navbar = (props) => {
  const [patientAnchorEl, setPatientAnchorEl] = useState(null);
  const [versionAnchorEl, setVersionAnchorEl] = useState(null);
  const [openPatientList, updateOpenPatientList] = useState(false);
  const [openVersionList, updateOpenVersionList] = useState(false);
  const patientRef = useRef(null);
  const versionRef = useRef(null);

  const profileUrl = hashCode({
    userid: props.userid,
    patientid: props.patientid,
  });
  const [folders, setFolders] = useState([]);

  const sharable_viewer = props.match.params.interface_type ? true : false;
  //   const [version, setVersion] = useState("1.0");

  const { isMobileView, setEditMode } = useAppViewCtrl();

  const setPatient = (value) => {
    props.history.push(
      `/threedview/${props.userid}/${props.patientid}/${value}/${props.name}`
    );
    window.location.reload();
  };

  const showMovementTable = () => {};

  const showPatients = () => {
    if (openPatientList) {
      updateOpenPatientList(false); // switch back from edit to view mode
      return;
    }
    updateOpenPatientList(true);
    setPatientAnchorEl(patientRef.current);
  };

  const showVersionList = () => {
    if (openVersionList) {
      updateOpenVersionList(false); // switch back from edit to view mode
      return;
    }
    updateOpenVersionList(true);
    setVersionAnchorEl(versionRef.current);
  };

  const editSetup = () => {
    setEditMode((prev) => !prev);
  };

  const setVersion = (targetVersion) => {
    props.history.push(
      `/threedview/${props.userid}/${props.patientid}/${targetVersion}/${props.name}`
    );
    window.location.reload();
  };

  useEffect(() => {
    var fileReader = new FileReader({
      userid: props.userid,
      patientid: props.patientid,
      loadPatientFiles: false,
    });

    fileReader.getFolders().then(() => {
      let folders = [...fileReader.folderList];
      folders.sort((a, b) => {
        if (a.split("_").length !== 3 || b.split("_").length !== 3) return 0;
        a = parseInt(a.split("_")[0]);
        b = parseInt(b.split("_")[0]);
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
      folders = folders.map((folder) => [folder, formatVersionDisplay(folder)]);
      setFolders(folders);
    });
  }, [props.patientid, props.userid]);

  const approve = () => {};
  return (
    <nav className={isMobileView ? "navbar_mobile" : "navbar"}>
      {!isMobileView && (
        <div
          style={{
            fontSize: 16,
            fontWeight: "500",
            height: 32,
            width: 155,
            padding: "7px 15px",
            cursor: "pointer",
            backgroundColor: "#f3f4f5",
            borderRadius: 4,
            display: "flex",
            visibility: "hidden",
          }}
          //   onClick={showMovementTable}
        >
          <img src={timeline} alt="sdf" style={{ width: 20 }} />
          <div style={{ fontSize: 12, margin: "auto", color: "#8A8F98" }}>
            Movement table
          </div>
        </div>
      )}

      <div
        className="avatar-wrapper"
        style={{
          width: 170,
          height: 32,
          textOverflow: "none",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%)",
          fontSize: 16,
          fontWeight: "500",
          color: "#1B1C1E",
          cursor: "pointer",
        }}
        ref={patientRef}
      >
        {sharable_viewer ? (
          <span className="patient-profile-link">{props.name}</span>
        ) : (
          <Link className="patient-profile-link" to={`/patients/${profileUrl}`}>
            {window.atob(props.name)}
          </Link>
        )}
        {/* <img src={dropdown} alt="sdf" onClick={showPatients} /> */}
      </div>
      <Popper
        open={openPatientList}
        anchorEl={patientAnchorEl}
        placement="bottom"
        style={{ zIndex: 2 }}
      >
        <div
          style={{
            overflowY: "auto",
            backgroundColor: "white",
            borderRadius: "2px",
          }}
        >
          {/* <MenuList autoFocusItem={openPatientList} id="menu-list-grow">
            {folders.map((folder) => (
              <MenuItem key={folder} onClick={() => setPatient(folder)}>
                {folder}
              </MenuItem>
            ))}
            {folders.length === 0 && (
              <MenuItem>There are no other patients</MenuItem>
            )}
            <MenuItem />
          </MenuList> */}
        </div>
      </Popper>
      {!isMobileView && (
        <div
          style={{
            display: "flex",
            display: sharable_viewer ? "none" : "flex",
          }}
        >
          {/* <div
            style={{
              fontSize: 16,
              fontWeight: "500",
              height: 40,
              width: 100,
              padding: "7px 15px",
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderRadius: 4,
              display: "flex",
              marginLeft: 32,
            }}
            onClick={() => {}}
          >
            <div
              style={{ fontSize: "14px", margin: "auto", color: "#1B1C1E" }}
              onClick={editSetup}
            >
              Edit Setup
            </div>
          </div> */}
          <div
            style={{
              fontSize: 16,
              fontWeight: "500",
              height: 40,
              //   width: 100,
              padding: "7px 15px",
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderRadius: "4px 0 0 4px",
              display: "flex",
              marginRight: 2,
              marginLeft: 32,
            }}
            // onClick={approve}
          >
            {/* <img src={circleCheck} alt="sdf" style={{ width: 20 }} /> */}
            <div
              style={{
                fontSize: "14px",
                margin: "auto",
                color: "#1B1C1E",
              }}
            >
              {formatVersionDisplay(props.version)}
            </div>
          </div>
          <div
            style={{
              height: 40,
              width: 40,
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderRadius: "0 4px 4px 0",
              display: "flex",
            }}
            onClick={() => {}}
            ref={versionRef}
          >
            <img
              src={dropdown}
              alt="sdf"
              style={{ width: 20, margin: "auto" }}
              onClick={showVersionList}
            />
          </div>
          <Popper
            open={openVersionList}
            anchorEl={versionAnchorEl}
            placement="bottom-end"
            style={{ zIndex: 2 }}
          >
            <div
              style={{
                overflowY: "auto",
                backgroundColor: "white",
                borderRadius: "2px",
              }}
            >
              <MenuList autoFocusItem={openVersionList} id="menu-list-grow">
                {folders.map((version) => (
                  <MenuItem
                    key={version}
                    onClick={() => setVersion(version[0])}
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#1b1c1e",
                      fontFamily: "Inter",
                    }}
                  >
                    {version[1]}
                  </MenuItem>
                ))}
              </MenuList>
            </div>
          </Popper>
          {/* <div
            style={{
              fontSize: 16,
              fontWeight: "500",
              height: 40,
              width: 100,
              padding: "7px 15px",
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderRadius: 4,
              display: "flex",
              marginLeft: 32,
            }}
            onClick={() => {}}
          >
            <div style={{ fontSize: "14px", margin: "auto", color: "#1B1C1E" }}>
              Export
            </div>
            <img src={arrowup} alt="sdf" style={{ width: 20 }} />
          </div> */}
        </div>
      )}
    </nav>
  );
};
export default withRouter(Navbar);
