import React from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Layout,
  Card,
  Spin,
  Alert,
  message,
} from "antd";
import {Amplify} from "aws-amplify";

const { Content } = Layout;

export default Form.create({ name: "normal_login" })(
  class NormalLoginForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        emailSent: false,
      };
    }

    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
        if (err || this.state.loading) return;
        this.setState({ loading: true });
        if (!this.state.emailSent) {
          try {
            await Amplify.Auth.forgotPassword(values.email);
            this.setState({ loading: false, emailSent: true });
            message.success("Please check your email for a verification code");
          } catch (e) {
            if (
              e.code === "InvalidParameterException" ||
              e.code === "NotAuthorizedException" ||
              e.code === "UserNotFoundException"
            )
              message.error("Email is incorrect");
            else if (
              e.code === "LimitExceededException" ||
              e.code === "InternalErrorException" ||
              e.code === "TooManyRequestsException"
            )
              message.error(
                "Too many requests; please wait before trying again"
              );
            else
              message.error(
                "There was a problem resetting your password, please try again later"
              );
            this.setState({ loading: false });
          }
        } else {
          try {
            await Amplify.Auth.forgotPasswordSubmit(
              values.email,
              values.code,
              values.password
            );
            this.setState({ loading: false });
            message.success("Password has been reset");
            this.props.history.push("/");
          } catch (e) {
            if (
              e.code === "CodeMismatchException" ||
              e.code === "ExpiredCodeException"
            )
              message.error("Verification code is incorrect or has expired");
            else if (
              e.code === "LimitExceededException" ||
              e.code === "InternalErrorException" ||
              e.code === "TooManyFailedAttemptsException" ||
              e.code === "TooManyRequestsException"
            )
              message.error(
                "Too many requests; please wait before trying again"
              );
            else if (
              e.code === "UserNotFoundException" ||
              e.code === "NotAuthorizedException"
            )
              message.error("Email is incorrect");
            else if (e.code === "InvalidParameterException")
              message.error(e.message);
            else
              message.error(
                "There was a problem resetting your password, please try again later"
              );
            this.setState({ loading: false });
          }
        }
      });
    };

    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        <Layout style={{ height: "100%" }}>
          <Content>
            <Card
              title={
                <div
                  className="logo"
                  style={{ lineHeight: "42px", background: "#fff" }}
                />
              }
              className="login-form"
            >
              <Spin tip="Logging in" spinning={this.state.loading}>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Item>
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Please input your email!" },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Email"
                      />
                    )}
                  </Form.Item>
                  {this.state.emailSent && (
                    <Form.Item>
                      {getFieldDecorator("code", {
                        rules: [
                          {
                            required: true,
                            message:
                              "Please input the verification code in the email!",
                          },
                        ],
                      })(
                        <Input
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          type="number"
                          placeholder="Code"
                        />
                      )}
                    </Form.Item>
                  )}
                  {this.state.emailSent && (
                    <Form.Item>
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                          {
                            min: 6,
                            message: "Password must be minimum 6 characters.",
                          },
                        ],
                      })(
                        <Input
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      )}
                    </Form.Item>
                  )}
                  <Form.Item style={{ marginBottom: "0px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Reset Password
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>
            </Card>
          </Content>
        </Layout>
      );
    }
  }
);
