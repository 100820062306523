import { useState } from "react";
import { createContainer } from "unstated-next";

const useAppViewState = () => {
  const [toothClicked, setToothClicked] = useState(false);
  const [movements, setMovements] = useState({});
  const [isFirstStepLoaded, setIsFirstStepLoaded] = useState(false);
  const [isFullLoaded, setIsFullLoaded] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [totalStep, setTotalStep] = useState(0);
  const [readyState, setReadyState] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [sliderValue, setSliderValue] = useState(1);
  const [overlayStep, setOverlayStep] = useState(1);
  const [gridVisible, setGridVisible] = useState(false);
  const [iprVisible, setIprVisible] = useState(true);
  const [collisionVisible, setCollisionVisible] = useState(true);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [views, setViews] = useState(2);
  const [isMobileView, setIsMobileView] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [curvesVisible, setCurvesVisible] = useState(false);
  const [cutoutsVisible, setCutoutsVisible] = useState(false);
  const [toothnumberVisible, setToothnumberVisible] = useState(false);
  const [axesVisible, setAxesVisible] = useState(false);
  /*
  1: upper
  2: front
  3: back
  4: lower
  5: right
  6: left
  */

  return {
    toothClicked,
    setToothClicked,
    movements,
    setMovements,
    isFirstStepLoaded,
    setIsFirstStepLoaded,
    isFullLoaded,
    setIsFullLoaded,
    currentStep,
    setCurrentStep,
    totalStep,
    setTotalStep,
    readyState,
    setReadyState,
    playAnimation,
    setPlayAnimation,
    sliderValue,
    setSliderValue,
    overlayStep,
    setOverlayStep,
    gridVisible,
    setGridVisible,
    iprVisible,
    setIprVisible,
    collisionVisible,
    setCollisionVisible,
    overlayVisible,
    setOverlayVisible,
    views,
    setViews,
    isMobileView,
    setIsMobileView,
    editMode,
    setEditMode,
    curvesVisible,
    setCurvesVisible,
    cutoutsVisible,
    setCutoutsVisible,
    toothnumberVisible,
    setToothnumberVisible,
    axesVisible,
    setAxesVisible,
  };
};

const appViewContainer = createContainer(useAppViewState);
export const AppViewProvider = appViewContainer.Provider;
export const useAppViewCtrl = () => {
  return appViewContainer.useContainer();
};
