import React from "react";
import "./Tag.css";

export function Tag(props) {
  let style = {};
  if (props.style) style = { ...props.style };
  if (props.size) {
    style.width = props.size;
    style.height = props.size;
  }
  if (props.background) {
    style.background = props.background;
  }

  let text_style = {};
  if (props.color) {
    text_style.color = props.color;
  }

  if (props.fontSize) {
    text_style.fontSize = props.fontSize;
  }

  return (
    <div className="tag" style={style}>
      <span style={text_style}>{props.children}</span>
    </div>
  );
}
