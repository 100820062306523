import {
  PerspectiveCamera,
  OrthographicCamera,
  WebGLRenderer,
  HemisphereLight,
  DirectionalLight,
  PointLight,
  SpotLight,
} from "three";
import CameraControls from "./camera-controls";

export const createPerspCamera = (width, height) => {
  return new PerspectiveCamera(30, width / height, 0.1, 1000);
};

export const createOrthoCamera = (width, height) => {
  return new OrthographicCamera(
    width / -2,
    width / 2,
    (height * 3) / 7,
    (height * 3) / -7,
    0.1,
    1000
  );
};

export const createRenderer = (width, height) => {
  const renderer = new WebGLRenderer({
    antialias: true,
    physicallyCorrectLights: true,
  });
  renderer.setSize(width, height);
  renderer.setClearColor(0xe2e7ed);
  renderer.domElement.style.outline = "none";
  renderer.domElement.style.width = "100%";
  renderer.domElement.style.height = "100%";
  return renderer;
};

export const createCameraControl = (
  camera,
  dom,
  dampingFactor,
  draggingDampingFactor,
  maxDistance,
  minDistance
) => {
  const cameraControls = new CameraControls(camera, dom);
  cameraControls.dampingFactor = dampingFactor;
  cameraControls.draggingDampingFactor = draggingDampingFactor;
  cameraControls.maxDistance = maxDistance;
  cameraControls.minDistance = minDistance;
  return cameraControls;
};

export const createHemiLight = () => {
  return new HemisphereLight(0xffffff, 0xffffff, 0.4);
};

export const createDirLight = () => {
  const dirLight = new DirectionalLight(0xffffff, 0.2);
  dirLight.castShadow = false;
  dirLight.position.set(0, -10, 0);
  return dirLight;
};

export const createPointLight = () => {
  const pointLight = new PointLight(0.5);
  pointLight.castShadow = false;
  return pointLight;
};

export const createSpotLight = () => {
  const spotlight = new SpotLight(0xffffff, 0.2);
  spotlight.castShadow = false;
  spotlight.shadow.bias = -0.0001;
  spotlight.shadow.mapSize.width = 1024 * 4;
  spotlight.shadow.mapSize.height = 1024 * 4;
  return spotlight;
};
