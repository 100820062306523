import React from "react";

export function Avatar(props) {
  let style = {};
  if (props.style) style = { ...props.style };
  if (props.size) {
    style.width = props.size;
    style.height = props.size;
  }
  if (props.background) {
    style.background = props.background;
  }

  return (
    <div className="avatar" style={style}>
      {props.icon && props.icon}
      {props.text ? (
        <span style={{ color: "white", fontSize: "10px" }}>{props.text}</span>
      ) : null}
    </div>
  );
}
