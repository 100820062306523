import React, { useState } from "react";
import {Amplify} from "aws-amplify";
import { Avatar } from "./Avatar";
import Icon from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import {
  getPatientNotifications,
  updatePatientNotification,
  updatePatientChat,
} from "./Patients";
import moment from "moment";
import { Input, notification, Spin, Dropdown, Menu, Switch } from "antd";
import { hashCode } from "./Patients";
import {
  Layers,
  ArrowRight,
  Archive,
  Plus,
  BookOpen,
  Folder,
  FilePlus,
  Paperclip,
} from "react-feather";
import { Button } from "./Button";
import { status_color_map } from "./Sidebar";
import "./Inbox.css";

const PatientLink = ({ notification }) => {
  return (
    <Link
      to={
        "/patients/" +
        hashCode({
          userid: notification.patient_userid,
          patientid: notification.patientid,
        })
      }
    >
      <span className="dark">{notification.patient_name}</span>
    </Link>
  );
};

const StatusNotificationContentHeader = ({ notification }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Avatar
      size={24}
      background="#A6ABAE"
      icon={<Layers color="white" size={12} />}
      style={{ marginRight: "8px" }}
    />
    <span>
      Status change on <PatientLink notification={notification} />
    </span>
  </div>
);

const NoteNotificationContentHeader = ({ notification }) => {
  const username = notification.change.username;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Avatar
        size={24}
        background="#A6ABAE"
        style={{ marginRight: "8px" }}
        text={
          username.split(" ").length > 1
            ? username.split(" ")[0][0] + username.split(" ")[1][0]
            : username[0]
        }
      />
      <span>
        <PatientLink notification={notification} /> was commented on by{" "}
        <span className="dark">{username}</span>
      </span>
    </div>
  );
};

const FileNotificationContentHeader = ({ notification }) => {
  let file_type = notification.change.file_type;
  if (file_type === "models") file_type = "scans";
  else if (file_type === "refinements") file_type = "refinement scans";
  else if (file_type === "xray") file_type = "X-rays";
  else if (file_type === "composite") file_type = "photos";
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Avatar
        size={24}
        background="#A6ABAE"
        icon={<FilePlus color="white" size={12} />}
        style={{ marginRight: "8px" }}
      />
      <span>
        New {file_type} were uploaded for{" "}
        <PatientLink notification={notification} />
      </span>
    </div>
  );
};

const NotificationContentHeader = ({ notification }) => (
  <div className="notification-content-header">
    {notification.change.type === "Status" && (
      <StatusNotificationContentHeader notification={notification} />
    )}
    {notification.change.type === "Note" && (
      <NoteNotificationContentHeader notification={notification} />
    )}
    {notification.change.type === "File" && (
      <FileNotificationContentHeader notification={notification} />
    )}
    <div>
      <p className="notification-timestamp">
        {moment(notification.timestamp).format("h:mm a [on] L")}
      </p>
    </div>
  </div>
);

const FileNotificationContent = ({ notification }) => (
  <div className="file-notification-content">
    <p>{notification.change.file_name}</p>
  </div>
);

const StatusNotificationContent = ({ notification }) => (
  <div className="status-notification-content">
    <div
      className="status-name-notification-box"
      style={{ background: status_color_map[notification.change.old_status] }}
    >
      <p style={{ margin: "0 !important" }}>{notification.change.old_status}</p>
    </div>
    <ArrowRight
      style={{ margin: "0px 10px 0px 10px" }}
      size={12}
      color="#A6ABAE"
    />
    <div
      className="status-name-notification-box"
      style={{ background: status_color_map[notification.change.status] }}
    >
      <p style={{ margin: "0 !important" }}>{notification.change.status}</p>
    </div>
  </div>
);

const NoteNotificationContent = ({ notification, archiveNotification }) => {
  const msg = {
    userid: notification.userid,
    username: Amplify.Cache.getItem("user").name,
    patientid: notification.patientid,
    patient_userid: notification.patient_userid,
  };
  const [message, setMessage] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const fileUploadRef = React.createRef();

  const createChatMessage = async () => {
    archiveNotification(notification);
    msg.date = new Date().toISOString();
    msg.message = message;
    await updatePatientChat(msg);
    for (const file of uploadFiles) {
      const res = await Amplify.Storage.put(
        `${notification.patientid}/notes/${msg.date}/${file.name}`,
        file,
        {
          level: "protected",
          identityId: notification.userid,
          bucket: "archform-patient-data-v1",
          contentType: file.type,
        }
      );
    }
  };
  return (
    <div>
      <div className="note-notification-content">
        <p>{notification.change.message}</p>
      </div>
      <div
        className=" note-notification-reply-content"
        style={{ marginTop: "24px" }}
      >
        <Input.TextArea
          autosize={{ minRows: 4 }}
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
          placeholder="Type your message . . ."
        />
        <div className="note-notification-reply-actions">
          <label
            style={{ cursor: "pointer" }}
            htmlFor={"file-upload-" + notification.notificationid}
          >
            <Paperclip size={12} color="#A6ABAE" />
            {uploadFiles.map((file) => (
              <span style={{ margin: "0px  8px 0px 8px" }}>{file.name}</span>
            ))}
            <input
              multiple
              style={{ display: "none" }}
              id={"file-upload-" + notification.notificationid}
              type="file"
              onChange={() => setUploadFiles([...fileUploadRef.current.files])}
              ref={fileUploadRef}
            />
          </label>
          <Button onClick={createChatMessage} size="small">
            <p>Comment</p>
          </Button>
          {/* <button onClick={createChatMessage} className='comment-button-small'> */}
          {/* </button> */}
        </div>
      </div>
    </div>
  );
};

const NotificationContent = ({ notification, archiveNotification }) => (
  <div className="notification-content">
    <NotificationContentHeader notification={notification} />
    <div className="notification-inner-content">
      {notification.change.type === "Status" && (
        <StatusNotificationContent notification={notification} />
      )}
      {notification.change.type === "Note" && (
        <NoteNotificationContent
          notification={notification}
          archiveNotification={archiveNotification}
        />
      )}
      {notification.change.type === "File" && (
        <FileNotificationContent notification={notification} />
      )}
    </div>
    <div className="line-break" />
  </div>
);

const NotificationHeader = ({ title }) => (
  <div className="notification-container">
    <div className="notification-content">
      <p className="notification-header-title">{title}</p>
      <div className="line-break" />
    </div>
    <div className="notification-actions" />
  </div>
);

const Notification = ({ notification, archiveNotification }) => (
  <div className="notification-container">
    <NotificationContent
      notification={notification}
      archiveNotification={archiveNotification}
    />
    <div className="notification-actions">
      {/* {notification.change.type === 'Note' ?
                <button className='comment-button'>
                    <Folder size={12} color='#46454D' />
                    <p>View Patient</p>
                </button>
                : null} */}
      {/* {notification.change.type === 'Note' ?
                <button className='comment-button'>
                    <BookOpen size={12} color='#46454D' />
                    <p>Open in ArchForm</p>
                </button>
                : null} */}
      <Button onClick={(e) => archiveNotification(notification)}>
        <Archive size={12} color="#46454D" />
        <p>Mark as done</p>
      </Button>
      {/* <button onClick={(e) => archiveNotification(notification)} className='comment-button'>
                <Archive size={12} color='#46454D' />
                <p>Mark as done</p>
            </button> */}
    </div>
  </div>
);

export class Inbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications_loaded: false,
      notifications: props.notifications || [],
      filtered_notifications: new Set(),
      filter: "All",
      search: "",
      dropdown_filter_visible: false,
      search_filters: ["File", "Note", "Status"],
    };

    this.archiveNotification = this.archiveNotification.bind(this);
    this.archiveAllNotifications = this.archiveAllNotifications.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.getFilterButtonClassNames = this.getFilterButtonClassNames.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.setSearchFilter = this.setSearchFilter.bind(this);
    this.updateDashboard = props.updateDashboard;
  }

  async componentDidMount() {
    this.processNotifications();
    this.setState({ notifications_loaded: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.notifications.length !== prevProps.notifications.length) {
      this.setState(
        {
          notifications_loaded: false,
          notifications: this.props.notifications,
        },
        () => {
          this.processNotifications();
          this.setState({ notifications_loaded: true });
        }
      );
    }
  }

  processNotifications() {
    const filtered_notifications = new Set();
    const search = this.state.search.toLowerCase().replace(/ /g, "");
    const search_filters = this.state.search_filters;
    for (const notification of this.state.notifications) {
      if (search) {
        if (!search_filters.includes(notification.change.type)) continue;
        let found = false;
        if (`${notification.patient_name}`.toLowerCase().includes(search))
          found = true;
        if (
          `${notification.patient_name
            .replace(",", "")
            .split(" ")
            .reverse()
            .join("")}`
            .toLowerCase()
            .includes(search)
        )
          found = true;
        if (
          moment(notification.timestamp)
            .format("MMMM Do YYYY")
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search)
        )
          found = true;
        if (
          notification.change.type
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search)
        )
          found = true;
        if (
          notification.change.type === "Note" &&
          notification.change.username
            .toLowerCase()
            .replace(/ /g, "")
            .includes(search)
        )
          found = true;
        if (!found) continue;
      }
      filtered_notifications.add(notification.notificationid);
    }
    this.setState({ filtered_notifications });
  }

  async archiveNotification(notification) {
    const filtered_notifications = new Set(this.state.filtered_notifications);
    filtered_notifications.delete(notification.notificationid);
    const notifications = this.state.notifications.filter(
      (nn) => nn.notificationid !== notification.notificationid
    );
    this.setState({
      notifications,
      filtered_notifications,
    });
    this.updateDashboard(notifications);
    await updatePatientNotification(notification);
  }

  async archiveAllNotifications() {
    this.setState({ notifications_loaded: false });
    for (const notification of this.state.notifications)
      await updatePatientNotification(notification);
    this.updateDashboard([]);
    this.setState({
      notifications: [],
      filtered_notifications: new Set(),
      notifications_loaded: true,
    });
  }

  setFilter(filter) {
    if (filter === this.state.filter) return;
    this.setState({ filter });
  }

  getFilterButtonClassNames(filter) {
    return (
      (this.state.filter === filter
        ? "notifications-header-filter-link-active "
        : "") + "notifications-header-filter-link"
    );
  }

  setSearch(evt) {
    const search = evt.target.value;
    this.setState({ search }, () => this.processNotifications());
  }

  setSearchFilter(filter) {
    let search_filters = this.state.search_filters;
    if (search_filters.includes(filter))
      search_filters = search_filters.filter((ff) => ff !== filter);
    else search_filters.push(filter);
    this.setState({ search_filters }, () => this.processNotifications());
  }

  render() {
    const today = moment();
    const yesterday = moment().subtract(1, "days");
    let last_notification_date = moment(null);
    return (
      <Spin spinning={!this.state.notifications_loaded}>
        <div id="notifications-outer-container">
          <div id="notifications-navbar">
            <p
              onClick={() => this.setFilter("All")}
              className={this.getFilterButtonClassNames("All")}
            >
              All
            </p>
            <p
              onClick={() => this.setFilter("File")}
              className={this.getFilterButtonClassNames("File")}
            >
              Upload
            </p>
            <p
              onClick={() => this.setFilter("Note")}
              className={this.getFilterButtonClassNames("Note")}
            >
              Comment
            </p>
            <p
              onClick={() => this.setFilter("Status")}
              className={this.getFilterButtonClassNames("Status")}
            >
              Status
            </p>
            <Input.Search
              placeholder="Search"
              onChange={this.setSearch}
              value={this.state.search}
              className="inbox-search"
            />
            <Button
              size="medium"
              dropdown={
                <div>
                  <p style={{ marginTop: "8px" }}>Filter Options</p>
                  <div className="line-break" />
                  <label htmlFor="Note">
                    <input
                      onChange={(e) => this.setSearchFilter("Note")}
                      checked={this.state.search_filters.includes("Note")}
                      type="checkbox"
                      name="Note"
                    />
                    Comments
                  </label>
                  <label htmlFor="Status">
                    <input
                      onChange={(e) => this.setSearchFilter("Status")}
                      checked={this.state.search_filters.includes("Status")}
                      type="checkbox"
                      name="Status"
                    />
                    Status Changes
                  </label>
                  <label htmlFor="File">
                    <input
                      onChange={(e) => this.setSearchFilter("File")}
                      checked={this.state.search_filters.includes("File")}
                      type="checkbox"
                      name="File"
                    />
                    File Uploads
                  </label>
                  <br style={{ marginTop: "30px" }} />
                </div>
              }
            >
              <Plus size={12} color="#46454D" />
              <p>Filter</p>
            </Button>
            {/* <div className='comment-button-dropdown'>
                            <button className='comment-button-medium'>
                            </button>
                            <div className='comment-button-dropdown-list'>
                                <p style={{ marginTop: '8px' }}>Filter Options</p>
                                <div className='line-break' />
                                <label htmlFor="Note">
                                    <input
                                        onChange={e => this.setSearchFilter('Note')}
                                        checked={this.state.search_filters.includes('Note')}
                                        type="checkbox"
                                        name="Note" />
                                    Comments
                                </label>
                                <label htmlFor="Status">
                                    <input
                                        onChange={e => this.setSearchFilter('Status')}
                                        checked={this.state.search_filters.includes('Status')}
                                        type="checkbox"
                                        name="Status" />
                                    Status Changes
                                </label>
                                <label htmlFor="File">
                                    <input
                                        onChange={e => this.setSearchFilter('File')}
                                        checked={this.state.search_filters.includes('File')}
                                        type="checkbox"
                                        name="File" />
                                    File Uploads
                                </label>
                                <br style={{ marginTop: '30px' }} />
                            </div>
                        </div> */}
            <Button onClick={(e) => this.archiveAllNotifications()}>
              <Archive size={12} color="#46454D" />
              <p>Mark all as done</p>
            </Button>
            {/* <button onClick={(e) => this.archiveAllNotifications()} className='comment-button'>
                        </button> */}
          </div>
          <div className="line-break" />

          <div id="notifications-container" style={{ marginTop: "24px" }}>
            <div style={{ height: "16px" }} />

            {this.state.notifications.length
              ? this.state.notifications
                  .filter(
                    (notification) =>
                      (this.state.filter === "All" ||
                        notification.change.type === this.state.filter) &&
                      this.state.filtered_notifications.has(
                        notification.notificationid
                      )
                  )
                  .map((notification) => {
                    const notification_date = moment(notification.timestamp);
                    let date_header;
                    if (
                      notification_date.isSame(today, "d") &&
                      !notification_date.isSame(last_notification_date, "d")
                    ) {
                      date_header = <NotificationHeader title="Today" />;
                    } else if (
                      notification_date.isSame(yesterday, "d") &&
                      !notification_date.isSame(last_notification_date, "d")
                    ) {
                      date_header = <NotificationHeader title="Yesterday" />;
                    } else if (
                      !notification_date.isSame(last_notification_date, "d")
                    ) {
                      date_header = (
                        <NotificationHeader
                          title={notification_date.format("MMMM Do, YYYY")}
                        />
                      );
                    }
                    last_notification_date = notification_date;
                    return (
                      <React.Fragment key={notification.notificationid}>
                        {date_header}
                        <Notification
                          notification={notification}
                          archiveNotification={this.archiveNotification}
                        />
                      </React.Fragment>
                    );
                  })
              : null}
            <div style={{ height: "32px" }} />
            <div id="notifications-shadow" />
          </div>
        </div>
      </Spin>
    );
  }
}
