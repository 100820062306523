import React from "react";
import { Amplify } from "aws-amplify";
import { Radio, Spin, Checkbox, message, Button } from "antd";
import { Loader } from "@googlemaps/js-api-loader";
import { TeethTable } from "./PatientView";
import {
  getUser,
  updateUser,
  getBillingPortal,
  getOrderHistory,
  getBillingAccount,
  getCheckout,
  archform_plans,
  getDefaultSource,
  getSubscriptions,
} from "./User";
import { clear_values } from "./db";
import AddressView from "./AddressView";
import "./SettingsPage.css";
import {
  withRouter,
} from "react-router-dom";

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      oldPass: "",
      newPass: "",
      updatingPass: false,
      address: {
        recipient: "",
        street_address: "",
        street_address2: "",
        locality: "",
        region: "",
        postal_code: "",
        country: "",
      },
      autocomplete: null,
      editAddress: false,
      updatingUser: false,
      billing_cycle: "month",
    };

    getUser().then((user) => this.setState({ user }));
    this.initAutocomplete = this.initAutocomplete.bind(this);
    this.fillInAddress = this.fillInAddress.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.submitAddress = this.submitAddress.bind(this);
    this.handleStripeCheckout = this.handleStripeCheckout.bind(this);
    this.updateUserAttribute = this.updateUserAttribute.bind(this);

    this.address1ref = React.createRef();
    this.address2ref = React.createRef();
    this.postcoderef = React.createRef();

    getBillingAccount().then((billing) => {
      this.setState({ billing });
      if (!billing || !billing.subscriptions.total_count)
        this.setState({ hasSubscription: false });
      else this.setState({ hasSubscription: true });
      getDefaultSource().then((defaultSource) =>
        this.setState({ defaultSource })
      );
    });
    getOrderHistory().then((orders) => {});
  }

  componentDidMount() {
    getUser().then((user) => {
      this.setState({ user });
      try {
        const address = JSON.parse(user.attributes.address);
        this.setState({ address });
      } catch (e) {}
    });
  }

  initAutocomplete() {
    this.address1ref.current.value = this.state.address.street_address;
    const loader = new Loader({
      apiKey: "AIzaSyCz0NeKi1sQIatSQmNVye62Lz8J4XX8ocU",
      version: "weekly",
      libraries: ["places"],
    });
    loader
      .load()
      .then((google) => {
        const autocomplete = new google.maps.places.Autocomplete(
          this.address1ref.current,
          {
            componentRestrictions: { country: ["us", "ca"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
          }
        );
        this.setState({ autocomplete });
        autocomplete.addListener("place_changed", this.fillInAddress);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  fillInAddress() {
    const place = this.state.autocomplete.getPlace();
    let address1 = "";
    const address = { ...this.state.address, postal_code: "" };
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          address.street_address = address1;
          break;
        }
        case "route": {
          address1 += component.short_name;
          address.street_address = address1;
          break;
        }
        case "postal_code": {
          if (address.postal_code.includes("-")) {
            address.postal_code = `${component.long_name}${address.postal_code}`;
          } else {
            address.postal_code = component.long_name;
          }
          break;
        }
        case "postal_code_suffix": {
          address.postal_code = `${address.postal_code}-${component.long_name}`;
          break;
        }
        case "locality": {
          address.locality = component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          address.region = component.short_name;
          break;
        }
        case "country": {
          address.country = component.long_name;
          break;
        }
        default: {
          break;
        }
      }
    }
    this.setState({ address });
    this.address1ref.current.value = address1;
  }

  updateAddress = (field) => (evt) => {
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        [field]: evt.target.value,
      },
    }));
  };

  submitAddress() {
    const address = { ...this.state.address };

    updateUser({ address: JSON.stringify(address) });
    this.setState({ editAddress: false });
  }

  handleStripeCheckout = (productId) => async () => {
    this.setState({ updatingUser: true });
    const checkout_page = await getCheckout(productId);
    if (checkout_page) {
      this.setState({ updatingUser: true });
      window.location = checkout_page;
    }
  };

  handleSetNewPassword = async () => {
    const oldPass = this.state.oldPass;
    const newPass = this.state.newPass;
    if (newPass.length < 6) {
      this.setState({ newPass: "" });
      return message.error("New password must be at least 6 characters long");
    }
    this.setState({ updatingPass: true })
    try {
      const user = await getUser();
      await Amplify.Auth.changePassword(user, oldPass, newPass);
      this.setState({ oldPass: "", newPass: "" });
      message.success("Your password has been changed successfully");
      this.setState({ updatingPass: false })
      // logout
      clear_values();
      Amplify.Cache.clear();
      Amplify.Auth.signOut();
      this.props.history.push("/login");
    } catch (e) {
      if (
        e.code === "InvalidParameterException" ||
        e.code === "NotAuthorizedException" ||
        e.code === "InvalidPasswordException"
      )
        message.error("Current password is incorrect");
      else if (
        e.code === "LimitExceededException" ||
        e.code === "InternalErrorException" ||
        e.code === "TooManyRequestsException"
      )
        message.error("Too many requests; please wait before trying again");
      else
        message.error(
          "There was a problem changing your password, please try again later"
        );
      this.setState({ updatingPass: false })
    }
  };

  async updateUserAttribute(attribute) {
    this.setState({ updatingUser: true });
    await updateUser(attribute);
    const user = await getUser();
    this.setState({ user, updatingUser: false });
  }

  render() {
    let tooth_num_system = "UNS";
    const { user, billing, defaultSource } = this.state;
    // const user = this.state.user
    if (user && user.attributes["custom:tooth_num_system"])
      tooth_num_system = user.attributes["custom:tooth_num_system"];
    let email_on_status = "False";
    if (user && user.attributes["custom:email_on_status"])
      email_on_status = user.attributes["custom:email_on_status"];
    let email_on_clinical = "False";
    if (user && user.attributes["custom:email_on_clinical"])
      email_on_clinical = user.attributes["custom:email_on_clinical"];
    let email_on_notes = "False";
    if (user && user.attributes["custom:email_on_notes"])
      email_on_notes = user.attributes["custom:email_on_notes"];
    // Default display notice: In order to adapt to the hidden checkbox on the page, set the value of display_notice to the opposite
    let display_notice = "False"
    if (user && user.attributes["custom:display_notice"])
      display_notice = user.attributes["custom:display_notice"];
    return (
      <Spin
        style={{ position: "fixed" }}
        spinning={!user || this.state.updatingUser}
      >
        <div className="settings-page-container">
          <div className="settings-layout-container">
            <p className="settings-title">Settings</p>

            <div className="settings-row" style={{ width: "400px" }}>
              <p style={{ marginBottom: "1em" }}>
                You're currently logged in as{" "}
                {user && user.attributes && user.attributes.name}
              </p>
            </div>
            <div className="settings-row" style={{ width: "400px" }}>
              <AddressView />
            </div>
            {defaultSource ? (
              <>
                <div className="settings-row" style={{ width: "400px" }}>
                  <p className="settings-subtitle">Payment Method</p>
                  <p>
                    {`${defaultSource.brand} ending in 
                    ${
                      defaultSource.dynamic_last4
                        ? defaultSource.dynamic_last4
                        : defaultSource.last4
                    }, 
                    expires ${defaultSource.exp_month}/${
                      defaultSource.exp_year
                    }`}
                  </p>
                  <button
                    className="logout-button"
                    onClick={(async (e) => {
                      this.setState({ updatingUser: true });
                      const billing_portal = await getBillingPortal();
                      this.setState({ patientsLoaded: false });
                      window.location = billing_portal + "/payment-methods";
                    }).bind(this)}
                  >
                    Add New Card
                  </button>
                </div>
              </>
            ) : null}

            {/* <div className="settings-row" style={{ width: '400px' }}>
              <p className="settings-subtitle">Billing</p>
            </div> */}
            {
              //billing && billing.subscriptions && billing.subscriptions.data.length === 0 ?
              // <>
              //   <div className="settings-row subscription-info" style={{ width: '400px' }}>
              //     <p>You're don't currently have any active software plans.</p>
              //     <p>You can learn more about them at <a target='blank' href='https://www.archform.co/pricing'>archform.com</a></p>
              //     <p>All plans start with a 30 day free trial.</p>
              //     <p>Please <a href='mailto:info@archform.co?subject=Enterprise%20Quote%20Request'>contact us </a>
              //       if you export more than 35 patients a month</p>
              //   </div>
              //   <Radio.Group
              //     onChange={e => this.setState({ billing_cycle: e.target.value })}
              //     defaultValue={this.state.billing_cycle}>
              //     <Radio.Button value={'month'}>Bill Monthly</Radio.Button>
              //     <Radio.Button value={'year'}>Bill Yearly</Radio.Button>
              //   </Radio.Group>
              //   <p style={{ marginTop: '20px' }}>Software Subscription</p>
              //   <div className="settings-row product-selection-container">
              //     <div className="product-info-container">
              //       <div className="product-title-container">
              //         <p className="product-title-name">Pay As You Go</p>
              //         {/* <p className="production-selection-description">${`${archform_plans.Patient[this.state.billing_cycle].price}/mo`}</p> */}
              //       </div>
              //       <div className='product-benefits'>
              //         <p>$50 Per Case</p>
              //       </div>
              //       <div
              //         className="product-expand-button"
              //         onClick={this.handleStripeCheckout(archform_plans.Patient[this.state.billing_cycle].stripeid)}
              //       >
              //         <p>Subscribe</p>
              //       </div>
              //     </div>
              //     <div className="product-info-container">
              //       <div className="product-title-container">
              //         <p className="product-title-name">Basic</p>
              //         <p className="production-selection-description">${`${archform_plans.Basic[this.state.billing_cycle].price}/mo`}</p>
              //       </div>
              //       <div className='product-benefits'>
              //         <p>5 Cases</p>
              //       </div>
              //       <div
              //         className="product-expand-button"
              //         onClick={this.handleStripeCheckout(archform_plans.Basic[this.state.billing_cycle].stripeid)}
              //       >
              //         <p>Subscribe</p>
              //       </div>
              //     </div>
              //     <div className="product-info-container">
              //       <div className="product-title-container">
              //         <p className="product-title-name">Premium</p>
              //         <p className="production-selection-description">${`${archform_plans.Premium[this.state.billing_cycle].price}/mo`}</p>
              //       </div>
              //       <div className='product-benefits'>
              //         <p>10 Cases</p>
              //       </div>
              //       <div
              //         className="product-expand-button"
              //         onClick={this.handleStripeCheckout(archform_plans.Premium[this.state.billing_cycle].stripeid)}
              //       >
              //         <p>Subscribe</p>
              //       </div>
              //     </div>
              //     <div className="product-info-container">
              //       <div className="product-title-container">
              //         <p className="product-title-name">Pro</p>
              //         <p className="production-selection-description">${`${archform_plans.Pro[this.state.billing_cycle].price}/mo`}</p>
              //       </div>
              //       <div className='product-benefits'>
              //         <p>25 Cases</p>
              //       </div>
              //       <div
              //         className="product-expand-button"
              //         onClick={this.handleStripeCheckout(archform_plans.Pro[this.state.billing_cycle].stripeid)}
              //       >
              //         <p>Subscribe</p>
              //       </div>
              //     </div>
              //   </div>
              //   <p>Form Aligners and Setup Services</p>
              //   <div className="settings-row product-selection-container">
              //     <div className="product-info-container">
              //       <div className="product-title-container">
              //         <p className="product-title-name">Pay As You Go</p>
              //       </div>
              //       <div className='product-benefits'>
              //       </div>
              //       <div
              //         className="product-expand-button"
              //         onClick={this.handleStripeCheckout(archform_plans.Patient[this.state.billing_cycle].stripeid)}
              //       >
              //         <p>Subscribe</p>
              //       </div>
              //     </div>
              //   </div>
              // </>
              // :
              // billing && billing.subscriptions && billing.subscriptions.data.length > 0 ?
              //   <div className="settings-row" style={{ width: '400px' }}>
              //     <p>You can update your billing details and subscription plan here</p>
              //     <button className="logout-button"
              //       onClick={(async e => {
              //         this.setState({ updatingUser: true });
              //         const billing_portal = await getBillingPortal();
              //         this.setState({ patientsLoaded: false });
              //         window.location = billing_portal;
              //       }).bind(this)}>View Subscriptions
              //     </button>
              //     {/* <button className="logout-button"
              //       style={{ marginTop: '5px' }}
              //       onClick={(async e => {
              //         console.log(billing)
              //         if (billing && billing.subscriptions) {
              //           if (billing.subscriptions.data.length == 0) {
              //             const checkout_page = await getCheckout('price_1J0tQmIffkoglhLDen3rNdnt');
              //             console.log(checkout_page)
              //             if (checkout_page) window.location = checkout_page;
              //           }
              //         }
              //         // const billing_portal = await getBillingPortal();
              //         // window.location = billing_portal;
              //       }).bind(this)}>View Plans
              //     </button> */}
              //   </div>
              // :
              // null
            }

            <div className="settings-row">
              <p className="settings-subtitle">Tooth Numbering System</p>
              <Radio.Group
                style={{ marginBottom: "2em" }}
                onChange={async (e) => {
                  await this.updateUserAttribute({
                    "custom:tooth_num_system": e.target.value,
                  });
                }}
                value={tooth_num_system}
              >
                <Radio.Button value="UNS">UNS</Radio.Button>
                <Radio.Button value="ISO">ISO</Radio.Button>
                <Radio.Button value="Palmer">Palmer</Radio.Button>
              </Radio.Group>
              <TeethTable table={[]} system={tooth_num_system} />
            </div>

            <div className="settings-row">
              <p className="settings-subtitle">Notification settings</p>
              <p style={{ marginBottom: "1em" }}>
                Send emails on status change
              </p>
              <Radio.Group
                style={{ marginBottom: "2em" }}
                onChange={async (e) => {
                  await this.updateUserAttribute({
                    "custom:email_on_status": e.target.value,
                  });
                }}
                value={email_on_status}
              >
                <Radio.Button value={"False"}>No</Radio.Button>
                <Radio.Button value={"True"}>Yes</Radio.Button>
              </Radio.Group>
              <p style={{ marginBottom: "1em" }}>
                Send emails on clinical changes
              </p>
              <Radio.Group
                style={{ marginBottom: "2em" }}
                onChange={async (e) => {
                  await this.updateUserAttribute({
                    "custom:email_on_clinical": e.target.value,
                  });
                }}
                value={email_on_clinical}
              >
                <Radio.Button value={"False"}>No</Radio.Button>
                <Radio.Button value={"True"}>Yes</Radio.Button>
              </Radio.Group>
              <p style={{ marginBottom: "1em" }}>
                Send emails when notes added
              </p>
              <Radio.Group
                onChange={async (e) => {
                  await this.updateUserAttribute({
                    "custom:email_on_notes": e.target.value,
                  });
                }}
                value={email_on_notes}
              >
                <Radio.Button value={"False"}>No</Radio.Button>
                <Radio.Button value={"True"}>Yes</Radio.Button>
              </Radio.Group>
            </div>

            <div className="settings-row" style={{ width: "400px" }}>
              <p className="settings-subtitle">Change your password</p>
              <input
                className="form-field"
                onChange={(e) => this.setState({ oldPass: e.target.value })}
                value={this.state.oldPass}
                type="password"
                placeholder="Current password"
              />
              <input
                className="form-field"
                onChange={(e) => this.setState({ newPass: e.target.value })}
                value={this.state.newPass}
                type="password"
                placeholder="New password"
              />
              {this.state.updatingPass 
                ? <Button type="primary" loading className="change-password-loading-button">Loading</Button>
                : <button
                    className="change-password-submit-button"
                    onClick={this.handleSetNewPassword}
                  >
                    Change password
                  </button>
              }
            </div>

            <div className="settings-row" style={{ width: "400px" }}>
              <p className="settings-subtitle">Notice in Web Viewer</p>
              {/* In order to adapt to the hidden checkbox on the page, set the value of display_notice to the opposite */}
              <Checkbox 
                checked={display_notice === "True"} 
                onChange={async (e) => {
                  await this.updateUserAttribute({
                    "custom:display_notice": e.target.checked ? "True" : "False",
                  });
                }}
              >Hide notice when opening Web Viewer</Checkbox>
            </div>

            <div className="settings-row" style={{ width: "400px" }}>
              <p className="settings-subtitle">Account</p>
              <p style={{ marginBottom: "1em" }}>
                You're currently logged in as {user && user.attributes.name}
              </p>
              <button
                className="logout-button"
                onClick={((e) => {
                  clear_values();
                  Amplify.Cache.clear();
                  Amplify.Auth.signOut();
                  this.props.history.push("/login");
                }).bind(this)}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

export default withRouter(SettingsPage);
