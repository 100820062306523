import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import { CardContent, Divider } from "@material-ui/core";
import { FaArrowRight } from "react-icons/fa";

const styles = {
  root: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",
    padding: 0,
    width: "250px",
    height: "285px",
    transition: "all .2s ease",
    borderRadius: "10px 0px 0px 10px",
    boxShadow: "none !important",
  },
  xsRoot: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",
    padding: 0,
    width: "0px",
    height: "0px",
    transition: "all .2s ease",
  },
  simpleFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardContent: {
    padding: 16,
  },
  ul: {
    listStyleType: "none",
    paddingLeft: 0,
    paddingBottom: 0,
    marginBottom: 0,
    fontSize: "small",
    marginTop: "0px",
  },
  li: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "16px",
  },
  title: {
    fontSize: 13,
  },
  info: {
    fontWeight: "700",
    fontFamily: "sans-serif",
  },
  faCogs: {
    padding: "10px",
    margin: "auto 0",
    color: "#5f6266",
    fontSize: "20px",
    opacity: "1",
  },
  faCogsHover: {
    padding: "10px",
    margin: "auto 0",
    opacity: ".5",
    fontSize: "20px",
  },
};

const radian = 57.3;

class Movements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movements: [
        {
          title: "Move In/Out",
          info: "0.000",
        },
        {
          title: "Move Left/Right",
          info: "0.000",
        },
        {
          title: "Move Up/Down",
          info: "0.000",
        },
        {
          title: "Tilt In/Out",
          info: "0.000",
        },
        {
          title: "Tilt Left/Right",
          info: "0.000",
        },
        {
          title: "Twist",
          info: "0.000",
        },
      ],
      // platform: window.innerWidth >= 1024 ? 'lg' : window.innerWidth >= 992 ? 'md' : window.innerWidth >= 768 ? 'sm' : 'xs',
      // isVisible: window.innerWidth <= 768 ? false : true,
      isVisible: false,
      hover: false,
    };
  }

  componentDidUpdate(pProps) {
    if (
      pProps.ToothClicked !== this.props.ToothClicked ||
      pProps.Movements !== this.props.Movements
    ) {
      this.setState({
        ...this.state,
        isVisible: this.props.ToothClicked,
        movements: [
          {
            title: "Buccal/Lingual",
            info:
              this.props.Movements.position !== undefined
                ? this.props.Movements.position.x.toFixed(3)
                : "0.000",
          },
          {
            title: "Mesial/Distal",
            info:
              this.props.Movements.position !== undefined
                ? this.props.Movements.position.y.toFixed(3)
                : "0.000",
          },
          {
            title: "Intrusion/Extrusion",
            info:
              this.props.Movements.position !== undefined
                ? (-this.props.Movements.position.z).toFixed(3)
                : "0.000",
          },
          {
            title: "Tip",
            info:
              this.props.Movements.rotation !== undefined
                ? (-this.props.Movements.rotation.x * radian).toFixed(3)
                : "0.000",
          },
          {
            title: "Torque",
            info:
              this.props.Movements.rotation !== undefined
                ? (-this.props.Movements.rotation.y * radian).toFixed(3)
                : "0.000",
          },
          {
            title: "Rotation",
            info:
              this.props.Movements.rotation !== undefined
                ? (this.props.Movements.rotation.z * radian).toFixed(3)
                : "0.000",
          },
        ],
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.isVisible ? (
          <Card className={classes.root}>
            <CardContent className={classes.cardContent}>
              <div className={classes.simpleFlex}>
                <div
                  style={{ fontSize: 16, fontWeight: 500, marginBottom: 20 }}
                >
                  Movements
                </div>
                <Divider />
                {this.state.platform === "xs" && (
                  <FaArrowRight
                    className={
                      this.state.hover ? classes.faCogsHover : classes.faCogs
                    }
                    onMouseEnter={() => this.setState({ hover: true })}
                    onMouseLeave={() => this.setState({ hover: false })}
                    onClick={() =>
                      this.setState({ isVisible: false, hover: false })
                    }
                  />
                )}
              </div>
              <ul className={classes.ul}>
                {this.state.movements.map((item, index) => {
                  return (
                    <li key={index} className={classes.li}>
                      <span className={classes.title}>{item.title}</span>
                      <span className={classes.info}>{item.info}</span>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.xsRoot}></Card>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Movements);
