import {
  BoxBufferGeometry,
  BufferGeometry,
  DoubleSide,
  Float32BufferAttribute,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  OctahedronBufferGeometry,
  PlaneBufferGeometry,
  Quaternion,
  Raycaster,
  SphereBufferGeometry,
  Vector3,
  Shape,
  ExtrudeGeometry,
  MeshLambertMaterial,
} from "three/build/three.module";

const gizmoScale = 1;

var TransformControls = function (camera, domElement) {
  if (domElement === undefined) {
    console.warn(
      'THREE.TransformControls: The second parameter "domElement" is now mandatory.'
    );
    domElement = document;
  }

  Object3D.call(this);

  this.visible = false;
  this.domElement = domElement;

  var _gizmo = new TransformControlsGizmo();
  this.add(_gizmo);

  var _plane = new TransformControlsPlane();
  this.add(_plane);

  var scope = this;

  // Define properties with getters/setter
  // Setting the defined property will automatically trigger change event
  // Defined properties are passed down to gizmo and plane

  defineProperty("camera", camera);
  defineProperty("object", undefined);
  defineProperty("enabled", true);
  defineProperty("axis", null);
  defineProperty("mode", "translate");
  defineProperty("translationSnap", null);
  defineProperty("rotationSnap", null);
  defineProperty("scaleSnap", null);
  defineProperty("space", "world");
  defineProperty("size", 1);
  defineProperty("dragging", false);
  defineProperty("showX", true);
  defineProperty("showY", true);
  defineProperty("showZ", true);

  var changeEvent = { type: "change" };
  var mouseDownEvent = { type: "mouseDown" };
  var mouseUpEvent = { type: "mouseUp", mode: scope.mode };
  var objectChangeEvent = { type: "objectChange" };

  // Reusable utility variables

  var raycaster = new Raycaster();

  function intersectObjectWithRay(object, raycaster, includeInvisible) {
    var allIntersections = raycaster.intersectObject(object, true);

    for (var i = 0; i < allIntersections.length; i++) {
      if (allIntersections[i].object.visible || includeInvisible) {
        return allIntersections[i];
      }
    }

    return false;
  }

  function intersectObjectsWithRay(objects, raycaster, includeInvisible) {
    var allIntersections = raycaster.intersectObjects(objects, true);

    for (var i = 0; i < allIntersections.length; i++) {
      if (allIntersections[i].object.visible || includeInvisible) {
        return allIntersections[i];
      }
    }

    return false;
  }

  var _tempVector = new Vector3();
  var _tempQuaternion = new Quaternion();
  var _unit = {
    X: new Vector3(1, 0, 0),
    Y: new Vector3(0, 1, 0),
    Z: new Vector3(0, 0, 1),
  };

  var pointStart = new Vector3();
  var pointEnd = new Vector3();
  var offset = new Vector3();
  var rotationAxis = new Vector3();
  var startNorm = new Vector3();
  var endNorm = new Vector3();
  var rotationAngle = 0;

  var cameraPosition = new Vector3();
  var cameraQuaternion = new Quaternion();
  var cameraScale = new Vector3();

  var parentPosition = new Vector3();
  var parentQuaternion = new Quaternion();
  var parentQuaternionInv = new Quaternion();
  var parentScale = new Vector3();

  var worldPositionStart = new Vector3();
  var worldQuaternionStart = new Quaternion();
  var worldScaleStart = new Vector3();

  var worldPosition = new Vector3();
  var worldQuaternion = new Quaternion();
  var worldQuaternionInv = new Quaternion();
  var worldScale = new Vector3();

  var eye = new Vector3();

  var positionStart = new Vector3();
  var quaternionStart = new Quaternion();
  var scaleStart = new Vector3();

  // TODO: remove properties unused in plane and gizmo

  defineProperty("worldPosition", worldPosition);
  defineProperty("worldPositionStart", worldPositionStart);
  defineProperty("worldQuaternion", worldQuaternion);
  defineProperty("worldQuaternionStart", worldQuaternionStart);
  defineProperty("cameraPosition", cameraPosition);
  defineProperty("cameraQuaternion", cameraQuaternion);
  defineProperty("pointStart", pointStart);
  defineProperty("pointEnd", pointEnd);
  defineProperty("rotationAxis", rotationAxis);
  defineProperty("rotationAngle", rotationAngle);
  defineProperty("eye", eye);

  domElement.addEventListener("pointerdown", onPointerDown, false);
  domElement.addEventListener("pointermove", onPointerHover, false);
  scope.domElement.ownerDocument.addEventListener(
    "pointerup",
    onPointerUp,
    false
  );

  this.dispose = function () {
    domElement.removeEventListener("pointerdown", onPointerDown);
    domElement.removeEventListener("pointermove", onPointerHover);
    scope.domElement.ownerDocument.removeEventListener(
      "pointermove",
      onPointerMove
    );
    scope.domElement.ownerDocument.removeEventListener(
      "pointerup",
      onPointerUp
    );

    this.traverse(function (child) {
      if (child.geometry) child.geometry.dispose();
      if (child.material) child.material.dispose();
    });
  };

  // Set current object
  this.attach = (object) => {
    this.object = object;
    this.visible = true;
    // if (!isLowerTooth && _gizmo) {
    //   _gizmo["gizmo"]["translate"].children.forEach((handle) => {
    //     if (
    //       handle.name.search("Z") !== -1 ||
    //       (handle.mode === "rotate" &&
    //         (handle.name === "Y" || handle.name === "X"))
    //     )
    //       handle.scale.z *= -1;
    //     handle.updateMatrixWorld();
    //   });
    //   _gizmo["picker"]["translate"].children.forEach((handle) => {
    //     if (
    //       handle.name.search("Z") !== -1 ||
    //       (handle.mode === "rotate" &&
    //         (handle.name === "Y" || handle.name === "X"))
    //     )
    //       handle.scale.z *= -1;
    //     handle.updateMatrixWorld();
    //   });
    // }

    return this;
  };

  // Detatch from object
  this.detach = function () {
    this.object = undefined;
    this.visible = false;
    this.axis = null;

    return this;
  };

  // Defined getter, setter and store for a property
  function defineProperty(propName, defaultValue) {
    var propValue = defaultValue;

    Object.defineProperty(scope, propName, {
      get: function () {
        return propValue !== undefined ? propValue : defaultValue;
      },

      set: function (value) {
        if (propValue !== value) {
          propValue = value;
          _plane[propName] = value;
          _gizmo[propName] = value;

          scope.dispatchEvent({ type: propName + "-changed", value: value });
          scope.dispatchEvent(changeEvent);
        }
      },
    });

    scope[propName] = defaultValue;
    _plane[propName] = defaultValue;
    _gizmo[propName] = defaultValue;
  }

  // updateMatrixWorld  updates key transformation variables
  this.updateMatrixWorld = function () {
    if (this.object !== undefined) {
      this.object.updateMatrixWorld();

      if (this.object.parent === null) {
        console.error(
          "TransformControls: The attached 3D object must be a part of the scene graph."
        );
      } else {
        this.object.parent.matrixWorld.decompose(
          parentPosition,
          parentQuaternion,
          parentScale
        );
      }

      this.object.matrixWorld.decompose(
        worldPosition,
        worldQuaternion,
        worldScale
      );

      parentQuaternionInv.copy(parentQuaternion).inverse();
      worldQuaternionInv.copy(worldQuaternion).inverse();
    }

    this.camera.updateMatrixWorld();
    this.camera.matrixWorld.decompose(
      cameraPosition,
      cameraQuaternion,
      cameraScale
    );

    eye.copy(cameraPosition).sub(worldPosition).normalize();

    Object3D.prototype.updateMatrixWorld.call(this);
  };

  this.pointerHover = function (pointer) {
    if (this.object === undefined || this.dragging === true) return;

    raycaster.setFromCamera(pointer, this.camera);
    var intersect = intersectObjectsWithRay(
      [_gizmo.picker["translate"], _gizmo.picker["rotate"]],
      raycaster
    );

    if (intersect) {
      this.axis = intersect.object.name;
      this.mode = intersect.object.mode;
    } else {
      this.axis = null;
    }
  };

  this.pointerDown = function (pointer) {
    if (
      this.object === undefined ||
      this.dragging === true ||
      pointer.button !== 0
    )
      return;

    if (this.axis !== null) {
      raycaster.setFromCamera(pointer, this.camera);

      var planeIntersect = intersectObjectWithRay(_plane, raycaster, true);

      if (planeIntersect) {
        var space = this.space;

        if (this.axis === "E" || this.axis === "XYZE" || this.axis === "XYZ") {
          space = "world";
        }

        if (space === "local" && this.mode === "rotate") {
          var snap = this.rotationSnap;

          if (this.axis === "X" && snap)
            this.object.rotation.x =
              Math.round(this.object.rotation.x / snap) * snap;
          if (this.axis === "Y" && snap)
            this.object.rotation.y =
              Math.round(this.object.rotation.y / snap) * snap;
          if (this.axis === "Z" && snap)
            this.object.rotation.z =
              Math.round(this.object.rotation.z / snap) * snap;
        }

        this.object.updateMatrixWorld();
        this.object.parent.updateMatrixWorld();

        positionStart.copy(this.object.position);
        quaternionStart.copy(this.object.quaternion);
        scaleStart.copy(this.object.scale);

        this.object.matrixWorld.decompose(
          worldPositionStart,
          worldQuaternionStart,
          worldScaleStart
        );

        pointStart.copy(planeIntersect.point).sub(worldPositionStart);
      }

      this.dragging = true;
      mouseDownEvent.mode = this.mode;
      this.dispatchEvent(mouseDownEvent);
    }
  };

  this.pointerMove = function (pointer) {
    var axis = this.axis;
    var mode = this.mode;
    var object = this.object;
    var space = this.space;
    if (axis === "E" || axis === "XYZE" || axis === "XYZ") {
      space = "world";
    }
    if (
      object === undefined ||
      axis === null ||
      this.dragging === false ||
      pointer.button !== -1
    )
      return;
    raycaster.setFromCamera(pointer, this.camera);
    var planeIntersect = intersectObjectWithRay(_plane, raycaster, true);
    if (!planeIntersect) return;
    pointEnd.copy(planeIntersect.point).sub(worldPositionStart);
    if (mode === "translate") {
      // Apply translate
      offset.copy(pointEnd).sub(pointStart);
      if (space === "local" && axis !== "XYZ") {
        offset.applyQuaternion(worldQuaternionInv);
      }
      if (axis.indexOf("X") === -1) offset.x = 0;
      if (axis.indexOf("Y") === -1) offset.y = 0;
      if (axis.indexOf("Z") === -1) offset.z = 0;
      if (space === "local" && axis !== "XYZ") {
        offset.applyQuaternion(quaternionStart).divide(parentScale);
      } else {
        offset.applyQuaternion(parentQuaternionInv).divide(parentScale);
      }
      object.position.copy(offset).add(positionStart);
      // Apply translation snap
      if (this.translationSnap) {
        if (space === "local") {
          object.position.applyQuaternion(
            _tempQuaternion.copy(quaternionStart).inverse()
          );
          if (axis.search("X") !== -1) {
            object.position.x =
              Math.round(object.position.x / this.translationSnap) *
              this.translationSnap;
          }
          if (axis.search("Y") !== -1) {
            object.position.y =
              Math.round(object.position.y / this.translationSnap) *
              this.translationSnap;
          }
          if (axis.search("Z") !== -1) {
            object.position.z =
              Math.round(object.position.z / this.translationSnap) *
              this.translationSnap;
          }
          object.position.applyQuaternion(quaternionStart);
        }
        if (space === "world") {
          if (object.parent) {
            object.position.add(
              _tempVector.setFromMatrixPosition(object.parent.matrixWorld)
            );
          }
          if (axis.search("X") !== -1) {
            object.position.x =
              Math.round(object.position.x / this.translationSnap) *
              this.translationSnap;
          }
          if (axis.search("Y") !== -1) {
            object.position.y =
              Math.round(object.position.y / this.translationSnap) *
              this.translationSnap;
          }
          if (axis.search("Z") !== -1) {
            object.position.z =
              Math.round(object.position.z / this.translationSnap) *
              this.translationSnap;
          }
          if (object.parent) {
            object.position.sub(
              _tempVector.setFromMatrixPosition(object.parent.matrixWorld)
            );
          }
        }
      }
    } else if (mode === "rotate") {
      offset.copy(pointEnd).sub(pointStart);
      var ROTATION_SPEED =
        20 /
        worldPosition.distanceTo(
          _tempVector.setFromMatrixPosition(this.camera.matrixWorld)
        );
      if (axis === "E") {
        rotationAxis.copy(eye);
        rotationAngle = pointEnd.angleTo(pointStart);
        startNorm.copy(pointStart).normalize();
        endNorm.copy(pointEnd).normalize();
        rotationAngle *= endNorm.cross(startNorm).dot(eye) < 0 ? 1 : -1;
      } else if (axis === "XYZE") {
        rotationAxis.copy(offset).cross(eye).normalize();
        rotationAngle =
          offset.dot(_tempVector.copy(rotationAxis).cross(this.eye)) *
          ROTATION_SPEED;
      } else if (axis === "X" || axis === "Y" || axis === "Z") {
        rotationAxis.copy(_unit[axis]);
        _tempVector.copy(_unit[axis]);
        if (space === "local") {
          _tempVector.applyQuaternion(worldQuaternion);
        }
        rotationAngle =
          offset.dot(_tempVector.cross(eye).normalize()) * ROTATION_SPEED;
      }
      // Apply rotation snap
      if (this.rotationSnap)
        rotationAngle =
          Math.round(rotationAngle / this.rotationSnap) * this.rotationSnap;
      this.rotationAngle = rotationAngle;
      // Apply rotate
      if (space === "local" && axis !== "E" && axis !== "XYZE") {
        object.quaternion.copy(quaternionStart);
        object.quaternion
          .multiply(
            _tempQuaternion.setFromAxisAngle(rotationAxis, rotationAngle)
          )
          .normalize();
      } else {
        rotationAxis.applyQuaternion(parentQuaternionInv);
        object.quaternion.copy(
          _tempQuaternion.setFromAxisAngle(rotationAxis, rotationAngle)
        );
        object.quaternion.multiply(quaternionStart).normalize();
      }
    }
    this.dispatchEvent(changeEvent);
    this.dispatchEvent(objectChangeEvent);
  };

  this.pointerUp = function (pointer) {
    if (pointer.button !== 0) return;

    if (this.dragging && this.axis !== null) {
      mouseUpEvent.mode = this.mode;
      this.dispatchEvent(mouseUpEvent);
    }

    this.dragging = false;
    this.axis = null;
  };

  // normalize mouse / touch pointer and remap {x,y} to view space.

  function getPointer(event) {
    if (scope.domElement.ownerDocument.pointerLockElement) {
      return {
        x: 0,
        y: 0,
        button: event.button,
      };
    } else {
      var pointer = event.changedTouches ? event.changedTouches[0] : event;

      var rect = domElement.getBoundingClientRect();

      return {
        x: ((pointer.clientX - rect.left) / rect.width) * 2 - 1,
        y: (-(pointer.clientY - rect.top) / rect.height) * 2 + 1,
        button: event.button,
      };
    }
  }

  // mouse / touch event handlers

  function onPointerHover(event) {
    if (!scope.enabled) return;

    switch (event.pointerType) {
      case "mouse":
      case "pen":
        scope.pointerHover(getPointer(event));
        break;
      default:
        break;
    }
  }

  function onPointerDown(event) {
    if (!scope.enabled) return;

    scope.domElement.style.touchAction = "none"; // disable touch scroll
    scope.domElement.ownerDocument.addEventListener(
      "pointermove",
      onPointerMove,
      false
    );

    scope.pointerHover(getPointer(event));
    scope.pointerDown(getPointer(event));
  }

  function onPointerMove(event) {
    if (!scope.enabled) return;

    scope.pointerMove(getPointer(event));
  }

  function onPointerUp(event) {
    if (!scope.enabled) return;

    scope.domElement.style.touchAction = "";
    scope.domElement.ownerDocument.removeEventListener(
      "pointermove",
      onPointerMove,
      false
    );

    scope.pointerUp(getPointer(event));
  }

  // TODO: deprecate

  this.getMode = function () {
    return scope.mode;
  };

  this.setMode = function (mode) {
    scope.mode = mode;
  };

  this.setTranslationSnap = function (translationSnap) {
    scope.translationSnap = translationSnap;
  };

  this.setRotationSnap = function (rotationSnap) {
    scope.rotationSnap = rotationSnap;
  };

  this.setScaleSnap = function (scaleSnap) {
    scope.scaleSnap = scaleSnap;
  };

  this.setSize = function (size) {
    scope.size = size;
  };

  this.setSpace = function (space) {
    scope.space = space;
  };

  this.update = function () {
    console.warn(
      "THREE.TransformControls: update function has no more functionality and therefore has been deprecated."
    );
  };
};

TransformControls.prototype = Object.assign(Object.create(Object3D.prototype), {
  constructor: TransformControls,

  isTransformControls: true,
});

var TransformControlsGizmo = function () {
  Object3D.call(this);

  this.type = "TransformControlsGizmo";

  // shared materials

  var gizmoMaterial = new MeshLambertMaterial({
    depthTest: false,
    depthWrite: false,
    transparent: true,
    opacity: 0.4,
    side: DoubleSide,
    fog: false,
    toneMapped: false,
  });

  var gizmoLineMaterial = new MeshLambertMaterial({
    depthTest: false,
    depthWrite: false,
    transparent: true,
    opacity: 0.4,
    fog: false,
    toneMapped: false,
    side: DoubleSide,
  });

  // Make unique material for each axis/color

  var matInvisible = gizmoMaterial.clone();
  matInvisible.opacity = 0.15;

  var matHelper = gizmoMaterial.clone();
  matHelper.opacity = 0.33;

  var matRed = gizmoMaterial.clone();
  matRed.color.set(0xff0000);

  var matGreen = gizmoMaterial.clone();
  matGreen.color.set(0x00ff00);

  var matBlue = gizmoMaterial.clone();
  matBlue.color.set(0x0000ff);

  var matWhiteTransparent = gizmoMaterial.clone();
  // matWhiteTransparent.opacity = 0.25;

  var matYellowTransparent = matWhiteTransparent.clone();
  matYellowTransparent.color.set(0xffff00);

  var matCyanTransparent = matWhiteTransparent.clone();
  matCyanTransparent.color.set(0x00ffff);

  var matMagentaTransparent = matWhiteTransparent.clone();
  matMagentaTransparent.color.set(0xff00ff);

  var matYellow = gizmoMaterial.clone();
  matYellow.color.set(0xffff00);

  var matLineRed = gizmoLineMaterial.clone();
  matLineRed.color.set(0xff0000);

  var matLineGreen = gizmoLineMaterial.clone();
  matLineGreen.color.set(0x00ff00);

  var matLineBlue = gizmoLineMaterial.clone();
  matLineBlue.color.set(0x0000ff);

  var matLineCyan = gizmoLineMaterial.clone();
  matLineCyan.color.set(0x00ffff);

  var matLineMagenta = gizmoLineMaterial.clone();
  matLineMagenta.color.set(0xff00ff);

  var matLineYellow = gizmoLineMaterial.clone();
  matLineYellow.color.set(0xffff00);

  var matLineGray = gizmoLineMaterial.clone();
  matLineGray.color.set(0x787878);

  var matLineYellowTransparent = matLineYellow.clone();
  matLineYellowTransparent.opacity = 0.25;

  // reusable geometry

  var lineGeometry = new BufferGeometry();
  lineGeometry.setAttribute(
    "position",
    new Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3)
  );

  // Special geometry for transform helper. If scaled with position vector it spans from [0,0,0] to position

  // Gizmo definitions - custom hierarchy definitions for setupGizmo() function

  var gizmoTranslate = {
    X: [
      [
        new Mesh(
          new BoxBufferGeometry(
            1 * gizmoScale,
            0.2 * gizmoScale,
            0.2 * gizmoScale
          ).translate(0.7, 0, 0),
          matLineRed.clone()
        ),
      ],
    ],
    Y: [
      [
        new Mesh(
          new BoxBufferGeometry(
            1 * gizmoScale,
            0.2 * gizmoScale,
            0.2 * gizmoScale
          ).translate(0.7, 0, 0),
          matLineGreen.clone()
        ),
        null,
        [0, 0, Math.PI / 2],
      ],
    ],
    Z: [
      [
        new Mesh(
          new BoxBufferGeometry(
            1 * gizmoScale,
            0.2 * gizmoScale,
            0.2 * gizmoScale
          ).translate(0.7, 0, 0),
          matLineBlue.clone()
        ),
        null,
        [0, -Math.PI / 2, 0],
      ],
    ],
    XYZ: [
      [
        new Mesh(
          new SphereBufferGeometry(0.1, 32, 32),
          matWhiteTransparent.clone()
        ),
        [0, 0, 0],
        [0, 0, 0],
      ],
    ],
    XY: [
      [
        new Mesh(
          new PlaneBufferGeometry(0.35 * gizmoScale, 0.35 * gizmoScale),
          matYellowTransparent.clone()
        ),
        [0.35, 0.35, 0],
      ],
    ],
    YZ: [
      [
        new Mesh(
          new PlaneBufferGeometry(0.35 * gizmoScale, 0.35 * gizmoScale),
          matCyanTransparent.clone()
        ),
        [0, 0.35, 0.35],
        [0, Math.PI / 2, 0],
      ],
    ],
    XZ: [
      [
        new Mesh(
          new PlaneBufferGeometry(0.35 * gizmoScale, 0.35 * gizmoScale),
          matMagentaTransparent.clone()
        ),
        [0.35, 0, 0.35],
        [-Math.PI / 2, 0, 0],
      ],
    ],
  };

  var pickerTranslate = {
    X: [
      [
        new Mesh(
          new BoxBufferGeometry(
            1 * gizmoScale,
            0.2 * gizmoScale,
            0.2 * gizmoScale
          ).translate(0.7, 0, 0),
          matLineRed.clone()
        ),
      ],
    ],
    Y: [
      [
        new Mesh(
          new BoxBufferGeometry(
            1 * gizmoScale,
            0.2 * gizmoScale,
            0.2 * gizmoScale
          ).translate(0.7, 0, 0),
          matLineGreen.clone()
        ),
        null,
        [0, 0, Math.PI / 2],
      ],
    ],
    Z: [
      [
        new Mesh(
          new BoxBufferGeometry(
            1 * gizmoScale,
            0.2 * gizmoScale,
            0.2 * gizmoScale
          ).translate(0.7, 0, 0),
          matLineBlue.clone()
        ),
        null,
        [0, -Math.PI / 2, 0],
      ],
    ],
    XYZ: [[new Mesh(new OctahedronBufferGeometry(0.2, 0), matInvisible)]],
    XY: [
      [
        new Mesh(
          new PlaneBufferGeometry(0.35 * gizmoScale, 0.295 * gizmoScale),
          matYellowTransparent.clone()
        ),
        [0.35, 0.35, 0],
      ],
    ],
    YZ: [
      [
        new Mesh(
          new PlaneBufferGeometry(0.35 * gizmoScale, 0.35 * gizmoScale),
          matCyanTransparent.clone()
        ),
        [0, 0.35, 0.35],
        [0, Math.PI / 2, 0],
      ],
    ],
    XZ: [
      [
        new Mesh(
          new PlaneBufferGeometry(0.35 * gizmoScale, 0.35 * gizmoScale),
          matMagentaTransparent.clone()
        ),
        [0.35, 0, 0.35],
        [-Math.PI / 2, 0, 0],
      ],
    ],
  };

  const createArcGeometry = () => {
    const shape = new Shape();
    shape.arc(0, 0, 1, Math.PI / 6, Math.PI / 3, false);
    shape.arc(-0.5, -Math.sqrt(3) / 2, 0.75, Math.PI / 3, Math.PI / 6, true);

    const extrudeSettings = {
      curveSegments: 10,
      depth: 0.2,
      bevelEnabled: false,
    };

    return new ExtrudeGeometry(shape, extrudeSettings);
  };

  var gizmoRotate = {
    X: [
      [
        new Mesh(createArcGeometry(), matLineRed.clone()),
        [-0.1, 0, 0],
        [0, -Math.PI / 2, 0],
      ],
    ],
    Y: [
      [
        new Mesh(createArcGeometry(), matLineGreen.clone()),
        [0, -0.1, 0],
        [Math.PI / 2, 0, 0],
      ],
    ],
    Z: [[new Mesh(createArcGeometry(), matLineBlue.clone()), [0, 0, -0.1]]],
  };

  var pickerRotate = {
    X: [
      [
        new Mesh(createArcGeometry(), matLineRed.clone()),
        [-0.1, 0, 0],
        [0, -Math.PI / 2, 0],
      ],
    ],
    Y: [
      [
        new Mesh(createArcGeometry(), matLineGreen.clone()),
        [0, -0.1, 0],
        [Math.PI / 2, 0, 0],
      ],
    ],
    Z: [[new Mesh(createArcGeometry(), matLineBlue.clone()), [0, 0, -0.1]]],
  };

  // Creates an Object3D with gizmos described in custom hierarchy definition.

  var setupGizmo = function (gizmoMap) {
    var gizmo = new Object3D();

    for (var name in gizmoMap) {
      for (var i = gizmoMap[name].length; i--; ) {
        var object = gizmoMap[name][i][0].clone();
        var position = gizmoMap[name][i][1];
        var rotation = gizmoMap[name][i][2];
        var scale = gizmoMap[name][i][3];
        var tag = gizmoMap[name][i][4];

        // name and tag properties are essential for picking and updating logic.
        object.name = name;
        object.tag = tag;

        if (position) {
          object.position.set(position[0], position[1], position[2]);
        }

        if (rotation) {
          object.rotation.set(rotation[0], rotation[1], rotation[2]);
        }

        if (scale) {
          object.scale.set(scale[0], scale[1], scale[2]);
        }

        object.updateMatrix();

        var tempGeometry = object.geometry.clone();
        tempGeometry.applyMatrix4(object.matrix);
        object.geometry = tempGeometry;
        object.renderOrder = Infinity;

        object.position.set(0, 0, 0);
        object.rotation.set(0, 0, 0);
        object.scale.set(1, 1, 1);

        gizmo.add(object);
      }
    }

    return gizmo;
  };

  // Reusable utility variables

  var alignVector = new Vector3(0, 1, 0);
  var identityQuaternion = new Quaternion();

  var unitX = new Vector3(1, 0, 0);
  var unitY = new Vector3(0, 1, 0);
  var unitZ = new Vector3(0, 0, 1);

  // Gizmo creation

  this.gizmo = {};
  this.picker = {};
  this.helper = {};

  this.add((this.gizmo["translate"] = setupGizmo(gizmoTranslate)));
  this.add((this.gizmo["rotate"] = setupGizmo(gizmoRotate)));
  this.add((this.picker["translate"] = setupGizmo(pickerTranslate)));
  this.add((this.picker["rotate"] = setupGizmo(pickerRotate)));

  // Pickers should be hidden always

  this.picker["translate"].visible = false;
  this.picker["rotate"].visible = false;

  // updateMatrixWorld will update transformations and appearance of individual handles

  this.updateMatrixWorld = function () {
    var space = this.space;

    var quaternion =
      space === "local" ? this.worldQuaternion : identityQuaternion;

    // Show only gizmos for current transform mode

    this.gizmo["translate"].visible = true;
    this.gizmo["rotate"].visible = true;

    var handles = [];
    handles = handles.concat(this.picker["translate"].children);
    this.picker["translate"].children.map((c) => (c.mode = "translate"));
    handles = handles.concat(this.gizmo["translate"].children);
    this.gizmo["translate"].children.map((c) => (c.mode = "translate"));

    handles = handles.concat(this.picker["rotate"].children);
    this.picker["rotate"].children.map((c) => (c.mode = "rotate"));
    handles = handles.concat(this.gizmo["rotate"].children);
    this.gizmo["rotate"].children.map((c) => (c.mode = "rotate"));
    for (var i = 0; i < handles.length; i++) {
      var handle = handles[i];

      // hide aligned to camera

      handle.visible = true;
      handle.rotation.set(0, 0, 0);
      handle.position.copy(this.worldPosition);

      var factor;

      if (this.camera.isOrthographicCamera) {
        factor = (this.camera.top - this.camera.bottom) / this.camera.zoom;
      } else {
        factor =
          this.worldPosition.distanceTo(this.cameraPosition) *
          Math.min(
            (1.9 * Math.tan((Math.PI * this.camera.fov) / 360)) /
              this.camera.zoom,
            7
          );
      }

      handle.scale.set(1, 1, 1).multiplyScalar((factor * this.size) / 7);

      // TODO: simplify helpers and consider decoupling from gizmo

      // Align handles to current local or world rotation

      handle.quaternion.copy(quaternion);

      if (handle.mode === "translate" || handle.mode === "rotate") {
        // Hide translate and scale axis facing the camera

        var AXIS_HIDE_TRESHOLD = 0.99;
        var PLANE_HIDE_TRESHOLD = 0.2;

        if (
          (handle.name === "X" && handle.mode !== "rotate") ||
          handle.name === "XYZX"
        ) {
          if (
            Math.abs(
              alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
          }
        }

        if (
          (handle.name === "Y" && handle.mode !== "rotate") ||
          handle.name === "XYZY"
        ) {
          if (
            Math.abs(
              alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
          }
        }

        if (
          (handle.name === "Z" && handle.mode !== "rotate") ||
          handle.name === "XYZZ"
        ) {
          if (
            Math.abs(
              alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.eye)
            ) > AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
          }
        }

        if (
          handle.name === "XY" ||
          (handle.name === "Z" && handle.mode === "rotate")
        ) {
          if (
            Math.abs(
              alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
          }
        }

        if (
          handle.name === "YZ" ||
          (handle.name === "X" && handle.mode === "rotate")
        ) {
          if (
            Math.abs(
              alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
          }
        }

        if (
          handle.name === "XZ" ||
          (handle.name === "Y" && handle.mode === "rotate")
        ) {
          if (
            Math.abs(
              alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.eye)
            ) < PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10);
          }
        }
        if (
          handle.name.search("Z") !== -1 ||
          (handle.mode === "rotate" &&
            (handle.name === "Y" || handle.name === "X"))
        ) {
          if (handle.tag !== "fwd") {
            handle.scale.z *= -1;
          }
        }
      }

      if (handle.name === this.axis && handle.mode === this.mode)
        handle.material.opacity = 1;
      else handle.material.opacity = 0.4;
    }

    Object3D.prototype.updateMatrixWorld.call(this);
  };
};

TransformControlsGizmo.prototype = Object.assign(
  Object.create(Object3D.prototype),
  {
    constructor: TransformControlsGizmo,

    isTransformControlsGizmo: true,
  }
);

var TransformControlsPlane = function () {
  Mesh.call(
    this,
    new PlaneBufferGeometry(100000, 100000, 2, 2),
    new MeshBasicMaterial({
      visible: false,
      wireframe: true,
      side: DoubleSide,
      transparent: true,
      opacity: 0.1,
      toneMapped: false,
    })
  );

  this.type = "TransformControlsPlane";

  var unitX = new Vector3(1, 0, 0);
  var unitY = new Vector3(0, 1, 0);
  var unitZ = new Vector3(0, 0, 1);

  var tempVector = new Vector3();
  var dirVector = new Vector3();
  var alignVector = new Vector3();
  var tempMatrix = new Matrix4();
  var identityQuaternion = new Quaternion();

  this.updateMatrixWorld = function () {
    var space = this.space;

    this.position.copy(this.worldPosition);

    if (this.mode === "scale") space = "local"; // scale always oriented to local rotation

    unitX
      .set(1, 0, 0)
      .applyQuaternion(
        space === "local" ? this.worldQuaternion : identityQuaternion
      );
    unitY
      .set(0, 1, 0)
      .applyQuaternion(
        space === "local" ? this.worldQuaternion : identityQuaternion
      );
    unitZ
      .set(0, 0, 1)
      .applyQuaternion(
        space === "local" ? this.worldQuaternion : identityQuaternion
      );

    // Align the plane for current transform mode, axis and space.

    alignVector.copy(unitY);

    switch (this.mode) {
      case "translate":
        switch (this.axis) {
          case "X":
            alignVector.copy(this.eye).cross(unitX);
            dirVector.copy(unitX).cross(alignVector);
            break;
          case "Y":
            alignVector.copy(this.eye).cross(unitY);
            dirVector.copy(unitY).cross(alignVector);
            break;
          case "Z":
            alignVector.copy(this.eye).cross(unitZ);
            dirVector.copy(unitZ).cross(alignVector);
            break;
          case "XY":
            dirVector.copy(unitZ);
            break;
          case "YZ":
            dirVector.copy(unitX);
            break;
          case "XZ":
            alignVector.copy(unitZ);
            dirVector.copy(unitY);
            break;
          case "XYZ":
          case "E":
            dirVector.set(0, 0, 0);
            break;
          default:
            break;
        }

        break;
      case "rotate":
      default:
        // special case for rotate
        dirVector.set(0, 0, 0);
    }

    if (dirVector.length() === 0) {
      // If in rotate mode, make the plane parallel to camera
      this.quaternion.copy(this.cameraQuaternion);
    } else {
      tempMatrix.lookAt(tempVector.set(0, 0, 0), dirVector, alignVector);

      this.quaternion.setFromRotationMatrix(tempMatrix);
    }

    Object3D.prototype.updateMatrixWorld.call(this);
  };
};

TransformControlsPlane.prototype = Object.assign(
  Object.create(Mesh.prototype),
  {
    constructor: TransformControlsPlane,

    isTransformControlsPlane: true,
  }
);

export { TransformControls, TransformControlsGizmo, TransformControlsPlane };
