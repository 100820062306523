import React from "react";
import {
  LockOutlined,
  PhoneOutlined,
  PlusOutlined,
  PrinterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Input,
  Button,
  Checkbox,
  Layout,
  Row,
  Col,
  Card,
  Spin,
  Alert,
  message,
} from "antd";
import { Amplify, Storage } from "aws-amplify";
import { createUser } from "./User";
// import {
//   Elements,
//   StripeProvider,
//   CardElement,
//   injectStripe,
// } from "react-stripe-elements";
// import awsExports from "./aws-exports";
import { Link, withRouter } from "react-router-dom";
import Header from "./Header";
import Intercom from "react-intercom";

const { Content, Footer } = Layout;

// class signupStripe extends React.Component {

//   render() {
//     return (
//     )
//   }
// }

const _SignupForm = Form.create({ name: "signup" })(
  class SignupForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        signup_complete: false,
        primary_technician: this.props.location.hash
          ? decodeURIComponent(this.props.location.hash.replace("#", ""))
          : null,
        step: 1,
        // step_one_values: {
        //   email: "",
        //   name: "",
        //   business: "",
        //   password: "",
        // },
      };
      const isCalendlyEvent = ((e) => {
        // console.log(e)
        if (
          e.data.event &&
          e.data.event.indexOf("calendly.event_scheduled") === 0
        ) {
          this.setState({ step: 3 });
        }
      }).bind(this);

      window.addEventListener("message", (e) => isCalendlyEvent(e));
    }

    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
        if (err) return console.log(err);
        if (this.state.loading || this.state.signup_complete) return;

        this.setState({
          loading: true,
        });
        const attributes = {
          email: values.email.toLowerCase().trim(),
          phone_number: "+" + values.phone_number_cc.trim() + values.phone_number.trim(),
          address: "None",
          name: values.name.trim(),
        };
        if (this.state.primary_technician) {
          attributes["custom:primary_technician"] =
            this.state.primary_technician;
        }

        try {
          const code = await Amplify.Auth.signUp({
            username: values.email.toLowerCase().trim(),
            password: values.password.trim(),
            attributes: attributes,
          });

          window.Calendly.initInlineWidget({
            url: "https://calendly.com/archform/onboarding-call",
            parentElement: document.getElementById("calendly-inline-widget"),
            prefill: {
              name: values.name,
              email: values.email.toLowerCase(),
            },
          });
          message.success("Sign Up Success!")
          this.setState({
            step: 3,
          });
        } catch (e) {
          // message.error("Fail: " + e.message)
          message.error("Create account failed, please try again.")
        }

        this.setState({
          loading: false,
        });

        // this.setState({ signup_complete: true, loading: false });

        // this.setState({ loading: true, error: false, step: 2 }, async () => {
        //   try {
        //     values = { ...this.state.step_one_values, ...values };

        //     // Stripe
        //     // const { token, error } = await this.props.stripe.createToken({ name: values.email });
        //     // if (error) throw error;
        //     // await createUser({ token: token.id, email: values.email });

        //     const attributes = {
        //       email: values.email.toLowerCase(),
        //       phone_number: "+" + values.phone_number_cc + values.phone_number,
        //       address: "None",
        //       name: values.name,
        //     };
        //     if (this.state.primary_technician)
        //       attributes["custom:primary_technician"] =
        //         this.state.primary_technician;
        //     const code = await Amplify.Auth.signUp({
        //       username: values.email.toLowerCase(),
        //       password: values.password,
        //       attributes: attributes,
        //     });
        //     this.setState({ signup_complete: true, loading: false });
        //     window.Calendly.initInlineWidget({
        //       url: "https://calendly.com/archform/onboarding-call",
        //       parentElement: document.getElementById("calendly-inline-widget"),
        //       prefill: {
        //         name: values.name,
        //         email: values.email.toLowerCase(),
        //       },
        //     });
        //   } catch (err) {
        //     console.log(err);
        //     this.setState({ loading: false, error: err.message }, () => {
        //       console.error(err);
        //     });
        //   }
        // });
      });
    };

    render() {
      const { getFieldDecorator } = this.props.form;
      const { signup_complete } = this.state;

      return (
        <Layout style={{ background: "white", height: "100%" }}>
          <Header hideUser />
          <Content id="signup-content">
            {this.state.step === 1 && (
              <div className="signup-cta">
                <h1
                  style={{
                    lineHeight: "56px",
                    fontSize: "44px",
                    fontWeight: "500",
                  }}
                >
                  Get started today
                </h1>
                <p style={{ lineHeight: "28px", fontWeight: "400" }}>
                  Begin planning treatments today.
                  {/* <br />
                  Your first 30 days are free. */}
                </p>
              </div>
            )}
            {this.state.step == 2 && (
              <div id="signup-cta-calendar" className="signup-cta">
                <h1
                  style={{
                    lineHeight: "56px",
                    fontSize: "44px",
                    fontWeight: "500",
                  }}
                >
                  Schedule a 30 Minute Demo
                </h1>
                <p style={{ lineHeight: "28px", fontWeight: "400" }}>
                  We'll email you a download link before the meeting
                </p>

                <Button
                  type="primary"
                  style={{ width: "50%" }}
                  onClick={() => this.setState({ step: 3 })}
                  block={false}
                >
                  Skip
                </Button>
              </div>
            )}
            {this.state.step == 3 && (
              <div id="signup-cta-calendar" className="signup-cta">
                <h1
                  style={{
                    lineHeight: "56px",
                    fontSize: "44px",
                    fontWeight: "500",
                  }}
                >
                  Confirm your email to log in
                </h1>
                <p style={{ lineHeight: "28px", fontWeight: "400" }}>
                  We sent a confirmation link to your email address.
                  <br />
                  Please open it and click the "Confirm account" button.
                  <br />
                  If you don't see the email, please check your spam folder
                </p>
              </div>
            )}
            <div
              id="calendar"
              style={{
                height: "100%",
                display: this.state.step == 2 ? "initial" : "none",
              }}
            >
              <div
                id="calendly-inline-widget"
                style={{ height: "100vh", dataAutoLoad: "false" }}
              />
            </div>
            <Card
              className="signup-form"
              style={{ display: signup_complete ? "none" : "flex" }}
            >
              <Spin tip="Creating Account" spinning={this.state.loading}>
                {!this.state.signup_complete && (
                  <Form
                    onSubmit={this.handleSubmit}
                    style={{ height: "100%" }}
                    // initialValues={{
                    //   email: this.state.step_one_values.email,
                    //   name: this.state.step_one_values.name,
                    //   business: this.state.step_one_values.business,
                    //   password: this.state.step_one_values.password,
                    // }}
                  >
                    {this.state.step === 1 && (
                      <Form.Item>
                        <span className="signup-input-label">Full name</span>
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your name!",
                            },
                          ],
                        })(
                          <Input
                            prefix={
                              <UserOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="John Carson"
                          />
                        )}
                      </Form.Item>
                    )}
                    {this.state.step === 1 && (
                      <Form.Item>
                        <span className="signup-input-label">
                          Email address
                        </span>
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                          ],
                        })(
                          <Input
                            prefix={
                              <PrinterOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Enter your email"
                          />
                        )}
                      </Form.Item>
                    )}
                    {this.state.step === 1 && (
                      <Form.Item>
                        <span className="signup-input-label">
                          Office/Business Name
                        </span>
                        {getFieldDecorator("business", {
                          rules: [
                            {
                              required: false,
                              message: "Please input your business name!",
                            },
                          ],
                        })(
                          <Input
                            prefix={
                              <UserOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="John Carson Orthodontics"
                          />
                        )}
                      </Form.Item>
                    )}
                    {/* {this.state.step === 2 && (
                      <Form.Item>
                        {getFieldDecorator("address", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your address!",
                            },
                          ],
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="home"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            placeholder="Address"
                          />
                        )}
                      </Form.Item>
                    )} */}
                    {this.state.step === 1 && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="signup-input-label">
                          Mobile number
                        </span>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Form.Item style={{ width: "20%" }}>
                            {getFieldDecorator("phone_number_cc", {
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "Please input your phone number's country code!",
                                },
                              ],
                            })(
                              <Input
                                prefix={
                                  <PlusOutlined
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                  />
                                }
                                placeholder="1"
                              />
                            )}
                          </Form.Item>
                          <Form.Item>
                            {getFieldDecorator("phone_number", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please input your phone number!",
                                },
                              ],
                            })(
                              <Input
                                prefix={
                                  <PhoneOutlined
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                  />
                                }
                                placeholder="Phone Number"
                              />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    )}
                    {this.state.step === 1 && (
                      <Form.Item>
                        <span className="signup-input-label">Password</span>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your Password!",
                            },
                            {
                              min: 6,
                              message:
                                "Password must be at least six characters long",
                            },
                          ],
                        })(
                          <Input
                            prefix={
                              <LockOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            type="password"
                            placeholder="Password"
                          />
                        )}
                      </Form.Item>
                    )}
                    {this.state.step === 1 && (
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          loading={this.state.loading}
                          type="primary"
                          htmlType="submit"
                          style={{ width: "100%" }}
                        >
                          Start using ArchForm
                        </Button>
                      </Form.Item>
                    )}
                  </Form>
                )}
                {this.state.error ? (
                  <Alert
                    message={this.state.error}
                    type="error"
                    showIcon
                    style={{ marginBottom: "0px" }}
                  />
                ) : null}
              </Spin>
            </Card>
          </Content>
          <Intercom
            appID="mg5n9yyr"
            // email={
            //   this.state.step_one_values.email
            //     ? this.state.step_one_values.email
            //     : null
            // }
            // name={
            //   this.state.step_one_values.name
            //     ? this.state.step_one_values.name
            //     : null
            // }
          />
        </Layout>
      );
    }
  }
);

const SignupForm = withRouter(_SignupForm);
export default class SignupPage extends React.Component {
  render() {
    return <SignupForm />;
  }
}
// export default class StripeProviderForm extends React.Component {
//   render() {
//     return (
//       <StripeProvider
//         apiKey={
//           // awsExports.aws_content_delivery_bucket.includes("prod")
//             // ? "pk_live_lOZs0HaHtTKjZeIicaVXehAY"
//             // : "pk_test_FpbM4jAtnJ1AVG6rlp3z6FcW"
//             "pk_live_lOZs0HaHtTKjZeIicaVXehAY"
//         }
//       >
//         <Elements>
//           <SignupForm />
//         </Elements>
//       </StripeProvider>
//     );
//   }
// }