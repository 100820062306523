import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Drawer from '@material-ui/core/Drawer';
import { fade } from "@material-ui/core/styles/colorManipulator";
import ArchFormAppBar from "./AppBar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import BuildIcon from "@material-ui/icons/Build";
import { Amplify } from "aws-amplify";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import PatientTable from "./PatientTable2";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import FeedbackIcon from "@material-ui/icons/Feedback";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FaceIcon from "@material-ui/icons/Face";
import { Animate } from "react-move";
import Header from "./Header";
import {
  getUser,
  updateUser,
  getBillingAccount,
  getFreeTrialActive,
} from "./User";
import { getDentist } from "./Dentists";
import { TeethTable } from "./PatientView";
import { Inbox } from "./Inbox";
import { Button as ArchformButton } from "./Button";
import Sidebar, { status_color_map } from "./Sidebar";
import PatientView from "./PatientView2";
import CreatePatient from "./CreatePatient";
import SettingsPage from "./SettingsPage";
import BillingPage from "./BillingPage";
import { Plus, Search } from "react-feather";
import Intercom from "react-intercom";
import { get_value } from "./db";
import "./Dashboard.css";

import {
  withRouter,
  Link,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import {
  Layout,
  Breadcrumb,
  Drawer,
  Icon,
  Menu,
  Input,
  Tabs,
  Row,
  Col,
  Button,
  Avatar,
  Dropdown,
  Radio,
  Spin,
  message,
} from "antd";
import {
  getPatients,
  getPatientAvatars,
  updatePatient,
  getPatientNotifications,
} from "./Patients";
const drawerWidth = 240;

const { Content } = Layout;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.location.hash
        ? decodeURIComponent(this.props.location.hash.replace("#", "")).split(
            "_"
          )[0]
        : "Needs Review",
      search: "",
      acctAnchorEl: null,
      draweropen: false,
      patientsLoaded: false,
      patients: [],
      avatars: {},
      notifications: Amplify.Cache.getItem("notifications") || [],
      selectedRows: [],
      billing_cycle: "month",
      user: null,
      oldPass: "",
      newPass: "",
      create_patient_available: true,
      currentPage: this.props.location.hash
        ? parseInt(
            decodeURIComponent(this.props.location.hash.replace("#", "")).split(
              "_"
            )[1]
          )
        : 1,
    };

    if (props.location.pathname === "/") {
      this.props.history.push(
        "#" +
          encodeURIComponent(
            this.state.status + "_" + this.state.currentPage.toString()
          )
      );
    }

    getUser().then((user) => this.setState({ user }));

    this.changeStatus = this.changeStatus.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.handleMenuChange = this.handleMenuChange.bind(this);
    this.onSelectRow = this.onSelectRow.bind(this);
    this.handlePatientStatusChange = this.handlePatientStatusChange.bind(this);
    this.handleRefreshPatients = this.handleRefreshPatients.bind(this);
    this.handleNotificationsChange = this.handleNotificationsChange.bind(this);
    this.handlePatientUpdate = this.handlePatientUpdate.bind(this);
    this.handleCreateNewPatientButton =
      this.handleCreateNewPatientButton.bind(this);
    this.handlePatientsStatusChange =
      this.handlePatientsStatusChange.bind(this);
  }

  async componentDidMount() {
    if (!this.state.patients) {
      this.setState({
        patientsLoaded: false,
      });
    }
    const patients = await get_value("pd");
    const avatars = await get_value("pa");
    if (patients) {
      this.setState(
        {
          patients: patients,
          avatars: avatars || {},
        },
        () => {
          this.setState({ patientsLoaded: patients.length > 0 });
        }
      );
    }
    const new_patients = await getPatients();
    const new_avatars = await getPatientAvatars();
    this.setState({
      patients: new_patients,
      patientsLoaded: true,
      avatars: new_avatars || {},
    });
    const notifications = await getPatientNotifications();
    this.setState({
      notifications: notifications,
    });
  }

  componentWillMount() {
    // if (this.state.patients) this.handleRefreshPatients(null, true);
    // else this.handleRefreshPatients(null, true);
    window.addEventListener(
      "beforeunload",
      ((e) => {
        this.handleRefreshPatients();
      }).bind(this)
    );
    if (this.props.location.hash) {
      const status = decodeURIComponent(
        this.props.location.hash.replace("#", "")
      ).split("_")[0];
      this.setState({ status });
    }
  }

  componentWillUpdate() {
    if (this.props.location.pathname === "/") {
      if (this.props.location.hash) {
        const status = decodeURIComponent(
          this.props.location.hash.replace("#", "")
        ).split("_")[0];
        if (status !== this.state.status) {
          this.setState({ status });
        }
      }
    }
  }

  async handlePatientStatusChange(status) {
    this.setState({ patientsLoaded: false });
    for (const row of this.state.selectedRows) {
      const patient = row.original;
      if (patient.status !== status.key) {
        patient.status = status.key;
        await updatePatient(patient);
      }
    }
    const patients = await get_value("pd");
    const avatars = await get_value("pa");
    this.setState({
      patients: patients,
      avatars: avatars || {},
      patientsLoaded: true,
      selectedRows: [],
    });
  }

  async handlePatientsStatusChange() {
    this.setState({ patientsLoaded: false });
    const patients = await get_value("pd");
    const avatars = await get_value("pa");
    this.setState({
      patients: patients,
      avatars: avatars || {},
      patientsLoaded: true,
    });
  }

  onSelectRow(selectedRows) {
    if (this.state.selectedRows.length === selectedRows.length) return;
    this.setState({ selectedRows });
    // onSelectRow(record, selected, selectedRows, nativeEvent) {
    // if (selected && this.state.selectedRows.indexOf(record) < 0) this.setState({ selectedRows: [...this.state.selectedRows, record] })
    // if (!selected && this.state.selectedRows.indexOf(record) > 0) this.setState({ selectedRows: this.state.selectedRows.filter(row => row !== record) })
  }

  changeStatus = (name) => (event) => {
    this.setState({ status: name });
  };

  changeSearch = (event) => {
    this.props.history.push("/");
    this.setState({ search: event.target.value, status: "All Patients" });
  };
  handleClick = (event) => {
    this.setState({ acctAnchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ acctAnchorEl: null });
  };

  handleToggleDrawer = () => {
    this.setState({ draweropen: !this.state.draweropen });
  };

  handleMenuChange = (key) => (ev) => {
    this.setState({
      status: key,
      search: "",
      selectedRows: [],
      currentPage: 1,
    });
    this.props.history.push("/#" + encodeURIComponent(key + "_1"));
  };

  handleRefreshPatients = async (ev, background = false) => {
    if (!background) this.setState({ patientsLoaded: false });
    const patients = await getPatients();
    const avatars = await getPatientAvatars();
    this.setState({
      patients: patients,
      patientsLoaded: true,
      avatars: avatars || {},
    });
    const notifications = await getPatientNotifications();
    this.setState({
      notifications: notifications,
    });
  };

  handleNotificationsChange = (notifications) => {
    this.setState({ notifications });
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
    this.props.history.push(
      "#" + encodeURIComponent(this.state.status + "_" + page.toString())
    );

    // currentPage
  };

  handleSetNewPassword = async () => {
    const oldPass = this.state.oldPass;
    const newPass = this.state.newPass;
    if (newPass.length < 6) {
      this.setState({ newPass: "" });
      return message.error("New password must be at least 6 characters long");
    }
    const user = await Amplify.Auth.currentAuthenticatedUser();
    try {
      await Amplify.Auth.changePassword(user, oldPass, newPass);
      this.setState({ oldPass: "", newPass: "" });
      message.success("New password has been set");
    } catch (e) {
      if (
        e.code === "InvalidParameterException" ||
        e.code === "NotAuthorizedException" ||
        e.code === "InvalidPasswordException"
      )
        message.error("Current password is incorrect");
      else if (
        e.code === "LimitExceededException" ||
        e.code === "InternalErrorException" ||
        e.code === "TooManyRequestsException"
      )
        message.error("Too many requests; please wait before trying again");
      else
        message.error(
          "There was a problem changing your password, please try again later"
        );
    }
  };

  handlePatientUpdate = (patient) => {
    const patients = [...this.state.patients];
    const idx = this.state.patients.findIndex(
      (curr_patient) => curr_patient.patientid === patient.patientid
    );
    if (idx) patients[idx] = patient;
    else patients.push(patients);
    this.setState({ patients });
  };

  handleCreateNewPatientButton = async () => {
    this.setState({ patientsLoaded: false });
    const free_trial = await getFreeTrialActive();
    if (free_trial) {
      this.setState({ patientsLoaded: true });
      this.props.history.push("/create-patient");
    } else {
      const billing = await getBillingAccount();
      if (billing && billing.subscriptions.total_count) {
        this.setState({ patientsLoaded: true });
        this.props.history.push("/create-patient");
      } else {
        this.setState({ patientsLoaded: true });
        this.setState({ create_patient_available: false });
      }
    }
  };

  render() {
    const user = this.state.user;

    return (
      <Layout style={{ background: "#fff" }}>
        <Content style={{ padding: "0px", background: "#fff" }}>
          <Route
            path="/"
            exact
            render={() => (
              <Spin spinning={!this.state.patientsLoaded}>
                <Layout style={{ background: "#fff", flexDirection: "row" }}>
                  <Sidebar dashboard={this}>
                    <div
                      className="patient-list-header-search"
                      style={{ marginBottom: "8px" }}
                    >
                      <Search size={16} color="#8A8F98" />
                      <input
                        placeholder="Search Patients"
                        onChange={this.changeSearch}
                        value={this.state.search}
                        autoFocus={this.state.search}
                        autoComplete="off"
                      />
                    </div>
                  </Sidebar>
                  <div className="patient-list-body">
                    {this.state.status !== "Inbox" && (
                      <div className="patient-list-header">
                        {this.state.search ? null : (
                          <div className="patient-list-header-info">
                            <div
                              className="patient-list-header-status-icon"
                              style={{
                                color: status_color_map[this.state.status],
                              }}
                            />
                            <p>{this.state.status}</p>
                          </div>
                        )}
                        <div className="patient-list-header-actions">
                          <div className="patient-list-search-container">
                            <div className="patient-list-header-search">
                              <Search size={16} color="#A6ABAE" />
                              <input
                                placeholder="Search Patients"
                                onChange={this.changeSearch}
                                value={this.state.search}
                                autoFocus={this.state.search}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <Dropdown
                            overlay={
                              <Menu onClick={this.handlePatientStatusChange}>
                                <Menu.Item key="On Hold">On Hold</Menu.Item>
                                <Menu.Item key="Unprocessed">
                                  Unprocessed
                                </Menu.Item>
                                <Menu.Item key="Planning">Planning</Menu.Item>
                                <Menu.Item key="Needs Review">
                                  Needs Review
                                </Menu.Item>
                                <Menu.Item key="Accepted">Accepted</Menu.Item>
                                <Menu.Item key="In Production">
                                  In Production
                                </Menu.Item>
                                <Menu.Item key="In Treatment">
                                  In Treatment
                                </Menu.Item>
                                <Menu.Item key="Archived">Archived</Menu.Item>
                              </Menu>
                            }
                            onClick={() => null}
                            type="primary"
                            disabled={!this.state.selectedRows.length}
                          >
                            <Button
                              // type="primary"
                              style={{ marginLeft: 15 }}
                              disabled={!this.state.selectedRows.length}
                            >
                              Change Status <Icon type="down" />
                            </Button>
                          </Dropdown>

                          {/* <ArchformButton
                          style={{
                            justifyContent: 'center',
                            border: `1px solid ${this.state.search ? 'var(--purple-100)' : status_color_map[this.state.status]}`,
                            margin: '0 16px'
                          }}
                          onClick={(() => this.props.history.push('/create-patient')).bind(this)} >
                          <Plus size={16} color={this.state.search ? 'var(--purple-100)' : status_color_map[this.state.status]} />
                          <p style={{ color: this.state.search ? 'var(--purple-100)' : status_color_map[this.state.status] }}>New Patient</p>
                        </ArchformButton> */}

                          <button
                            className="patient-list-button"
                            style={{
                              border: `1px solid ${
                                this.state.search
                                  ? "var(--purple-100)"
                                  : status_color_map[this.state.status]
                              }`,
                              color: this.state.search
                                ? "var(--purple-100)"
                                : status_color_map[this.state.status],
                            }}
                            onClick={this.handleCreateNewPatientButton}
                          >
                            <Plus
                              size={16}
                              color={
                                this.state.search
                                  ? "var(--purple-100)"
                                  : status_color_map[this.state.status]
                              }
                              style={{ marginRight: "8px" }}
                            />
                            New patient
                          </button>
                        </div>
                      </div>
                    )}
                    {this.state.status === "Inbox" &&
                      this.state.patientsLoaded && (
                        <Inbox
                          updateDashboard={this.handleNotificationsChange}
                          notifications={this.state.notifications || []}
                        />
                      )}
                    {this.state.status !== "Inbox" && (
                      <PatientTable
                        avatars={this.state.avatars || {}}
                        patients={this.state.patients || []}
                        search={this.state.search.toLowerCase()}
                        status={this.state.status}
                        onSelectRow={this.onSelectRow}
                        onChangeRow={this.onChangeRow}
                        handlePageChange={this.handlePageChange}
                        currentPage={this.state.currentPage}
                      />
                    )}
                  </div>
                  <div
                    className="create-patient-disabled-overlay"
                    style={{
                      display: this.state.create_patient_available
                        ? "none"
                        : "flex",
                    }}
                  >
                    <div>
                      <h3>
                        You must have an active subscription to create new
                        patients
                      </h3>
                      <button
                        // className='submit-patient-disabled-button'
                        onClick={(() => {
                          this.setState({ create_patient_available: true });
                          this.props.history.push("/billing");
                        }).bind(this)}
                      >
                        <p>Go to Billing</p>
                      </button>
                    </div>
                  </div>
                </Layout>
              </Spin>
            )}
          />
          <Route
            path="/patients/:num"
            render={() => (
              <Layout style={{ background: "#fff", flexDirection: "row" }}>
                <Sidebar dashboard={this}>
                  <div
                    className="patient-list-header-search"
                    style={{ marginBottom: "8px" }}
                  >
                    <Search size={16} color="#A6ABAE" />
                    <input
                      placeholder="Search Patients"
                      onChange={this.changeSearch}
                      value={this.state.search}
                      autoFocus={this.state.search}
                      autoComplete="off"
                    />
                  </div>
                </Sidebar>
                <PatientView
                  patients={this.state.patients}
                  onChangeStatus={this.handlePatientsStatusChange}
                />
              </Layout>
            )}
          />

          <Route
            path="/create-patient"
            render={() => (
              <Layout style={{ background: "#fff", flexDirection: "row" }}>
                <Sidebar dashboard={this}>
                  <div
                    className="patient-list-header-search"
                    style={{ marginBottom: "8px" }}
                  >
                    <Search size={16} color="#A6ABAE" />
                    <input
                      placeholder="Search Patients"
                      onChange={this.changeSearch}
                      value={this.state.search}
                      autoFocus={this.state.search}
                      autoComplete="off"
                    />
                  </div>
                </Sidebar>
                <CreatePatient
                  patients={this.state.patients}
                  user={user}
                  updatePatientList={this.handlePatientUpdate}
                />
              </Layout>
            )}
          />
          <Route
            path="/edit-patient/:num"
            render={() => (
              <Layout style={{ background: "#fff", flexDirection: "row" }}>
                <Sidebar dashboard={this}>
                  <div
                    className="patient-list-header-search"
                    style={{ marginBottom: "8px" }}
                  >
                    <Search size={16} color="#A6ABAE" />
                    <input
                      placeholder="Search Patients"
                      onChange={this.changeSearch}
                      value={this.state.search}
                      autoFocus={this.state.search}
                      autoComplete="off"
                    />
                  </div>
                </Sidebar>
                <Spin spinning={!this.state.patientsLoaded}>
                  {this.state.patientsLoaded ? (
                    <CreatePatient
                      patients={this.state.patients}
                      user={user}
                      updatePatientList={this.handlePatientUpdate}
                    />
                  ) : null}
                </Spin>
              </Layout>
            )}
          />

          <Route
            path="/settings"
            render={() => (
              <Layout style={{ background: "#fff", flexDirection: "row" }}>
                <Sidebar dashboard={this}>
                  <div
                    className="patient-list-header-search"
                    style={{ marginBottom: "8px" }}
                  >
                    <Search size={16} color="#A6ABAE" />
                    <form>
                      <input
                        placeholder="Search Patients"
                        onChange={this.changeSearch}
                        value={this.state.search}
                        autoFocus={this.state.search}
                        autoComplete="off"
                      />
                    </form>
                  </div>
                </Sidebar>
                <SettingsPage user={user} />
              </Layout>
            )}
          />

          <Route
            path="/billing"
            render={() => (
              <Layout style={{ background: "#fff", flexDirection: "row" }}>
                <Sidebar dashboard={this}>
                  <div
                    className="patient-list-header-search"
                    style={{ marginBottom: "8px" }}
                  >
                    <Search size={16} color="#A6ABAE" />
                    <input
                      placeholder="Search Patients"
                      onChange={this.changeSearch}
                      value={this.state.search}
                      autoFocus={this.state.search}
                      autoComplete="off"
                    />
                  </div>
                </Sidebar>
                <BillingPage user={user} />
              </Layout>
            )}
          />
        </Content>
        {user && (
          <Intercom
            appID="mg5n9yyr"
            email={user && user.attributes.email}
            name={user && user.attributes.name}
          />
        )}
      </Layout>
    );
  }
}

export default withRouter(Dashboard);
